import { datadogRum } from '@datadog/browser-rum';

if (
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== 'vms.fleet-dev.fcs.gsa.gov' &&
  window.location.hostname !== 'vms.fleet-test.fcs.gsa.gov'
) {
  datadogRum.init({
    applicationId: '32a200a8-247f-4882-8bc8-f88d7f35a765',
    clientToken: 'pub9844e2e7eb4e0dc151aff1b540887ac0',
    site: 'ddog-gov.com',
    service: 'vms-dev',

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // eslint-disable-next-line consistent-return
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        event.error.message.includes('ResizeObserver loop limit exceeded')
      ) {
        return false;
      }
    },
  });

  datadogRum.startSessionReplayRecording();
}
