import { gql } from '@apollo/client';

export const GET_BOACS_BY_PARTIAL = gql`
  query GetBoacsByPartial(
    $partialBoac: String!
    $agencyCode: String
    $bureauCode: String
  ) {
    getBoacsByPartialBoac(
      partialBoac: $partialBoac
      agencyCode: $agencyCode
      bureauCode: $bureauCode
    ) {
      boacId
      boac
      agencyCode
      bureauCode
    }
  }
`;

export const GET_ZONES_CENTERS = gql`
  query {
    getZones {
      id
      name
      dataSource
      regions {
        id
        name
      }
      centers {
        id
        sortId
        name
        stateCode
        regionId
        fieldOffices {
          id
          name
        }
      }
    }
  }
`;

export const GET_LIFECYCLE_INDICATORS = gql`
  query GetLifecycleIndicators {
    getLifecycleIndicators {
      lifecycleIndicatorStatus
      lifecycleIndicator
      lifecycleIndicatorOwnershipType
    }
  }
`;

const GET_BUREAU = gql`
  query GetBureau($agencyCode: String!, $id: String!) {
    getBureau(agencyCode: $agencyCode, id: $id) {
      name
      id
    }
  }
`;

export const GET_FILTERED_VENDORS = gql(/* GraphQL */ `
  query getFilteredVendors(
    $filters: [Filter!]!
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getFilteredVendors(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      count
      hasMore
      rows {
        id
        fleetVendorNumber
        vendorName
        vendorDbaName
        samStatus
        smallBusinessFlag
        uniqueEntityIdentifier
        socioeconomicInfo {
          debtSubjectToOffset
        }
        vendorContacts {
          contactType
          address1
          address2
          address3
          city
          state {
            stateCode
          }
          postalCode
          countryId
          country {
            isoCountryCode3
          }
        }
        fleetStatus
        phoneExtension
        phoneNumber
        phoneCountryCode
      }
    }
  }
`);

export const GET_FILTERED_VENDORS_TYPE_AHEAD_OPTIONS = gql`
  query getVendorTypeAheadOptions($key: String!, $search: String!) {
    getVendorTypeAheadOptions(key: $key, search: $search)
  }
`;
