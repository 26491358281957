import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Label, Spinner } from '@gsa/afp-component-library';
import { GET_TAG_ACTIVITY, GET_BUREAU } from '../../../services/data-layer';
import { useRegistration } from '../reg-context-provider';

const expirationFormatting = (expDate) => {
  if (!expDate) return '—';
  return `${expDate.toString().substring(4, 6)}/${expDate
    .toString()
    .substring(0, 4)}`;
};

export default function LicensePlateDetails({
  tagNumber,
  tagExpirationDate,
  tagAgencyCode,
  tagBureauCode,
  onChange = () => {},
}) {
  const { setRegistrationError } = useRegistration();

  const [getBureau, { data: bureauData, loading: loadingBureau }] =
    useLazyQuery(GET_BUREAU, {
      fetchPolicy: 'no-cache',
      onError(err) {
        setRegistrationError(err);
      },
    });

  const [getTagActivity, { data, loading: loadingTagActivity }] = useLazyQuery(
    GET_TAG_ACTIVITY,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        setRegistrationError(err);
      },
    },
  );

  const [bureau, setBureau] = useState(null);

  useEffect(() => {
    if (tagNumber) {
      getTagActivity({ variables: { id: tagNumber } });
    } else {
      setBureau(null);
      onChange(null);
    }
  }, [tagNumber]);

  useEffect(() => {
    if (data?.getTag?.expirationDate) {
      onChange(data?.getTag);
    }
  }, [data]);

  useEffect(() => {
    if (tagBureauCode && tagAgencyCode) {
      getBureau({
        variables: {
          id: tagBureauCode,
          agencyCode: tagAgencyCode,
        },
      });
    } else if (!tagBureauCode && !tagAgencyCode) {
      setBureau(null);
    }
  }, [tagBureauCode, tagAgencyCode]);

  useEffect(() => {
    if (bureauData?.getBureau) {
      setBureau(bureauData?.getBureau.shortName);
    }
  }, [bureauData]);

  return (
    <>
      {(loadingTagActivity || loadingBureau) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      <div className="grid-col-4">
        <Label>Federal License Plate Expiration Date</Label>
        <div className="afp-registration-text afp-registration-no-border">
          {expirationFormatting(tagExpirationDate)}
        </div>
      </div>
      <div className="grid-col-4">
        <Label>Bureau</Label>
        <div className="afp-registration-text afp-registration-no-border">
          {bureau ? tagBureauCode : ''} {bureau && tagBureauCode ? '-' : ''}{' '}
          {bureau || '—'}
        </div>
      </div>
    </>
  );
}
