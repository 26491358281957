/* eslint-disable filenames/match-exported */
import React, { useState, useEffect, useCallback } from 'react';
import {
  AFPTable,
  Icon,
  EmptyState,
  Spinner,
  Pagination,
  Tooltip,
  Tag,
} from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import useUser from 'utilities/use-user';
import { GET_RECALLS_BY_VEHICLE } from '../../../../services/data-layer';
import { useVehicle } from '../../vehicle-context-provider';
import { VehicleDescription } from '../../widgets/recall-table-widgets';
import { formatDataForTable } from '../../widgets/table-data-widgets';
import AddCommentModal from '../../modals/add-comment-modal';
import AddRemedyDate from '../../modals/add-remedy-date';
import './styles.css';

const initVariables = {
  limit: 5,
  offset: 0,
  order: [['status', 'ASC']],
};

const VehicleRecallTable = ({ ownerShipType }) => {
  const { vehicle, showSystemError } = useVehicle();

  const [pageCount, setPageCount] = useState(5);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(null);
  const [isReset, resetPage] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [addComment, setAddComment] = useState(false);
  const [addRemedyDate, setAddRemedyDate] = useState(false);
  const [actionData, setActionData] = useState({});
  const [getRecalls, setGetRecalls] = useState(process.env.NODE_ENV === 'test');
  const { isRole } = useUser();
  const initialSortingState = [['status', 'ASC']];
  const [sortingState, setSortingState] = useState(initialSortingState);

  const { data, loading, error, refetch } = useQuery(GET_RECALLS_BY_VEHICLE, {
    variables: {
      id: vehicle?.uuid,
      offset,
      limit: pageCount,
      order: sortingState,
    },
  });

  const TABLE_ICON_COLLAPSED = (
    <Icon className="usa-icon--size-4" iconName="navigate_next" />
  );
  const TABLE_ICON_EXPANDED = (
    <Icon className="usa-icon--size-4" iconName="expand_more" />
  );

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return <VehicleDescription original={original} />;
  }, []);

  // Handler for Actions
  const actionClick = (action, queryData) => {
    if (action === 'addComment') {
      setAddComment(true);
      setActionData(queryData);
    }
    if (action === 'addRemedyDate') {
      setAddRemedyDate(true);
      setActionData(queryData);
    }
  };

  useEffect(() => {
    if (getRecalls) {
      const sortBy = sortingState[0][0];
      const sortOrder = sortingState[0][1];

      if (sortBy === 'status') {
        const variables = {
          order: sortingState,
        };

        refetch(variables);
      }

      if (sortBy === 'launchDate') {
        // API takes in a different format for sorting launch date
        const variables = {
          order: [['recallCampaign', 'recallReleaseDate', sortOrder]],
        };
        refetch(variables);
      }

      if (sortBy === 'recallCampaign.isRemedyAvailable') {
        // API takes in a different format for sorting isRemedyAvailable
        const variables = {
          order: [['recallCampaign', 'isRemedyAvailable', sortOrder]],
        };
        refetch(variables);
      }
    }
  }, [initVariables, pageCount, offset, sortingState, getRecalls]);

  useEffect(() => {
    if (data) {
      const newData = formatDataForTable(
        data,
        actionClick,
        ownerShipType,
        isRole,
      );

      setTableData(newData);
      setDataCount(data?.getRecallsByVehicle?.count || 0);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [addComment, addRemedyDate]);
  const TableWrapper = ({ tableData, loading, pageCount, dataCount }) => {
    return (
      <>
        <AFPTable
          columns={tableHeaderData}
          iconCollapsed={TABLE_ICON_COLLAPSED}
          iconExpanded={TABLE_ICON_EXPANDED}
          data={loading ? [] : tableData || []}
          testId="afp-responsive-table-test-id"
          defaultSort={sortingState}
          fullWidth
          bordered={false}
          expandable
          scrollable={false}
          stacked
          renderRowSubComponent={renderRowSubComponent}
          onSort={(order) => {
            if (order) {
              setGetRecalls(true);
              const [sortBy, sortOrder] = order.split(' ');
              setSortingState([[sortBy.replace(/`/g, ''), sortOrder]]);
            }
          }}
        />
        {loading && <Spinner className="padding-y-9" />}
        {tableData?.length > 0 ? (
          <Pagination
            variant="advanced"
            currentPage={currentPage}
            itemsPerPage={pageCount}
            itemsCount={dataCount}
            onPageChange={handlePaginationChange}
            isReset={isReset}
            itemsPerPageOptions={[5, 10, 15, 20]}
          />
        ) : null}

        {(tableData == null || tableData.length === 0) && !loading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>

            <div className="text-center text-bold" data-testid="no-recalls">
              This vehicle has no open or closed recalls.
            </div>
          </div>
        )}
      </>
    );
  };

  const tableHeaderData = [
    {
      Header: 'Campaign',
      accessor: 'recallCode',
      sortable: false,
      headerClassName: 'width-card-lg',
      Cell: ({ value, row: { original } }) => {
        return (
          <>
            <div>{value}</div>

            {original?.recallCampaign?.shouldStopDrive && (
              <div>
                <Tag key="Urgent-Dark" variant="Urgent-Dark">
                  STOP DRIVE
                </Tag>
              </div>
            )}

            {original?.recallCampaign?.shouldParkOutside && (
              <div>
                <Tag
                  key="Important-Dark"
                  variant="Important-Dark"
                  className="text-no-wrap"
                >
                  PARK OUTSIDE
                </Tag>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      headerClassName: 'width-card-lg',
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: () => (
        <span
          className="display-flex remedy-tooltip text-no-wrap"
          title="Remedy available"
        >
          Remedy available
          <Tooltip
            className="usa-button--unstyled"
            label='Remedy Available: "Yes" means a recall repair or parts are available.'
            position="top"
          >
            <Icon iconName="info" className="margin-left-1 text-primary" />
          </Tooltip>
        </span>
      ),
      accessor: 'recallCampaign.isRemedyAvailable',
      headerClassName: 'width-card-lg',
      Cell: ({ value }) => {
        return value ? 'Yes' : 'No';
      },
    },
    {
      Header: () => (
        <span
          className="display-flex remedy-tooltip text-no-wrap"
          title="Remedy date"
        >
          Remedy date
          <Tooltip
            className="usa-button--unstyled"
            label="Remedy date is the date that you had the recall addressed."
            position="top"
          >
            <Icon iconName="info" className="margin-left-1 text-primary" />
          </Tooltip>
        </span>
      ),
      accessor: 'remedyDate',
      headerClassName: 'width-card-lg',
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sortable: false,
    },
  ];

  const handlePaginationChange = (pageNum, itemsPer) => {
    if (itemsPer !== pageCount) {
      setPageCount(itemsPer);
      resetPage(true);
      setOffset(0);
      setCurrentPage(pageNum);
    }
    const newOffset = (pageNum - 1) * itemsPer;

    if (newOffset !== offset) {
      setCurrentPage(pageNum);
      setOffset(newOffset);
    }
  };

  if (error) showSystemError(true);

  return (
    <>
      {addComment ? (
        <AddCommentModal
          onClose={() => setAddComment(false)}
          actionData={actionData}
        />
      ) : null}
      {addRemedyDate ? (
        <AddRemedyDate
          onClose={() => setAddRemedyDate(false)}
          actionData={actionData}
        />
      ) : null}

      <TableWrapper
        tableData={tableData}
        loading={loading}
        pageCount={pageCount}
        dataCount={dataCount}
      />
    </>
  );
};

export default VehicleRecallTable;
