import { usCountryCode } from 'utilities/consts';
import { nullifiedSchemaObject } from 'utilities/form-generator-controls/helper';
import { z } from 'zod';

const schemaObject = {
  entityType: z.string(),
  entityId: z.string(),
  priority: z.number(),
  pocFirstName: z.string(),
  pocLastName: z.string(),
  pocEmailAddress: z.string().email(),
  pocPhone1: z.string(),
  pocPhone1Ext: z.string(),
  pocPhone2: z.string(),
};

const contactAssociationSchemaNullish = z.object(
  nullifiedSchemaObject(schemaObject),
);

const usOfficeLocationSchema = z
  .object({
    countryId: z
      .string()
      .nonempty({ message: 'Country is required' })
      .nullish(),
    address1: z
      .string()
      .nonempty({ message: 'Address line 1 is required' })
      .nullish(),
    address2: z.string().nullish(),
    city: z.string().nonempty({ message: 'City is required' }).nullish(),
    state: z.string().optional(),
    postalCode: z
      .string()
      .nonempty({ message: 'Zip code is required' })
      .nullish(),
  })
  .superRefine((data, ctx) => {
    if (
      (data.countryId === usCountryCode && data.state === '') ||
      (data.countryId === usCountryCode && data.state === 'default')
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['state'],
        fatal: true,
        message: 'State is required',
      });
    }
    return z.NEVER;
  });

const others = z.object({
  boac: z.string().nonempty({ message: 'BOAC is required' }).nullish(),
  customerAgencyCode: z.string().nullish(),
  customerBureauCode: z.string().nullish(),
  accountName: z.string().nonempty({ message: 'Account name is required' }),
  fmcId: z
    .string()
    .refine(
      (data) => data !== 'default',
      'Fleet Management Center (FMC) is required',
    ),
  fsrUserEmail: z.coerce
    .string()
    .email({ message: 'Valid FSR email is required' }),
  mileageCaptureType: z.string().max(1, 'Should be one character').nullish(),
  agencySubGroup: z
    .string()
    .refine((data) => {
      return data.length === 2 || data.length === 3 || data.length === 0;
    }, 'Should be two or three characters')
    .nullish(),
  maintenanceReminder: z
    .string()
    .nonempty({ message: 'Receive PM reminder is required' })
    .nullish(),
  accountNotes: z.string().nullish(),
  contactAssociationError: z
    .string()
    .nullish()
    .superRefine((data, ctx) => {
      if (data) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
  contactAssociations: z.array(contactAssociationSchemaNullish), // Validation is handled by onBeforeSubmit
});

export const customerAccountFormSchema = z.intersection(
  usOfficeLocationSchema,
  others,
);
