/* eslint-disable react/prop-types */
import React, { useState, createContext, useContext } from 'react';
import { getBase64 } from 'components/vehicle-details/sidenav-widgets/preventative-maintenance/helper';
import { usePmExpress } from './pm-express-provider';

export const UploadInvoiceComponentContext = createContext();

export default function UploadInvoiceContext({
  children,
  setData,
  setSkipPageReset,
}) {
  const [file, setFile] = useState(null);

  const { closePmInvoiceModal, selectedPmForUpload } = usePmExpress();

  const handleDocChange = async () => {
    setSkipPageReset(true);
    const fileData = file ? await getBase64(file) : undefined;

    setData((old) => {
      const modifiedData = old.map((row) => {
        const data = { ...row };
        if (data.assetPmId === selectedPmForUpload?.assetPmId && !file) {
          data.invoiceData = null;
          data.invoiceModificationFlag = 'delete';
        } else if (data.assetPmId === selectedPmForUpload?.assetPmId && file) {
          data.invoiceData = {
            name: file?.name,
            docStoreUri: 'uri',
            fileType: file?.type,
          };
          data.file = fileData;
          data.fileName = file?.name;
          data.fileType = file?.type;
          data.docMetaId = selectedPmForUpload?.invoiceData?.docMetaId;
          data.invoiceModificationFlag = 'add';
        }
        return data;
      });
      return modifiedData;
    });
    closePmInvoiceModal();
  };

  return (
    <UploadInvoiceComponentContext.Provider
      value={{
        file,
        setFile,
        handleDocChange,
      }}
    >
      {children}
    </UploadInvoiceComponentContext.Provider>
  );
}

export const useUploadInvoiceContext = () =>
  useContext(UploadInvoiceComponentContext);
