import moment from 'moment';
import { z } from 'zod';

export const assignVehicleFormSchema = z
  .object({
    garageCountryCode: z.string().nullish(),
    garageAddress1: z.string().nullish(),
    garageAddress2: z.string().nullish(),
    garageAddress3: z.string().nullish(),
    garageCity: z.string().nullish(),
    garageStateCode: z.string().nullish(),
    garagePostalCode: z
      .string()
      .nonempty({ message: 'Zip code is required' })
      .nullish(),
    fundCode: z.string().nullish(),
    account1: z.string().nullish(),
    account2: z.string().nullish(),
    assignmentDate: z.string(),
    beginOdometer: z
      .string()
      .nonempty({ message: 'Begin mileage is required' })
      .nullish(),
    endOdometer: z
      .string()
      .nonempty({ message: 'End mileage is required' })
      .nullish(),
    numberOfKeys: z.string().nullish(),
    comment: z.string().nullish(),
  })
  .superRefine((val, ctx) => {
    const assignmentDate = moment(val?.assignmentDate, 'MM/DD/YYYY', true);
    const isValidDateFormat = assignmentDate.isValid();
    if (!assignmentDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['assignmentDate'],
        fatal: true,
        message: 'Assignment date is required',
      });
    } else if (isValidDateFormat) {
      const startOfMonth = moment().startOf('month');
      const isValidDate =
        assignmentDate.isAfter(startOfMonth.subtract(1, 'days')) &&
        assignmentDate.isBefore(moment());
      if (!isValidDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          path: ['assignmentDate'],
          fatal: true,
          message:
            'Assignment date must be no earlier than the start of the current month and no later than the current day',
        });
      }
    } else {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['assignmentDate'],
        fatal: true,
        message: 'Assignment date must be in MM/DD/YYYY format',
      });
    }

    const { beginOdometer, endOdometer, garagePostalCode, numberOfKeys } = val;
    const sanitizedBeginOdometer = beginOdometer.replace(/\D/g, '');
    const sanitizedEndOdometer = endOdometer.replace(/\D/g, '');
    const sanitizedNumberOfKeys = numberOfKeys.replace(/\D/g, '');

    if (sanitizedBeginOdometer && sanitizedEndOdometer) {
      if (
        (sanitizedEndOdometer || sanitizedEndOdometer === '0') &&
        +sanitizedEndOdometer < +sanitizedBeginOdometer
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_arguments,
          message: 'Begin mileage must be less than end mileage',
          fatal: true,
          path: ['beginOdometer'],
        });
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_arguments,
          message: 'End mileage must be greater than begin mileage',
          fatal: true,
          path: ['endOdometer'],
        });
      }
    }
    if (!sanitizedBeginOdometer) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_arguments,
        message: 'Invalid format for begin mileage',

        fatal: true,
        path: ['beginOdometer'],
      });
    }
    if (!sanitizedEndOdometer) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_arguments,
        message: 'Invalid format for end mileage',

        fatal: true,
        path: ['endOdometer'],
      });
    }
    if (!sanitizedNumberOfKeys && numberOfKeys !== '') {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_arguments,
        message: 'Invalid format for number of keys',
        fatal: true,
        path: ['numberOfKeys'],
      });
    }
    if (garagePostalCode) {
      let formattedZipCode = garagePostalCode;
      formattedZipCode = formattedZipCode.trim();
      // https://regex101.com/r/w9khTI/1/
      formattedZipCode = formattedZipCode.replace(/[^0-9]+/g, '');
      // https://regex101.com/r/lhi3nT/1
      formattedZipCode = formattedZipCode.replace(
        /^([0-9]{5})([0-9]{4})$/,
        '$1-$2',
      );
      if (!formattedZipCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_arguments,
          message: 'Invalid format for zip code',
          fatal: true,
          path: ['garagePostalCode'],
        });
      }
    }
  });
