import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Spinner } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import {
  CREATE_SUPPORTING_DOC,
  DISASSOCIATE_PDF,
  REGISTER_VEHICLE,
  VEHICLE_REG_DETAIL,
} from '../../../../services/data-layer';
import { getRegistrationPDFPayload } from '../../../registration-details/helpers/payload-constructors';
import {
  InvalidPoCAlert,
  NetworkErrorAlert,
} from '../../../registration-details/widgets/poc-status-alerts';
import { useVehicle } from '../../vehicle-context-provider';
import AgencyDetails from './agency-details';
import LicensePlate from './license-plate';
import LicensePlatePoc from './license-plate-poc';
import RegistrationDetails from './registration-details';
import './registration.css';
import useUpdateStatePlate from './use-update-state-plate';
import {
  ExemptConfirmation,
  MissingConfirmation,
} from './vehicle-status-warnings';

const MODEL_TYPE_CODES = [
  'Standard Vehicle',
  'Motorcycle',
  'Trailer',
  'Low Speed Electric Vehicle',
  'Other',
  'VEH',
];

export default (props) => {
  const { refetchVehicle, vehicle } = useVehicle();
  const { id: vin } = vehicle;

  const [updateStatePlate, updatedStatePlate, updatingStatePlate] =
    useUpdateStatePlate();

  const [, setUpdatedVehicle] = useState(null);
  const [registrationUpdated, setRegistrationUpdated] = useState(false);

  const [isRedirect, setIsRedirect] = useState(false);
  const [documentLink, setDocumentLink] = useState('');
  const [refetchingData, setRefetchingData] = useState(false);

  const [displayMissingStatusConfirmation, setMissingStatusConfirmation] =
    useState(false);
  // Tag will be removed from the vehicle after certain status updates but will need to be stored for display in confirmation modal
  const [displayTag, setTagForDisplay] = useState(null);
  const [displayExemptionConfirmation, setDisplayExemptionConfirmation] =
    useState(false);
  const [exemptStatus, setExemptStatus] = useState(null);

  const [hasPocError, setHasPocError] = useState(false);
  const [wasUpdateSubmitted, setWasUpdateSubmitted] = useState(false);
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [fetchVehicle, { data, loading }] = useLazyQuery(VEHICLE_REG_DETAIL, {
    fetchPolicy: 'no-cache',
  });

  // network alerts should be cleared when PoC is updated but the invalid PoC error will only be dismissed if both PoCs are valid
  const handlePocUpdate = (hasError) => {
    setHasPocError(hasError);
    setHasNetworkError(false);
  };

  const [
    registerVehicle,
    { data: registrationResponse, loading: loadingRegisterVehicle },
  ] = useMutation(REGISTER_VEHICLE, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setWasUpdateSubmitted(true);
      // Different errors are returned based on different invalid POC states
      if (error?.message === 'Invalid submission') {
        const isPocError = !!error?.graphQLErrors.find(
          (x) =>
            x.extensions?.code &&
            x.extensions?.code.includes('Point of contact emails'),
        );
        handlePocUpdate(isPocError);
      } else {
        setHasPocError(false);
        setHasNetworkError(true);
      }
    },
    onCompleted: () => {
      handlePocUpdate(false);
      setWasUpdateSubmitted(false);
      refetchVehicle(vin);
    },
  });

  const [createSupportingDoc, { data: supportingDocUrl }] = useMutation(
    CREATE_SUPPORTING_DOC,
    {
      fetchPolicy: 'no-cache',
      onError: (requestError) => {
        // eslint-disable-next-line no-console
        console.warn(requestError);
      },
    },
  );

  const [disassociateRegistrationPDF] = useMutation(DISASSOCIATE_PDF, {
    fetchPolicy: 'no-cache',
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.warn(requestError);
    },
  });

  const updateRegistration = (veh) => {
    setIsRedirect(false);
    setRegistrationUpdated(false); // In case the call fails.  It will be reset after the call succeeds and vehicle details are fetched
    /**
     * modelTypeCode is not present in the VehicleModel object sometimes
     * modelTypeCode is being returned with incorrect values from backend
     *
     * We added the below check on modelTypeCode to match the checkIsValidItemType check
     * in the backend service: afp-vms-api/src/services/helpers/registration-manager.ts:checkIsValidItemType()
     *
     * This code was pushed to overcome this defect: AFP-44457. The root cause is related to a missed field
     * in refactoring and in transforming from Legacy to new DB (model.modelTypeCode)
     *
     * Follow up work needs to be done to fix the root cause. More details: https://cm-jira.usa.gov/browse/AFP-44457
     *  */
    const modelTypeCode =
      veh?.modelTypeCode && MODEL_TYPE_CODES.includes[veh.modelTypeCode]
        ? veh.modelTypeCode
        : 'Standard Vehicle';
    registerVehicle({
      variables: { registration: { ...veh, modelTypeCode } },
    });
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    setIsRedirect(!!props?.location?.state?.isRedirect);
    // eslint-disable-next-line react/prop-types
    setDocumentLink(props?.location?.state?.documentLink || '');
    // Clears the state passed in as a redirect so it doesn't persist across refreshing
    window.history.replaceState({}, document.title);
  }, []);

  const clearConfirmationSettings = () => {
    setIsRedirect(false);
    setMissingStatusConfirmation(false);
    setDisplayExemptionConfirmation(false);
  };

  useEffect(() => {
    if (updatedStatePlate) {
      clearConfirmationSettings();
      setRefetchingData(true);
      refetchVehicle(vin).finally(() => {
        setRefetchingData(false);
      });
      setRegistrationUpdated(true);
    }
  }, [updatedStatePlate, vin]);

  useEffect(() => {
    if (registrationResponse) {
      clearConfirmationSettings();
      // Only display the confirmation when the exempt status updates from false to true
      if (
        displayTag?.tagNumber &&
        registrationResponse?.registerVehicle?.exemptPlate &&
        !exemptStatus
      ) {
        setDisplayExemptionConfirmation(true);
        // TODO: This can be orchestrated by the backend after MVP
        disassociateRegistrationPDF({
          variables: {
            model: 'Vehicle',
            modelPK: registrationResponse?.registerVehicle?.id,
            documentName: 'registration',
          },
        });
      }
      fetchVehicle({
        variables: {
          id: vin,
        },
      });
      setExemptStatus(registrationResponse?.registerVehicle?.exemptPlate);
      setRegistrationUpdated(true);
    }
  }, [registrationResponse, vin]);

  useEffect(() => {
    if (supportingDocUrl?.createSupportingDoc) {
      setUpdatedVehicle((oldVeh) => {
        return {
          ...oldVeh,
          supportingDocument: {
            signedUrl: supportingDocUrl.createSupportingDoc,
          },
        };
      });
    }
  }, [supportingDocUrl]);

  useEffect(() => {
    if (data) {
      if (!data.getVehicle) return;
      const vehicleRegistered =
        !!data.getVehicle?.tag || data.getVehicle?.exemptPlate;

      setUpdatedVehicle({
        ...data.getVehicle,
        registered: vehicleRegistered,
      });
      setExemptStatus(data.getVehicle?.exemptPlate);
      if (registrationUpdated) {
        const pdfData = getRegistrationPDFPayload(data.getVehicle);
        if (pdfData) {
          createSupportingDoc({
            variables: {
              data: pdfData,
              model: 'Vehicle',
              modelPK: vehicle.id,
              documentName: 'registration',
            },
          });
        }
      }
    }
  }, [data]);

  if (loading) return <Spinner className="padding-y-9" />;
  return (
    <>
      {registrationUpdated && !isRedirect && (
        <div className="padding-bottom-1">
          <Alert type="success" slim focused>
            Vehicle was successfully updated.
          </Alert>
        </div>
      )}
      {displayExemptionConfirmation && (
        <ExemptConfirmation
          licensePlate={displayTag?.tagNumber}
          expirationDate={displayTag?.tagExpirationDate}
          vin={vin}
        />
      )}
      {isRedirect && (
        <div className="padding-bottom-1">
          <Alert type="success" slim focused>
            Vehicle was successfully registered.
            {documentLink && documentLink.createSupportingDoc && (
              <>
                {' '}
                <a href={documentLink.createSupportingDoc} download>
                  Download vehicle registration card
                </a>
              </>
            )}
          </Alert>
        </div>
      )}
      {displayMissingStatusConfirmation && (
        <div className="padding-bottom-1">
          <MissingConfirmation
            licensePlate={displayTag?.tagNumber}
            expirationDate={displayTag?.tagExpirationDate}
          />
        </div>
      )}
      {hasPocError && wasUpdateSubmitted && <InvalidPoCAlert />}
      {hasNetworkError && <NetworkErrorAlert />}
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-1">
            Registration Details
          </h4>
          <RegistrationDetails vehicle={vehicle} />
        </div>

        <div className="tablet:grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-1">
            Agency Details
          </h4>
          <AgencyDetails
            vehicle={vehicle}
            refetchVehicle={refetchVehicle}
            setRegistrationUpdated={setRegistrationUpdated}
          />
        </div>
      </div>

      <div>
        <h2 className="margin-top-10 border-bottom-05 border-primary-lighter padding-bottom-1">
          License plate
        </h2>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <h4 className="title-s-caps text-primary margin-bottom-1">
              License Plate
            </h4>
            <LicensePlate
              vehicle={vehicle}
              updateRegistration={updateRegistration}
              updateStatePlate={(statePlate, statePlateExp) => {
                updateStatePlate(vehicle, statePlate, statePlateExp);
              }}
              setTagForDisplay={setTagForDisplay}
            />
          </div>
          <div className="tablet:grid-col-6">
            {vehicle?.tag && (
              <>
                <h4 className="title-s-caps text-primary margin-bottom-1">
                  License Plate POC
                </h4>
                <LicensePlatePoc tagDetails={vehicle?.tag} />
              </>
            )}
          </div>
        </div>
      </div>
      {(refetchingData || updatingStatePlate || loadingRegisterVehicle) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};
