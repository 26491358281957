import React from 'react';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  PublicRoute,
  PrivateRoute,
  AppProvider,
  PageFocus,
  Unauthorized,
  NotFound,
  NotOpted,
  FeatureProvider,
} from '@gsa/afp-shared-ui-utils';
import '@gsa/afp-component-library/dist/css/index.css';
import { RecoilHistoryListener } from 'components/RecoilHistoryListener';
import MileageExpress from 'components/mileage-express';
import Layout from './utilities/layout';
import VehicleDetailPage from './components/vehicle-details';
import Vehicles from './components/vehicle-inventory';
import Orders from './components/orders';
import RegistrationDetails from './components/registration-details';
import OrderDetails from './components/order-details';
import dataStore from './services/data-store';
import './app.css';
import { authRedirect } from './utilities/auth-redirect';
import LicensePlate from './components/license-plate';
import LicensePlateDetails from './components/license-plate-details';
import VehicleReg from './components/vehicle-registration';
import { VMSOperations, VMSSubjects } from './utilities/consts';
import { useFeatureToggle } from './utilities/feature-toggle';
import Recalls from './components/recalls';
import RecallsCampaigns from './components/recalls/recall-campaigns';
import VehicleRecalls from './components/recalls/vehicle-recalls';
import VehicleMileage from './components/vehicle-mileage';
import { VEHICLE_DETAILS_SIDENAV_MAPPING } from './components/vehicle-details/sidenav-widgets/sidenav-mapping';
import { GET_VMS_ENABLED_FEATURES } from './services/data-layer';
import VehicleReports from './components/vehicle-reports';
import BulkImportPage from './components/vehicle-reports/pages/bulk-import/index';
import ExportVehicleReports from './components/vehicle-reports/pages/export-vehicle-reports/index';
import ScheduledReports from './components/vehicle-reports/pages/scheduled-reports/index';
import VehicleExpenses from './components/vehicle-expenses';
import ExpenseAddOrModify from './components/vehicle-details/sidenav-widgets/vehicle-expenses/pages/update';
import RepairOrderPage from './components/vehicle-repair-order';
import FcReplacementHistory from './components/fc-replacement/fc-replacement-history';
import MotorPool from './components/motor-pool';
import RecallUpdate from './components/recalls/vehicle-recalls/actions';
import PmExpress from './components/pm-express';
import OfficeManagement from './components/office-management';
import './datadog-monitoring';
import CustomerAccount from './components/customer-accounts/index';
import RecallCampaignDetail from './components/recalls/recall-campaign-detail';
import '@gsa/afp-shared-form-utils/dist/style.css';

function App() {
  const { feature } = useFeatureToggle();
  return (
    <ApolloProvider client={dataStore}>
      <AppProvider>
        <FeatureProvider featureQuery={GET_VMS_ENABLED_FEATURES}>
          <Layout>
            <Router>
              <RecoilHistoryListener />
              <PageFocus />
              <Switch>
                <PrivateRoute
                  exact
                  path={`/vehicles/:${encodeURIComponent(
                    'vin',
                  )}/expense/:action`}
                  component={ExpenseAddOrModify}
                  title="Update Expense Record"
                  operation={VMSOperations.Update}
                  subject={VMSSubjects.VEHICLE}
                />
                {Object.keys(VEHICLE_DETAILS_SIDENAV_MAPPING)
                  .filter((key) => VEHICLE_DETAILS_SIDENAV_MAPPING[key])
                  .map((key) => {
                    const {
                      operation = VMSOperations.View,
                      title = 'Vehicle Details',
                      subject = VMSSubjects.VEHICLE,
                      featureName,
                    } = VEHICLE_DETAILS_SIDENAV_MAPPING[key];
                    return (
                      <PrivateRoute
                        key={`cdd-sidenav-route-${key}`}
                        path={`/vehicles/:${encodeURIComponent('vin')}/${key}`}
                        component={VehicleDetailPage}
                        title={title}
                        operation={operation}
                        subject={subject}
                        {...(featureName && { featureName })}
                      />
                    );
                  })}
                <Redirect
                  from={`/vehicles/:${encodeURIComponent('vin')}`}
                  to={`/vehicles/:${encodeURIComponent('vin')}/overview`}
                />
                <PrivateRoute
                  exact
                  path={`/vehicle-registration/:${encodeURIComponent('vin')}?`}
                  component={VehicleReg}
                  title="Vehicle Registration"
                  operation={VMSOperations.Update}
                  subject={VMSSubjects.VEHICLE}
                />

                <PrivateRoute
                  path={`/registration-details/:${encodeURIComponent('vin')}?`}
                  component={RegistrationDetails}
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  path="/expense/add-modify"
                  component={ExpenseAddOrModify}
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/vehicles"
                  component={Vehicles}
                  title="Vehicle Inventory"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                />

                <PrivateRoute
                  exact
                  path="/orders"
                  component={Orders}
                  title="License Plate Orders"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.ORDER}
                />

                <PrivateRoute
                  path="/license-plates"
                  component={LicensePlate}
                  title="License Plate Inventory"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.TAG}
                />
                <PrivateRoute
                  path={`/license-plate/:${encodeURIComponent('id')}`}
                  component={LicensePlateDetails}
                  operation={VMSOperations.View}
                  subject={VMSSubjects.TAG}
                />
                <PrivateRoute
                  path={`/order/:${encodeURIComponent('orderNumber')}`}
                  component={OrderDetails}
                  operation={VMSOperations.View}
                  subject={VMSSubjects.ORDER}
                />
                <PrivateRoute
                  exact
                  path="/recalls"
                  component={Recalls}
                  title="Recalls"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/recalls/campaigns"
                  component={feature(RecallsCampaigns, 'recalls')}
                  title="Recalls campaigns"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path={`/recalls/campaigns/:${encodeURIComponent('id')}`}
                  component={RecallCampaignDetail}
                  title="Recall detail"
                  operation={VMSOperations.view}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/recalls/vehicle-recalls"
                  component={VehicleRecalls}
                  title="Vehicle Recalls"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                  featureName="fedfms"
                />
                <PrivateRoute
                  exact
                  path={`/recalls/campaign/:action/:${encodeURIComponent(
                    'id',
                  )}`}
                  component={RecallUpdate}
                  title="Recall campaign Action"
                  operation={VMSOperations.Update}
                  subject={VMSSubjects.VEHICLE}
                />

                <PrivateRoute
                  exact
                  path="/recalls/campaign/:action/"
                  component={RecallUpdate}
                  title="Recall campaign Action"
                  operation={VMSOperations.Update}
                  subject={VMSSubjects.VEHICLE}
                />

                <PrivateRoute
                  exact
                  path="/vehicle/mileages"
                  component={VehicleMileage}
                  title="Vehicle Mileage History"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                  featureName="fedfms"
                />
                <PrivateRoute
                  exact
                  path={`/vehicle/:${encodeURIComponent(
                    'vin',
                  )}/repair-order/:${encodeURIComponent('order')}/:mode`}
                  component={RepairOrderPage}
                />
                <PrivateRoute
                  exact
                  path={`/vehicle/:${encodeURIComponent(
                    'vin',
                  )}/repair-order/:mode`}
                  component={RepairOrderPage}
                />
                <PrivateRoute
                  exact
                  path="/reports"
                  component={VehicleReports}
                  title="Vehicle reports"
                  operation={VMSOperations.UPDATE_VEHICLE_GF}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports"
                  component={VehicleReports}
                  title="Vehicle reports"
                  operation={VMSOperations.Update}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports/bulk-import"
                  component={BulkImportPage}
                  title="Bulk import"
                  operation={VMSOperations.UPDATE_VEHICLE_GF}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports/bulk-import"
                  component={BulkImportPage}
                  title="Bulk import"
                  operation={VMSOperations.UPDATE_VEHICLE_AO}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports/export"
                  component={ExportVehicleReports}
                  title="Export vehicle reports"
                  operation={VMSOperations.VIEW_VEHICLE_GF}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports/export"
                  component={ExportVehicleReports}
                  title="Export vehicle reports"
                  operation={VMSOperations.VIEW_VEHICLE_AO}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports/export/:reportId"
                  component={ExportVehicleReports}
                  title="Export vehicle reports"
                  operation={VMSOperations.VIEW_VEHICLE_GF}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/reports/scheduled-reports"
                  component={ScheduledReports}
                  title="Scheduled Reports"
                  operation={VMSOperations.UPDATE_VEHICLE_GF}
                  subject={VMSSubjects.VEHICLE}
                />
                <PrivateRoute
                  exact
                  path="/vehicle/expenses"
                  component={VehicleExpenses}
                  title="Vehicle expenses"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.VEHICLE}
                  featureName="fedfms"
                />
                <PrivateRoute
                  exact
                  path="/fleetcard/replacement/history"
                  component={feature(
                    FcReplacementHistory,
                    'fleet-card-replacement',
                  )}
                  title="FC Replacement History"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.FC_REPLACEMENT}
                />
                <PrivateRoute
                  exact
                  path={`/motor-pools/:${encodeURIComponent('id')}?`}
                  component={feature(MotorPool, 'motor-pools')}
                  title="Dispatch and Reservation"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.MOTOR_POOL}
                />
                <PrivateRoute
                  exact
                  path="/pm-reporting"
                  component={feature(PmExpress, 'pm-express')}
                  title="PM Reporting"
                  operation={VMSOperations.UPDATE_VEHICLE_GF}
                  subject={VMSSubjects.VEHICLE}
                />

                <PrivateRoute
                  exact
                  path="/mileage-reporting"
                  component={feature(MileageExpress, 'mileage-express')}
                  title="Mileage Reporting"
                  operation={VMSOperations.Create}
                  subject={VMSSubjects.GFMileage}
                />

                <PrivateRoute
                  path="/customer-accounts"
                  component={feature(CustomerAccount, 'customer-accounts-view')}
                  operation={VMSOperations.View}
                  subject={VMSSubjects.CUSTOMER_ACCOUNT}
                />
                <PrivateRoute
                  exact
                  path="/office-management/:organization"
                  component={feature(OfficeManagement, 'office-management')}
                  title="Office Management"
                  operation={VMSOperations.View}
                  subject={VMSSubjects.ORGANIZATION_PROFILE}
                />
                <Route exact path="/auth" component={authRedirect} />
                <Route exact path="/">
                  <Redirect to="/vehicles" />
                </Route>
                <PublicRoute
                  exact
                  path="/unauthorized"
                  component={Unauthorized}
                  title="Access Denied"
                />
                <PublicRoute
                  path="/not-opted"
                  component={NotOpted}
                  title="Access Denied"
                />
                <PublicRoute
                  path="*"
                  component={NotFound}
                  title="Sorry for the Detour"
                />
              </Switch>
            </Router>
          </Layout>
        </FeatureProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

export default App;
