/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import usePortalModal from 'utilities/portal-modal';
import {
  Button,
  RequiredFieldIndicator,
  Icon,
  SelectDropdown,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';
import { emdash } from 'components/common';
import { getYesOrNo, getMoneyFormat } from '../vehicle-overview/helpers/common';

export default function LeasingRatesEdit({
  vehicle,
  ratesData,
  editingRates,
  manualEntryRate,
  onSave,
  onClose,
}) {
  const [RatesEditModal, openModal, closeModal] = usePortalModal();
  const [editedData, setEditedData] = useState({});
  const [fieldErrors, setFieldError] = useState({});
  const [isManualEntryRate, setIsManualEntryRate] = useState(manualEntryRate);

  const requiredFields = ['monthlyRate', 'mileageRate'];

  const mileageRateTypeOptions = [
    { value: '', label: '- Select -' },
    {
      value: 'Maintenance only mileage rate',
      label: 'Maintenance only mileage rate',
    },
    {
      value: 'Fuel only mileage rate',
      label: 'Fuel only mileage rate',
    },
    {
      value: 'No mileage rate',
      label: 'No mileage rate',
    },
  ];

  const manualEntryRateOptions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  const handleClose = () => {
    setFieldError({});
    setEditedData({});
    closeModal();
    onClose();
  };

  useEffect(() => {
    if (editingRates) {
      setEditedData(ratesData);
      openModal();
    } else {
      handleClose();
    }
  }, [editingRates]);

  const getFieldErrors = () => {
    const errors = {};
    for (const field of requiredFields) {
      let error = getFieldError(field);
      if (error) errors[field] = error;
    }
    return errors;
  };

  const handleSave = () => {
    const fieldErrors = getFieldErrors();
    if (isEmpty(fieldErrors)) {
      handleClose();
      onSave(editedData);
    } else {
      setFieldError(fieldErrors);
    }
  };

  const getFieldError = (field) => {
    if (isManualEntryRate && !editedData[field]) return 'Field is required';
  };

  const handleDataChange = (field, value) => {
    const updatedData = { ...editedData };

    if (field === 'manualEntryRate') {
      updatedData[field] = value === 'true';
      setIsManualEntryRate(value === 'true');
      if (updatedData[field]) {
        updatedData.modifiedMileageRateType = '';
      } else {
        updatedData.modifiedMileageRateType =
          ratesData?.modifiedMileageRateType;
      }
    } else {
      updatedData[field] = value;
    }
    setEditedData(updatedData);
  };

  return (
    <RatesEditModal
      title={<h2>Edit leasing rates information</h2>}
      onClose={handleClose}
      actions={
        <>
          <Button
            variant="unstyled"
            data-testid="close-rates"
            onClick={handleClose}
            label="Close"
          />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
            data-testid="save-and-close-rates"
          />
        </>
      }
    >
      <p>
        Edit leasing rate information for VIN <strong>{vehicle?.id}</strong> in
        the form below.
      </p>

      <span className="text-italic">
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </span>

      <>
        <div>
          <div className="grid-row grid-gap grid-col-12 margin-top-2">
            <div className="tablet:grid-col-6">
              <SelectDropdown
                id="manualEntryRate"
                label="Manual Entry Rate"
                name="manualEntryRate"
                options={manualEntryRateOptions}
                value={editedData.manualEntryRate}
                onChange={(e) =>
                  handleDataChange('manualEntryRate', e.target.value)
                }
              />
            </div>
            <div className="tablet:grid-col-6 margin-top-2">
              <label htmlFor="monthly-rate">
                <span className="text-bold">
                  Monthly rate <RequiredFieldIndicator />
                </span>
              </label>
              {fieldErrors.monthlyRate && (
                <span className="usa-error-message">
                  {fieldErrors.monthlyRate}
                </span>
              )}
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <NumberFormat
                  value={editedData?.monthlyRate}
                  thousandSeparator={true}
                  thousandsGroupStyle="thousand"
                  disabled={!isManualEntryRate}
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator="."
                  className="usa-input"
                  id="monthlyRate"
                  name="monthlyRate"
                  data-testid="monthlyRate"
                  onValueChange={({ value }) =>
                    handleDataChange('monthlyRate', value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="grid-row grid-gap grid-col-12 margin-top-2">
            <div className="tablet:grid-col-6">
              <label htmlFor="daily-rate">
                <span className="text-bold">Daily rate</span>
              </label>
              <div data-testid="dailyRate" className="text-tabular">
                {getMoneyFormat(editedData?.dailyRate?.toFixed(2)) || emdash}
              </div>
            </div>

            <div className="tablet:grid-col-6">
              <label htmlFor="mileage-rate">
                <span className="text-bold">
                  Mileage rate <RequiredFieldIndicator />
                </span>
              </label>
              {fieldErrors.mileageRate && (
                <span className="usa-error-message">
                  {fieldErrors.mileageRate}
                </span>
              )}
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <NumberFormat
                  value={editedData?.mileageRate}
                  disabled={!isManualEntryRate}
                  thousandSeparator={true}
                  thousandsGroupStyle="thousand"
                  fixedDecimalScale
                  decimalScale={3}
                  decimalSeparator="."
                  className="usa-input"
                  id="mileageRate"
                  name="mileageRate"
                  onValueChange={({ value }) =>
                    handleDataChange('mileageRate', value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="grid-row grid-gap grid-col-12 margin-top-2">
            <div className="tablet:grid-col-6">
              <label htmlFor="optional-equipment-rate">
                <span className="text-bold">Optional equipment rate</span>
              </label>
              {fieldErrors.optionalEquipmentRate && (
                <span className="usa-error-message">
                  {fieldErrors.optionalEquipmentRate}
                </span>
              )}
              <div className="dollar-input">
                <Icon iconName="attach_money" className="prefix-icon-dollar" />
                <NumberFormat
                  value={editedData?.optionalEquipmentRate}
                  thousandSeparator={true}
                  thousandsGroupStyle="thousand"
                  decimalScale={0}
                  allowNegative={false}
                  className="usa-input"
                  id="optionalEquipmentRate"
                  name="optionalEquipmentRate"
                  data-testid="optionalEquipmentRate"
                  onValueChange={({ value }) =>
                    handleDataChange('optionalEquipmentRate', value)
                  }
                />
              </div>
            </div>

            <div className="tablet:grid-col-6">
              <label htmlFor="afv-surcharge-value">
                <span className="text-bold">AFV surcharge</span>
              </label>
              <div data-test-id="afvSurcharge" className="text-tabular">
                {getMoneyFormat(editedData?.afvSurcharge) || emdash}
              </div>
            </div>
          </div>

          <div className="grid-row grid-gap grid-col-12">
            <div className="tablet:grid-col-6">
              <SelectDropdown
                id="modifiedMileageRateType"
                label="Modified Mileage Rate Type"
                name="modifiedMileageRateType"
                disabled={isManualEntryRate}
                options={mileageRateTypeOptions}
                value={editedData?.modifiedMileageRateType}
                onChange={(e) => {
                  handleDataChange('modifiedMileageRateType', e.target.value);
                }}
              />
            </div>

            <div className="tablet:grid-col-6 margin-top-3">
              <label htmlFor="high-cost-threshold-value">
                <span className="text-bold">High cost threshold</span>
              </label>
              <div data-test-id="highCostThreshold" className="text-tabular">
                {getYesOrNo(editedData?.highCostThreshold) || emdash}
              </div>
            </div>
          </div>

          <div className="grid-row grid-gap grid-col-12 margin-top-3">
            <div className="tablet:grid-col-6">
              <label htmlFor="location">
                <span className="text-bold">Location</span>
              </label>
              <div data-test-id="location" className="text-tabular">
                {editedData?.location || emdash}
              </div>
            </div>
            <div className="tablet:grid-col-6 margin-top-1">
              <label htmlFor="opt-in-rate-value">
                <span className="text-bold">
                  Telematics opt-in subscription rate
                </span>
              </label>
              <div data-test-id="optInRate" className="text-tabular">
                {getMoneyFormat(editedData?.optInSubscriptionRate) || emdash}
              </div>
            </div>
          </div>
        </div>
      </>
    </RatesEditModal>
  );
}
