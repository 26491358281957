import React from 'react';
import { emdash } from '../../../../common';

const BillingHistoryTableDetail = (billingHistory) => {
  const borderClasses = 'border-bottom border-base-lighter padding-y-1';
  // eslint-disable-next-line react/destructuring-assignment
  const { original } = billingHistory?.row;

  let actionCode = emdash;
  if (original?.actionCode && original?.actionCode.length === 1) {
    actionCode = original?.actionCode === '2' ? 'FULL' : original?.daysUsed;
  }

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Odometer</strong>
          {original?.endMileage || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Miles driven</strong>
          {original?.milesDriven || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Optional equipment rate</strong>
          {original?.accessoryCharge || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Days billed</strong>
          {original?.daysUsed || emdash}
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>A1 action code</strong>
          {actionCode}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>BOAC</strong>
          {original?.customerBoac || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Customer account name</strong>
          {original?.customerAccountName || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Customer account number</strong>
          {original?.customerAccountId || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Legacy account number</strong>
          {original?.legacyCustomerNumber || emdash}
        </div>
      </div>
    </div>
  );
};

export default BillingHistoryTableDetail;
