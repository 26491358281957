import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  SelectDropdown,
  MultiSelectDropdown,
  Button,
  useModal,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import { emdash } from 'components/common';
import { CDD_FORM_FIELDS } from './consts';
import CDDPopOverModal from './cdd-pop-over-modal';
import { populateTelematicsOptions } from '../../helpers/utils';

const TelematicsForm = ({ isAOVehicle }) => {
  const {
    formState: { errors },
    control,
    aldCategories,
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const watchTelematicsUsage = watch(
    CDD_FORM_FIELDS?.telematicsDataUsage?.name,
  );

  const hasTelematicsDataUsage =
    watchTelematicsUsage?.length > 0 ||
    getValues(CDD_FORM_FIELDS?.telematicsDataUsage?.name)?.length > 0;

  const telematicsCardClasses = classnames(
    'padding-2 bg-blue-5 border-2px border-primary-lighter title-s text-bold radius-md margin-bottom-1 display-block',
  );

  const { isOpen, openModal, closeModal } = useModal();

  const renderGFVehicleFields = () => {
    return (
      <>
        <div className="text-bold margin-top-2">Vehicle telematics</div>
        <div className="margin-bottom-3">
          <span>
            {getValues(CDD_FORM_FIELDS.cddTelematicsInstalled.name) || emdash}
          </span>
        </div>
        <div className="margin-top-3">
          <>
            <span className="text-bold">Vehicle telematics data usage</span>
            <Button
              position="right"
              variant="unstyled"
              className="margin-left-1"
              rightIcon={{ name: 'info', className: 'text-black' }}
              onClick={() => {
                openModal();
              }}
              data-testid="telematics-info-button"
              aria-label="info"
            />
          </>
        </div>
        <div className="margin-bottom-neg-3">
          <span>
            {getValues(CDD_FORM_FIELDS?.telematicsDataUsage?.name) || emdash}
          </span>
        </div>
      </>
    );
  };

  const renderAOVehicleFields = () => {
    return (
      <>
        <div className="grid-col-6">
          <Controller
            name={CDD_FORM_FIELDS.cddTelematicsInstalled.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <SelectDropdown
                  {...field}
                  ref={ref}
                  data-testid="telematics-device-installed"
                  label={<span className="text-bold">Vehicle telematics</span>}
                  name={CDD_FORM_FIELDS.cddTelematicsInstalled.name}
                  id={CDD_FORM_FIELDS.cddTelematicsInstalled.id}
                  options={[
                    { value: '', label: 'Select' },
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                    { value: 'Waiver', label: 'Waiver' },
                  ]}
                  errorMessage={
                    errors?.[CDD_FORM_FIELDS.cddTelematicsInstalled.name]
                      ? errors[CDD_FORM_FIELDS.cddTelematicsInstalled.name]
                          .message
                      : null
                  }
                  aria-invalid={
                    errors?.[CDD_FORM_FIELDS.cddTelematicsInstalled.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
        </div>
        <div className="margin-top-3 margin-bottom-3">
          <>
            <span className="text-bold">Vehicle telematics data usage</span>
            <Button
              position="right"
              variant="unstyled"
              className="margin-left-1"
              rightIcon={{ name: 'info', className: 'text-black' }}
              onClick={() => {
                openModal();
              }}
              data-testid="telematics-info-button"
              aria-label="info"
            />
          </>
          {hasTelematicsDataUsage && (
            <span className={telematicsCardClasses}>
              {watchTelematicsUsage}
            </span>
          )}
          <Controller
            name={CDD_FORM_FIELDS.telematicsDataUsage.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <MultiSelectDropdown
                  {...field}
                  ref={ref}
                  name={CDD_FORM_FIELDS.telematicsDataUsage.name}
                  id={CDD_FORM_FIELDS.telematicsDataUsage.id}
                  placeholder="Select telematics data usage"
                  options={populateTelematicsOptions(aldCategories)}
                  onChange={(items) => {
                    items.sort();
                    setValue(CDD_FORM_FIELDS.telematicsDataUsage.name, items);
                  }}
                  selectedValues={
                    getValues(CDD_FORM_FIELDS.telematicsDataUsage.name) || []
                  }
                  errorMessage={
                    errors?.[CDD_FORM_FIELDS.telematicsDataUsage.name]
                      ? errors[CDD_FORM_FIELDS.telematicsDataUsage.name].message
                      : null
                  }
                  aria-invalid={
                    errors?.[CDD_FORM_FIELDS.telematicsDataUsage.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div data-testid="cdd-telematics-form">
      {isAOVehicle ? renderAOVehicleFields() : renderGFVehicleFields()}
      {isOpen && (
        <CDDPopOverModal
          isOpen={isOpen}
          closeModal={closeModal}
          title="Vehicle telematics data usage"
        />
      )}
    </div>
  );
};

TelematicsForm.propTypes = {
  isAOVehicle: PropTypes.bool.isRequired,
};

export default TelematicsForm;
