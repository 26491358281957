import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';
import { Button, Alert, Spinner } from '@gsa/afp-component-library';
import { vehToPDFpayload } from 'utilities/format';
import usePortalModal from 'utilities/portal-modal';
import { BULK_REGISTRATION_PDF } from '../../../services/data-layer';
import { carTitleConstructor } from '../../../utilities/car-title';
import { vehicleRegistrationIsIncomplete } from '../../vehicle-registration-status-tag';
import { getRegistrationPDFPayload } from '../../registration-details/helpers/payload-constructors';

export default function bulkRegDownload(alertUtils) {
  const [Modal, openModal, closeModal] = usePortalModal();
  const [createBulkPDFs, { loading: creatingBulkPDFs }] = useMutation(
    BULK_REGISTRATION_PDF,
    {
      onCompleted: (data) => {
        if (data?.createBulkVehiclePDFRegistrations) {
          alertUtils.showSuccessAlert(
            <span>
              Your request for generation of registration card(s) has been
              submitted. You will receive an email when the PDF file is ready
              for download.
            </span>,
          );
          window.open(data.createBulkVehiclePDFRegistrations);
          closeModal();
        }
      },
      onError: () => {
        alertUtils.showErrorAlert(
          <span>
            There was an error in your request for generation of registration
            card(s). Please try again later. If the problem persists, please
            call or email the helpdesk. For further assistance,{' '}
            <FleetTechnicalSupportContact />
          </span>,
        );
      },
    },
  );

  const [vehicles, setVehicles] = useState(null);
  const [unregistered, setUnregistered] = useState(null);

  const initVehicleRegDownload = (vehs) => {
    const formattedPayload = vehs
      .filter(
        (v) =>
          (!!v.tagNumber || v.exemptPlate) &&
          !vehicleRegistrationIsIncomplete(v),
      )
      .map((v) => vehToPDFpayload(v));

    const unusableVehs = vehs.length - formattedPayload.length;
    setVehicles(formattedPayload);
    setUnregistered(unusableVehs);
    openModal();
  };

  const generatePDFsForVehs = () => {
    alertUtils.closeAlert();
    if (vehicles.length) {
      const payload = vehicles.map((veh) => {
        return getRegistrationPDFPayload(veh);
      });
      createBulkPDFs({ variables: { vehicles: payload } });
      closeModal();
    }
  };

  const BulkRegistrationModal = () => {
    if (creatingBulkPDFs) {
      return (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      );
    }

    return (
      <Modal
        variant="large"
        data-testid="bulk-reg-download-modal"
        title={
          <>
            {unregistered ? (
              <Alert
                className="margin-top-4 margin-right-4 margin-bottom-2"
                type="warning"
                slim
              >
                <span className="text-bold">{unregistered}</span> registration
                card(s) <span className="text-bold">cannot</span> be downloaded
                because the vehicle is currently{' '}
                <span className="text-bold">unregistered</span>, registration is
                incomplete, or vehicle is "exempt" from having a U.S. Government
                license plate.
              </Alert>
            ) : null}
            {vehicles?.length === 1 ? (
              <h2>
                {' '}
                <span data-testid="registrations">{vehicles?.length}</span>{' '}
                registration card(s) will be downloaded
              </h2>
            ) : (
              <h2>
                {' '}
                <span data-testid="registrations">{vehicles?.length}</span>{' '}
                registration cards will be downloaded
              </h2>
            )}
          </>
        }
        actions={
          <>
            <Button
              variant="unstyled"
              className="margin-right-2"
              onClick={closeModal}
              label="Cancel"
            />
            {vehicles?.length === 1 ? (
              <Button
                onClick={generatePDFsForVehs}
                label={`Download ${vehicles?.length} registration card(s)`}
              />
            ) : (
              <Button
                onClick={generatePDFsForVehs}
                label={`Download ${vehicles?.length} registration cards`}
              />
            )}
          </>
        }
      >
        {vehicles?.length > 0 && (
          <div className="bulk-registration-download">
            {vehicles?.length === 1 ? (
              <p>
                Registration card(s) will be downloaded for following vehicles:
              </p>
            ) : (
              <p>
                Registration cards will be downloaded for following vehicles:
              </p>
            )}
            <ul>
              {vehicles &&
                vehicles.map((v) => (
                  <li key={v.id} className="text-uppercase">
                    <span className="text-bold">
                      {carTitleConstructor(
                        v.modelYear,
                        v.vehicleMake,
                        v.vehicleModel,
                        v.modelCode,
                      )}
                    </span>
                    ({v.id})
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Modal>
    );
  };

  return [BulkRegistrationModal, initVehicleRegDownload];
}
