import React, { useEffect, useState } from 'react';
import usePortalModal from 'utilities/portal-modal';
import moment from 'moment';
import useUser from 'utilities/use-user';

import {
  Button,
  RequiredFieldIndicator,
  SelectDropdown,
  TextInput,
  DatePicker,
  Icon,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { VehiclePropType } from 'utilities/types';
import { emdash } from 'components/common';
import { getMoneyFormat } from './valuation-edit';

export default function DisposalEdit({
  vehicle,
  valuationData,
  editingDisposal,
  onSave,
  onClose,
  options,
  setSectionMsg,
}) {
  const [DisposalEditModal, openModal, closeModal] = usePortalModal();

  const [editedData, setEditedData] = useState({});
  const [editedError, setEditedError] = useState({});
  const [dayAfterAcquisitionDate, setDayAfterAcquisitionDate] = useState(null);

  const isOwnershipAO = vehicle?.ownershipTypeCode === 'AO';
  const isOwnershipGF = vehicle?.ownershipTypeCode === 'GF';
  const { isRole } = useUser();
  const numericRegExp = /^$|^[0-9]+$/i;

  const hasFMCManagerOrFMVRSrrole =
    isRole('FMVRSAdminRole') || isRole('FMCManager');

  const hasFMCManagerOrFMVRSerOrFSRrole =
    isRole('FMVRSAdminRole') || isRole('FMCManager') || isRole('FleetSvcRep');

  const handleClose = () => {
    closeModal();
    onClose();
  };

  const [errors, setErrors] = useState({});

  const validate = (e, field) => {
    if (!numericRegExp.test(e.target.value)) {
      setErrors({
        [field]: 'Only numbers allowed',
      });
      return;
    } else {
      setErrors({ ...errors, [field]: '' });
    }
  };

  useEffect(() => {
    if (editingDisposal) {
      setEditedData(valuationData);

      if (valuationData?.originalAcquisitionDate) {
        const tomorrow = moment(valuationData.originalAcquisitionDate)
          .utc()
          .add(1, 'days');

        setDayAfterAcquisitionDate(tomorrow);
      }

      openModal();
    } else {
      handleClose();
    }
  }, [editingDisposal]);

  useEffect(() => {
    setEditedData(valuationData);
  }, []);

  const handleSave = () => {
    if (!editedError?.actualDisposalDateError && !errors.disposalCondition) {
      onSave(editedData);
      handleClose();
    }
  };

  const handleEditedData = (field, ev) => {
    const updatedData = { ...editedData };
    if (field === 'actualDisposalReason' && ev !== '') {
      updatedData[field] = options.find((option) => option.value === ev).label;
    } else if (field === 'actualDisposalDate') {
      if (moment(ev).diff(moment(valuationData?.originalAcquisitionDate)) < 0) {
        setEditedError({
          ...editedError,
          actualDisposalDateError:
            'Disposal date must be greater than the acquisition date (when provided)',
        });
      } else {
        setEditedError({
          ...editedError,
          actualDisposalDateError: undefined,
        });
      }
      updatedData[field] = ev;
    } else {
      updatedData[field] = ev;
    }
    setEditedData(updatedData);
  };

  const getDisposalReasonValue = (reason) => {
    const optionValue =
      options.find((option) => option.label === reason)?.value ?? '10';
    return reason ? optionValue : '0';
  };

  return (
    <DisposalEditModal
      title={<h2>Edit disposal information</h2>}
      onClose={handleClose}
      actions={
        <>
          <Button variant="unstyled" onClick={handleClose} label="Close" />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <p>
        Edit disposal information for VIN <strong>{vehicle?.id}</strong> in the
        form below.
      </p>

      <span className="text-italic">
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </span>

      {isOwnershipAO && (
        <>
          <div>
            <div className="grid-row grid-gap grid-col-12 margin-top-3">
              <div className="tablet:grid-col-6">
                <label htmlFor="replacement-eligibility">
                  <span className="text-bold">
                    Projected replacement eligibility
                  </span>
                </label>
                <div>
                  <DatePicker
                    defaultValue={
                      editedData?.eligibleReplacementDate
                        ? moment
                            .utc(editedData?.eligibleReplacementDate)
                            .format('YYYY-MM-DD')
                        : ''
                    }
                    id="replacement-eligibility"
                    name="replacement-eligibility"
                    data-testid="replacement-eligibility"
                    hint="mm/dd/yyyy"
                    onChange={(val) =>
                      handleEditedData('eligibleReplacementDate', val)
                    }
                    errorMessage={editedError?.eligibleReplacementDateError}
                  />
                </div>
              </div>

              <div className="tablet:grid-col-6">
                <label htmlFor="turn-in-reason">
                  <span className="text-bold">Sale turn in reason</span>
                </label>

                <div className="margin-top-neg-2">
                  <TextInput
                    maxLength={1}
                    name="turn-in-code"
                    type="input"
                    value={editedData?.turnInCode}
                    onChange={(e) =>
                      handleEditedData('turnInCode', e.target.value)
                    }
                    data-testid="turn-in-reason"
                  />
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-cost">
                  <span className="text-bold">Disposal date</span>
                </label>

                <div>
                  <DatePicker
                    defaultValue={editedData?.actualDisposalDate}
                    id="disposal-date"
                    name="disposal-date"
                    data-testid="disposal-date"
                    hint="mm/dd/yyyy"
                    minDate={dayAfterAcquisitionDate?.format('YYYY-MM-DD')}
                    onChange={(val) =>
                      handleEditedData('actualDisposalDate', val)
                    }
                    errorMessage={editedError?.actualDisposalDateError}
                  />
                </div>
              </div>

              <div className="tablet:col-6">
                <SelectDropdown
                  name="disposalReason"
                  label="Select disposal reason"
                  onChange={(e) =>
                    handleEditedData('actualDisposalReason', e.target.value)
                  }
                  value={getDisposalReasonValue(
                    editedData?.actualDisposalReason,
                  )}
                  options={options}
                />
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-cost">
                  <span className="text-bold">Disposal cost</span>
                </label>
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    value={editedData?.disposalCost}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    className="usa-input"
                    id="disposal-cost"
                    name="disposal-cost"
                    onValueChange={({ value }) =>
                      handleEditedData('disposalCost', value)
                    }
                  />
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-proceeds">
                  <span className="text-bold">Disposal proceeds</span>
                </label>
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    value={editedData?.disposalProceeds}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    className="usa-input"
                    id="disposal-proceeds"
                    name="disposal-proceeds"
                    onValueChange={({ value }) =>
                      handleEditedData('disposalProceeds', value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isOwnershipGF && (
        <>
          <div>
            <div className="grid-row grid-gap grid-col-12 margin-top-3">
              <div className="tablet:grid-col-6">
                <label htmlFor="replacement-eligibility">
                  <span className="text-bold">
                    Projected replacement eligibility
                  </span>
                </label>
                <div
                  data-test-id="eligibleReplacementDate"
                  className="text-tabular"
                >
                  {' '}
                  {valuationData?.eligibleReplacementDate
                    ? moment
                        .utc(valuationData.eligibleReplacementDate)
                        .format('MM/DD/YYYY')
                    : emdash}
                </div>
              </div>

              {hasFMCManagerOrFMVRSrrole && (
                <div className="tablet:grid-col-6">
                  <label htmlFor="turn-in-reason">
                    <span className="text-bold">Sale turn in reason</span>
                  </label>

                  <div className="margin-top-neg-2">
                    <TextInput
                      maxLength={1}
                      name="turn-in-code"
                      type="input"
                      value={editedData?.turnInCode}
                      onChange={(e) =>
                        handleEditedData('turnInCode', e.target.value)
                      }
                      data-testid="turn-in-reason"
                    />
                  </div>
                </div>
              )}
              {!hasFMCManagerOrFMVRSrrole && (
                <div className="tablet:grid-col-6">
                  <label htmlFor="turn-in-reason">
                    <span className="text-bold">Sale turn in reason</span>
                  </label>
                  <div data-test-id="turn-in-reason" className="text-tabular">
                    {editedData?.turnInCode || emdash}
                  </div>
                </div>
              )}

              {hasFMCManagerOrFMVRSrrole && (
                <div className="tablet:grid-col-6 margin-top-2">
                  <label htmlFor="turn-in-location">
                    <span className="text-bold">Sale turn in location</span>
                  </label>

                  <div className="margin-top-neg-2">
                    <TextInput
                      maxLength={6}
                      name="turn-in-location"
                      type="input"
                      value={editedData?.turnInLocation}
                      onChange={(e) =>
                        handleEditedData('turnInLocation', e.target.value)
                      }
                      data-testid="turn-in-location"
                    />
                  </div>
                </div>
              )}
              {!hasFMCManagerOrFMVRSrrole && (
                <div className="tablet:grid-col-6 margin-top-2">
                  <label htmlFor="turn-in-location">
                    <span className="text-bold">Sale turn in location</span>
                  </label>
                  <div data-testid="turn-in-location" className="text-tabular">
                    {editedData?.turnInLocation || emdash}
                  </div>
                </div>
              )}

              {hasFMCManagerOrFMVRSerOrFSRrole && (
                <div className="tablet:grid-col-6 margin-top-2">
                  <label htmlFor="disposal-condition">
                    <span className="text-bold">Current condition</span>
                  </label>

                  <div className="margin-top-neg-2">
                    <TextInput
                      maxLength={1}
                      name="disposalCondition"
                      type="input"
                      value={editedData?.disposalCondition}
                      onChange={(e) =>
                        handleEditedData('disposalCondition', e.target.value)
                      }
                      onBlur={(e) => validate(e, 'disposalCondition')}
                      errorMessage={errors.disposalCondition}
                      data-testid="disposalCondition"
                    />
                  </div>
                </div>
              )}

              {!hasFMCManagerOrFMVRSerOrFSRrole && (
                <div className="tablet:grid-col-6">
                  <label htmlFor="disposal-condition">
                    <span className="text-bold">Current condition</span>
                  </label>
                  <div
                    data-testid="disposal-condition"
                    className="text-tabular"
                  >
                    {editedData?.disposalCondition || emdash}
                  </div>
                </div>
              )}

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-date">
                  <span className="text-bold">Disposal date</span>
                </label>

                <div data-testid="actualDisposalDate" className="text-tabular">
                  {' '}
                  {valuationData?.actualDisposalDate
                    ? moment
                        .utc(valuationData.actualDisposalDate)
                        .format('MM/DD/YYYY')
                    : emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-reason">
                  <span className="text-bold">Disposal reason</span>
                </label>

                <div
                  data-testid="actualDisposalReason"
                  className="text-tabular"
                >
                  {editedData?.actualDisposalReason || emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-cost">
                  <span className="text-bold">Disposal cost</span>
                </label>

                <div data-testid="disposalCost" className="text-tabular">
                  {getMoneyFormat(editedData?.disposalCost) || emdash}
                </div>
              </div>

              <div className="tablet:grid-col-6 margin-top-3">
                <label htmlFor="disposal-proceeds">
                  <span className="text-bold">Disposal proceeds</span>
                </label>
                <div data-testid="disposalProceeds" className="text-tabular">
                  {getMoneyFormat(editedData?.disposalProceeds) || emdash}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DisposalEditModal>
  );
}

export const valuationData = {
  originalAcquisitionDate: PropTypes.string,
};

export const option = {
  value: PropTypes.string,
  label: PropTypes.string,
};

DisposalEdit.propTypes = {
  vehicle: PropTypes.shape(VehiclePropType).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editingDisposal: PropTypes.bool.isRequired,
  setSectionMsg: PropTypes.func.isRequired,
  valuationData: PropTypes.shape(valuationData).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(option)).isRequired,
};
