import React, { createContext, useContext, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal } from '@gsa/afp-component-library';
import { ADD_PM, UPDATE_PM } from 'components/pm-express/pm-express.gql';
import exportMessage from 'utilities/export-message';
import { REPORT_DELIVERY_METHOD, REPORT_TYPE } from 'utilities/consts';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { EXPORT_REPORT } from 'services/data-layer';
import {
  GET_PM_BY_VEHICLE,
  GET_PM_SCHEDULES,
  UPDATE_ASSET_PM_SCHEDULE,
  DELETE_PM,
} from './pm.gql';

export const PmContext = createContext();

const initialState = {
  pmHistoryList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  alertMessage: { context: '', message: '', type: '', header: null },
  selectedPm: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PM_HISTORY_LIST':
      return { ...state, pmHistoryList: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_SELECTED_PM':
      return { ...state, selectedPm: action.payload };
    case 'SET_PM_SCHEDULES':
      return { ...state, pmSchedules: action.payload };
    default:
      throw new Error('Invalid action');
  }
};

function PmProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dateFilters, setDateFilters] = useState();
  const { setAlertMessages, refetchVehicle } = useVehicle();

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const {
    isOpen: isManagePmModalOpen,
    openModal: openManagePmModal,
    closeModal: closeManagePmModal,
  } = useModal();

  const {
    isOpen: isDeletePmModalOpen,
    openModal: openDeletePmModal,
    closeModal: closeDeletePmModal,
  } = useModal();

  const {
    isOpen: isEditPmScheduleModalOpen,
    openModal: openEditPmScheduleModal,
    closeModal: closeEditPmScheduleModal,
  } = useModal();

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  const [
    getPmByVehicle,
    { refetch: refetchPmByVehicle, loading: pmHistoryListLoading },
  ] = useLazyQuery(GET_PM_BY_VEHICLE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'pmHistoryListing'),
    onCompleted: (responseData) => {
      if (responseData?.getPmByVehicle) {
        dispatchAction('SET_PM_HISTORY_LIST', responseData.getPmByVehicle);
      }
    },
  });

  const [addPm, { loading: addingPm }] = useMutation(ADD_PM, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'pmListing'),
    onCompleted: (response) => {
      if (response.addPm) {
        refetchPmByVehicle();
        refetchVehicle();
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: 'PM added successfully',
        });
        dispatch({
          type: 'SET_SELECTED_PM',
          payload: undefined,
        });
        closeManagePmModal();
      }
    },
  });

  const [updatePm, { loading: updatingPm }] = useMutation(UPDATE_PM, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'pmListing'),
    onCompleted: (response) => {
      if (response.updatePm.length > 0) {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: 'PM updated successfully',
        });
        dispatch({
          type: 'SET_SELECTED_PM',
          payload: undefined,
        });
        refetchPmByVehicle();
        refetchVehicle();
        closeManagePmModal();
      }
    },
  });

  const [exportData] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setAlertMessages([
        {
          ...exportMessage.exportSuccessMessage,
        },
      ]);
    },
    onError: () => {
      setAlertMessages([
        {
          ...exportMessage.exportMsgError,
        },
      ]);
    },
  });

  const onExport = (vin) => {
    if (state.pmHistoryList.rows.length === 0) {
      setAlertMessages([
        {
          ...exportMessage.exportMsgNoData('Preventive Maintenance'),
        },
      ]);
      return;
    }

    const filters = {
      operator: '$and',
      conditions: [
        {
          key: '$vehicle.serial_number_vin',
          operation: '$equal',
          value: vin,
        },
      ],
    };

    if (dateFilters) {
      filters.conditions.push(dateFilters.conditions[0]);
    }

    exportData({
      variables: {
        request: {
          reportType: REPORT_TYPE.PREVENTATIVE_MAINTENANCE_EXPRESS,
          deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
          reportCriteria: {
            filters,
          },
        },
      },
    });
  };

  const [getPmSchedules, { loading: pmSchedulesLoading }] = useLazyQuery(
    GET_PM_SCHEDULES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'pmSchedules'),
      onCompleted: (responseData) => {
        if (responseData?.getPreventativeMaintenanceSchedules) {
          dispatchAction(
            'SET_PM_SCHEDULES',
            responseData.getPreventativeMaintenanceSchedules,
          );
        }
      },
    },
  );

  const [updateAssetPmSchedule, { loading: updateAssetPmScheduleLoading }] =
    useMutation(UPDATE_ASSET_PM_SCHEDULE, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'pmSchedules'),
      onCompleted: (responseData) => {
        if (responseData.updateAssetPmSchedule) {
          closeEditPmScheduleModal();
          refetchVehicle();
          setAlertMessages([
            {
              type: 'success',
              message: 'PM schedule updated successfully',
            },
          ]);
        }
      },
    });

  const [deletePm, { loading: deletePmLoading }] = useMutation(DELETE_PM, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'deletePm'),
    onCompleted: (responseData) => {
      if (responseData.deletePm) {
        closeDeletePmModal();
        setAlertMessages([
          {
            type: 'success',
            message: 'PM deleted successfully',
          },
        ]);
        dispatch({
          type: 'SET_SELECTED_PM',
          payload: undefined,
        });
        refetchVehicle();
      }
    },
  });

  return (
    <PmContext.Provider
      value={{
        ...state,
        dispatch,
        getPmByVehicle,
        pmHistoryListLoading,
        getPmSchedules,
        pmSchedulesLoading,
        updateAssetPmSchedule,
        updateAssetPmScheduleLoading,
        isManagePmModalOpen,
        openManagePmModal,
        closeManagePmModal,
        isDeletePmModalOpen,
        openDeletePmModal,
        closeDeletePmModal,
        isEditPmScheduleModalOpen,
        openEditPmScheduleModal,
        closeEditPmScheduleModal,
        addPm,
        addingPm,
        updatePm,
        updatingPm,
        deletePm,
        deletePmLoading,
        onExport,
        dateFilters,
        setDateFilters,
        ...props,
      }}
    >
      {children}
    </PmContext.Provider>
  );
}

export default PmProvider;

PmProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const usePm = () => useContext(PmContext);
