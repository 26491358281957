/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { terminateSchema } from './terminate-vehicle-form-schema';

const TerminateVehicleForm = ({
  onSubmit,
  terminationData,
  setTerminationData,
}) => {
  const terminateVehicleRef = useRef(null);

  useEffect(() => {
    if (!terminateVehicleRef.current) return () => {};
    const subscription = terminateVehicleRef.current.watch((data) => {
      setTerminationData({
        ...terminationData,
        ...data,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [terminateVehicleRef.current]);

  const today = new Date();

  const defaultFormData = useMemo(
    () => ({
      terminationDate: '',
    }),
    [],
  );
  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            id: 'terminationDate',
            type: 'datepicker',
            label: 'Termination date',
            required: true,
            element: {
              control: {
                maxDate: today.toISOString(),
                minDate: new Date(today.getFullYear(), today.getMonth(), 1).toISOString(),
              },
            },
          },
          {
            id: 'endOdometer',
            type: 'text',
            label: 'End mileage',
            required: true,
          },
        ],
      },
      {
        fields: [
          {
            id: 'comment',
            type: 'textarea',
            label: 'Remarks',
            element: {
              control: {
                maxLength: 100,
                countdown: true,
              },
            },
          },
        ],
      },
    ],
  };
  return (
    <FormGenerator
      ref={terminateVehicleRef}
      schema={terminateSchema}
      id="terminate-vehicle-form"
      data-testid="terminate-vehicle-form"
      defaultValues={defaultFormData}
      content={formContent}
      onSubmit={onSubmit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};

export default TerminateVehicleForm;
