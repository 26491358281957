import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { canUpdateGFVehicleBM } from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useVehicle } from '../../../vehicle-context-provider';
import useCanPerformActions from '../../../../../hooks/use-can-perform-actions';
import useUser from '../../../../../utilities/use-user';
import {
  GET_ASSET_DISPOSAL,
  UPDATE_OR_CREATE_ASSET_DISPOSAL,
} from '../../../../../services/data-layer';
import ValuationEdit, { getMoneyFormat } from './valuation-edit';
import { VehiclePropType } from '../../../../../utilities/types';

const Valuation = ({ data }) => {
  const { setTabMsg } = useVehicle();
  const [valuationData, setValuationData] = useState({});

  const [editingValuation, setValuationEditing] = useState(false);

  const canPerformActions = useCanPerformActions();
  const { isRole } = useUser();
  const hasFSRrole = isRole('FSR');
  const hasCFMrole = isRole('CustFltMan');
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const hasSiteAdmin = isRole('SiteAdmin');
  const hasCustFltManRole = isRole('CustFltMan');
  const ability = useAppAbility();

  const canEditValuationAO = canPerformActions.canEditEquipment(
    data,
    hasFSRrole,
    hasCFMrole,
    hasFMVRSrole,
    hasSiteAdmin,
    hasCustFltManRole,
  );

  const canUpdateGF =
    isFeatureEnabled('edit-gf-vehicle') && canUpdateGFVehicleBM(ability);

  const canEdit =
    data.ownershipTypeCode === 'GF' ? canUpdateGF : canEditValuationAO;

  const [
    updateOrCreateValuation,
    { data: vehicleValuationData, loading: vehicleValuationDataLoading },
  ] = useMutation(UPDATE_OR_CREATE_ASSET_DISPOSAL, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setTabMsg({
        type: 'error',
        message: error?.message,
      });
    },
  });

  const [
    getAssetDisposal,
    { data: getAssetDisposalResponse, loading: getAssetDisposalLoading },
  ] = useLazyQuery(GET_ASSET_DISPOSAL, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (getAssetDisposalResponse)
      setValuationData(getAssetDisposalResponse.getDisposalByAssetId);
  }, [getAssetDisposalResponse]);

  useEffect(() => {
    if (vehicleValuationData?.updateOrCreateDisposal) {
      setValuationData(vehicleValuationData.updateOrCreateDisposal);
      setTabMsg({
        type: 'success',
        message: 'Valuation was successfully saved',
      });
    }
  }, [vehicleValuationData]);

  useEffect(() => {
    if (data.uuid) {
      getAssetDisposal({ variables: { assetId: data.uuid } });
    }
  }, [data]);

  const handleCloseValuationEdit = () => {
    setValuationEditing(false);
  };

  const handleSaveValuation = (editedValuationData) => {
    const {
      salvageValue,
      acquisitionCost,
      salvagePercent,
      usefulLifeMonths,
      capitalizedItem,
      usefulLifeMiles,
      maximumRepairAmount,
      depreciationCumulative,
      bookValue,
      capitalValue,
    } = editedValuationData;

    const updatedValuationData = {
      ...omit(valuationData, ['id', '__typename']),
      salvageValue: Number(salvageValue),
      acquisitionCost: Number(acquisitionCost),
      salvagePercent: Number(salvagePercent),
      usefulLifeMonths: Number(usefulLifeMonths),
      usefulLifeMiles: Number(usefulLifeMiles),
      capitalizedItem,
      maximumRepairAmount: Number(maximumRepairAmount),
      depreciationCumulative: Number(depreciationCumulative),
      bookValue: Number(bookValue),
      capitalValue: Number(capitalValue),
    };

    updateOrCreateValuation({
      variables: {
        assetDisposalArg: updatedValuationData,
      },
    });
  };

  const capitalizedItemOptions = [
    { value: '', label: 'Select' },
    { value: 'X', label: 'N/A' },
    { value: 'N', label: 'No' },
    { value: 'Y', label: 'Yes' },
  ];

  const emdash = '\u2014';

  const gfData = [
    [
      'Initial acquisition cost',
      getMoneyFormat(valuationData?.acquisitionCost) || emdash,
    ],
    [
      'Capitalized value',
      getMoneyFormat(valuationData?.capitalValue) || emdash,
    ],
    ['Book value', getMoneyFormat(valuationData?.bookValue) || emdash],
    ['Salvage value', getMoneyFormat(valuationData?.salvageValue) || emdash],
    [
      'Depreciable useful life',
      valuationData.usefulLifeMonths
        ? `${valuationData.usefulLifeMonths} months`
        : emdash,
    ],
    [
      'Cumulative depreciation ',
      getMoneyFormat(valuationData?.depreciationCumulative) || emdash,
    ],
    [
      'Forecasted value at sale',
      getMoneyFormat(valuationData?.forecastedResidualValue) || emdash,
    ],
    [
      'Forecasted residual by third party?',
      valuationData?.isResidualValueComplete ? 'Yes' : 'No',
    ],
  ];

  const aoData = [
    [
      'Acquisition cost',
      getMoneyFormat(valuationData?.acquisitionCost) || emdash,
    ],
    [
      'Capitalized value',
      getMoneyFormat(valuationData?.capitalValue) || emdash,
    ],
    [
      'Capitalized item',
      valuationData?.capitalizedItem
        ? `${
            capitalizedItemOptions.find(
              (item) => item.value === valuationData?.capitalizedItem,
            )?.label
          }`
        : '—',
    ],
    ['Book value', getMoneyFormat(valuationData?.bookValue) || emdash],
    ['Salvage percent', `${valuationData?.salvagePercent ?? 0}%`],
    ['Useful life (months)', valuationData?.usefulLifeMonths ?? emdash],
    [
      'Useful life (miles)',
      valuationData?.usefulLifeMiles
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || emdash,
    ],
    [
      'Cumulative depreciation ',
      getMoneyFormat(valuationData?.depreciationCumulative) || emdash,
    ],
    [
      'Maximum repair amount',
      getMoneyFormat(valuationData?.maximumRepairAmount) || emdash,
    ],
  ];

  const tableData = data.ownershipTypeCode === 'GF' ? gfData : aoData;

  return (
    <>
      <h4 className="title-s-caps text-primary margin-bottom-1">Valuation</h4>
      <div
        className="bg-gray-3 radius-md padding-y-2 padding-x-4"
        data-testid={`afp-valuation-${valuationData?.id}`}
      >
        <DetailsTable
          className="afp-registration__section_container text-tabular"
          data={tableData}
        />
        {canEdit && (
          <Button
            data-testid={`valuation-edit-${valuationData?.id}`}
            onClick={() => setValuationEditing(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit point of contact ${valuationData?.id}`}
            label="Edit"
          />
        )}
        {editingValuation && (
          <div className="grid-col-12 pull-right">
            <ValuationEdit
              vehicle={data}
              valuationData={valuationData}
              editingValuation={editingValuation}
              onClose={handleCloseValuationEdit}
              onSave={handleSaveValuation}
              capitalizedItemOptions={capitalizedItemOptions}
            />
          </div>
        )}
      </div>
      {(vehicleValuationDataLoading ||
        getAssetDisposalLoading ||
        vehicleValuationDataLoading) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

Valuation.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default Valuation;
