import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DetailsTable, Spinner, Alert } from '@gsa/afp-component-library';
import { emdash } from 'components/common';
import moment from 'moment';
import { useVehicle } from '../../vehicle-context-provider';
import { isTelematicsEnabled } from '../../helpers/feature-helper';
import { GET_VEHICLE_TELEMATICS } from './telematics.gql';

const formatData = (data) => {
  return data.map((item) => {
    return [
      item.label,
      <span className="float-right align-right">{item?.value ?? emdash}</span>,
    ];
  });
};

const Telematics = () => {
  const { vehicle } = useVehicle();
  const { ownershipTypeCode, id, customerId } = vehicle;
  if (!vehicle || !isTelematicsEnabled(ownershipTypeCode)) return null;

  const [telematicsData, setTelematicsData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const [
    getVehicleTelematicsData,
    { loading: telematicsDataLoading, error: telematicsDataError },
  ] = useLazyQuery(GET_VEHICLE_TELEMATICS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (responseData) => {
      if (responseData) {
        setTelematicsData(responseData.getVehicleTelematics);
      }
    },
  });

  useEffect(() => {
    // Fetch Telematics data
    getVehicleTelematicsData({
      variables: {
        vin: id,
      },
    });
  }, [id]);

  useEffect(() => {
    if (telematicsData) {
      const subscriptionDataMapping = [
        {
          label: 'Telematics installed',
          value: telematicsData?.isTelematicsInstalled ? 'Yes' : 'No',
        },
        {
          label: 'Customer subscription activated',
          value: telematicsData?.assetTelematicsSubscriptions?.[0]
            ?.isSubscriptionActive
            ? 'Yes'
            : 'No',
        },
        {
          label: 'GSA subscription activation date',
          value: telematicsData?.assetTelematicsSubscriptions?.[0]
            ?.subscriptionActivationDate
            ? moment(
                telematicsData?.assetTelematicsSubscriptions?.[0]
                  ?.subscriptionActivationDate,
              ).format('MM/DD/YYYY')
            : null,
        },
        {
          label: 'GSA subscription deactivation date',
          value: telematicsData?.assetTelematicsSubscriptions?.[0]
            ?.subscriptionDeactivationDate
            ? moment(
                telematicsData?.assetTelematicsSubscriptions?.[0]
                  ?.subscriptionDeactivationDate,
              ).format('MM/DD/YYYY')
            : null,
        },
        {
          label: 'Geotab device serial number',
          value: telematicsData?.assetTelematicsMetadata?.deviceSerialNumber,
        },
        {
          label: "Geotab assigned 'gsafleet' DB device ID",
          value: telematicsData?.assetTelematicsMetadata?.deviceId,
        },
        {
          label: 'Telematics odometer',
          value: telematicsData?.assetTelematics?.odometer
            ? `${telematicsData?.assetTelematics?.odometer?.toLocaleString()} mi/km`
            : null,
        },
        {
          label: 'Date of last reported telematics odometer',
          value: telematicsData?.assetTelematics?.odometerDate
            ? moment(telematicsData?.assetTelematics?.odometerDate).format(
                'MM/DD/YYYY . h:mm a',
              )
            : null,
        },
        {
          label: 'Telematics exemption status',
          value: telematicsData?.isTelematicsExempt ? 'Yes' : 'No',
        },
      ];
      setSubscriptionData(subscriptionDataMapping);
    }
  }, [telematicsData]);

  if (telematicsDataLoading) return <Spinner className="padding-y-9" />;

  if (telematicsDataError) {
    return (
      <Alert type="error">
        An error occurred while fetching telematics data
      </Alert>
    );
  }

  return (
    <div className="grid-row grid-gap">
      <div className="grid-col-7">
        <h3 className="title-s-caps text-primary">Subscription details</h3>
        <div className="bg-gray-3 padding-3">
          <DetailsTable bordered wrapText data={formatData(subscriptionData)} />
        </div>
      </div>
    </div>
  );
};

export default Telematics;
