import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { DetailsTable, Button } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import InventoryStatusTag from '../../inventory-status-tag/index';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import { VehicleOwnership } from '../helpers/constants';
import {
  canViewAOVehicles,
  canViewGFVehicles,
} from '../../../utilities/authorization';

const LpVehicleInfo = () => {
  const ability = useAppAbility();
  const shouldSeeVehicleLink =
    canViewGFVehicles(ability) || canViewAOVehicles(ability);

  const { tagDetails } = useLicensePlateDetails();
  const getVinLink = () => {
    return shouldSeeVehicleLink ? (
      <Link
        to={`/vehicles/${encodeURIComponent(_.get(tagDetails, 'vehicle.id'))}`}
      >
        {_.get(tagDetails, 'vehicle.id')}
      </Link>
    ) : (
      _.get(tagDetails, 'vehicle.id')
    );
  };

  const formatModalCodeDescription = (details) => {
    return _.get(details, 'vehicle.vehicleModel.modelDescription')
      ? _.get(details, 'vehicle.vehicleModel.modelDescription')
      : _.get(details, 'vehicle.modelCode')
      ? _.get(details, 'vehicle.modelCode')
      : '-';
  };

  if (!_.get(tagDetails, 'vehicle')) {
    return null;
  }
  // hide if the tag exp date is not equal to the attached vehicle tag exp date
  // used for unattached tags after swap
  if (
    _.get(tagDetails, 'expirationDate') !==
    _.get(tagDetails, 'vehicle.tag.expirationDate')
  ) {
    return null;
  }
  return (
    <div className="grid-col-6">
      <h2 className="title-s-caps text-primary margin-bottom-1">VEHICLE</h2>
      <div className="bg-gray-3 padding-3">
        <DetailsTable
          bordered
          data={[
            [
              'VIN',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.id') ? getVinLink() : '—'}
              </span>,
            ],
            [
              'Vehicle status',
              <span className="float-right align-right status-info">
                {_.get(tagDetails, 'vehicle.itemInventoryStatusCode') ? (
                  <InventoryStatusTag
                    statusCode={_.get(
                      tagDetails,
                      'vehicle.itemInventoryStatusCode',
                    )}
                  />
                ) : (
                  '—'
                )}
              </span>,
            ],
            [
              'Year',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.modelYear')
                  ? _.get(tagDetails, 'vehicle.modelYear')
                  : '—'}
              </span>,
            ],
            [
              'Make',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.vehicleMake.makeName')
                  ? _.get(tagDetails, 'vehicle.vehicleMake.makeName')
                  : '—'}
              </span>,
            ],
            [
              'Model',
              <span className="float-right align-right">
                {formatModalCodeDescription(tagDetails)}
              </span>,
            ],
            [
              'Color',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.makeColor.name')
                  ? _.get(tagDetails, 'vehicle.makeColor.name')
                  : '—'}
              </span>,
            ],
            [
              'Fuel type',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.fuel.description')
                  ? _.get(tagDetails, 'vehicle.fuel.description')
                  : '—'}
              </span>,
            ],
            [
              'FAST reportable',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.fastReportable') ? 'Yes' : 'No'}
              </span>,
            ],
            [
              'Ownership',
              <span className="float-right align-right">
                {_.get(tagDetails, 'vehicle.ownershipTypeCode')
                  ? VehicleOwnership[
                      _.get(tagDetails, 'vehicle.ownershipTypeCode')
                    ]
                  : '—'}
              </span>,
            ],
          ]}
        />

        {_.get(tagDetails, 'tagActivityTypeCode') === 'ATTD' && (
          <div className="padding-top-3">
            {shouldSeeVehicleLink && (
              <Link
                to={`/vehicles/${encodeURIComponent(
                  _.get(tagDetails, 'vehicle.id'),
                )}`}
              >
                <Button
                  type="button"
                  variant="outline"
                  className="bg-white"
                  data-testid="view-vehicle"
                  label="View Vehicle"
                />
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LpVehicleInfo;
