import { z } from 'zod';

export const terminateSchema = z.object({
  terminationDate: z
    .string()
    .nonempty({ message: 'Termination date is required' })
    .nullish(),
  endOdometer: z
    .string()
    .nonempty({ message: 'End mileage is required' })
    .nullish()
    .refine(
      (val) => {
        const sanitizedEndOdometer = val.replace(/\D/g, '');
        return sanitizedEndOdometer;
      },
      {
        message: 'Invalid format for end mileage',
        path: ['endOdometer'],
      },
    ),
  comment: z.string().nullish(),
});
