import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Breadcrumbs, DetailsTable, Button } from '@gsa/afp-component-library';
import { usCountryCode } from 'utilities/consts';
import { useCustomerAccounts } from '../providers/customer-account-provider';
import CustomerAccountHeader from '../widgets/customer-account-header';
import { emdash, SectionBlock } from '../../common';
import ContactInfoDisplay from '../widgets/contact-info-display';
import useUser from '../../../utilities/use-user';
import formatPhoneNumber from '../../../utilities/phoneNumberFormatter';

const CustomerAccountView = () => {
  const { customerAccountSelected, getCustomerAccount, optionsData } =
    useCustomerAccounts();

  const { id } = useParams();
  const { isGsaEmployee } = useUser();
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');

  const history = useHistory();

  useEffect(() => {
    const customerId = Number.parseInt(id, 10);
    if (id) {
      getCustomerAccount({ variables: { customerId } });
    }
  }, [id]);

  const setStateNameFromData = (usStates, customerAccount) => {
    const state = usStates.find(
      (s) => s.value === customerAccount?.usStateId?.toString(),
    );
    return state ? state.label : customerAccount?.usStateId;
  };

  const setCountryNameFromData = (countries, customerAccount) => {
    if (customerAccount?.countryId?.toString() === usCountryCode) {
      return 'USA';
    }
    if (customerAccount?.countryId?.toString() !== usCountryCode) {
      const country = countries.find(
        (c) => c.value === customerAccount?.countryId?.toString(),
      );
      return country ? country?.label : customerAccount?.countryId;
    }
    return '';
  };

  useEffect(() => {
    if (
      optionsData?.countries &&
      optionsData?.usStates &&
      customerAccountSelected
    ) {
      setStateName(
        setStateNameFromData(optionsData.usStates, customerAccountSelected),
      );
      setCountryName(
        setCountryNameFromData(optionsData.countries, customerAccountSelected),
      );
    }
  }, [customerAccountSelected, optionsData]);

  const trail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <Link to="/customer-accounts">Customer Accounts</Link>,
  ];

  const [title, setTitle] = useState('');
  const [primaryPointOfContact, setPrimaryPointOfContact] = useState('');
  const [additionalPointsOfContact, setAdditionalPointsOfContact] = useState(
    [],
  );

  useEffect(() => {
    setTitle(`${customerAccountSelected?.accountName}`);
    if (customerAccountSelected?.primaryContactAssociation?.pointOfContact) {
      setPrimaryPointOfContact(
        customerAccountSelected?.primaryContactAssociation?.pointOfContact,
      );
    }
    if (customerAccountSelected?.allContactAssociations) {
      setAdditionalPointsOfContact(
        customerAccountSelected?.allContactAssociations.filter(
          (contact) => contact?.priority !== 1,
        ),
      );
    }
  }, [customerAccountSelected]);

  const getAccountDetails = () => {
    const data = [
      ['Agency', customerAccountSelected?.customerAgency?.prefixedName || ''],
      ['Bureau', customerAccountSelected?.customerBureau?.prefixedName || ''],
      [
        'Office',
        customerAccountSelected?.customerPhysicalOffice?.prefixedName || '',
      ],
      ['BOAC', customerAccountSelected?.boac || ''],
      ['Customer account number', customerAccountSelected?.customerId || ''],
      [
        'Legacy customer number',
        customerAccountSelected?.legacyCustomerNumber || '',
      ],
      [
        'Number of vehicles',
        customerAccountSelected?.vehicleCount === 0 ? (
          customerAccountSelected?.vehicleCount
        ) : (
          <Button
            variant="unstyled"
            label={customerAccountSelected?.vehicleCount}
            onClick={() =>
              history.push({
                pathname: '/vehicles',
                state: {
                  legacyCustomerNumber:
                    customerAccountSelected?.legacyCustomerNumber,
                },
              })
            }
          />
        ),
      ],
    ];

    if (isGsaEmployee()) {
      data.push([
        'Internal account notes',
        customerAccountSelected?.accountNotes || '',
      ]);
    }
    return data;
  };

  const getOfficeAddress = () => {
    return [
      [
        'Office location address',
        <div>
          <div>{customerAccountSelected?.address1}</div>
          {customerAccountSelected?.address2 && (
            <div>{customerAccountSelected?.address2}</div>
          )}
          {customerAccountSelected?.usStateId && (
            <div>
              {`${customerAccountSelected?.city}, ${stateName} ${
                customerAccountSelected?.postalCode || ''
              }`}
            </div>
          )}
          {customerAccountSelected?.internationalStateName && (
            <>
              <div>{customerAccountSelected?.city || ''}</div>
              <div>
                {`${customerAccountSelected?.internationalStateName} ${
                  customerAccountSelected?.postalCode || ''
                }`}
              </div>
            </>
          )}
          <div>{countryName}</div>
        </div>,
      ],
    ];
  };

  const getPrimaryContactInfo = () => {
    return [
      [
        'Primary point of contact',
        <ContactInfoDisplay contact={primaryPointOfContact} />,
      ],
    ];
  };

  const customerTypeMap = {
    D: 'DESC',
    G: 'GORP',
    W: 'WEX',
  };

  const getAdditionalContactInfo = (contact) => {
    return [
      ['Additional point of contact', <ContactInfoDisplay contact={contact} />],
    ];
  };

  const getMgmtCenterInfo = () => {
    const items = [
      ['Zone', customerAccountSelected?.zoneId || ''],
      [
        'FMC',
        <div>
          <div>{customerAccountSelected?.managementCenter?.name || emdash}</div>
          {customerAccountSelected?.managementCenter?.city &&
            customerAccountSelected?.managementCenter?.state && (
              <div>{`${customerAccountSelected?.managementCenter.city}, ${customerAccountSelected?.managementCenter.state}`}</div>
            )}
        </div>,
      ],
      [
        'FSR',
        <div>
          <div className="text-bold">
            {`${customerAccountSelected?.fsrUser?.firstName || ''} ${
              customerAccountSelected?.fsrUser?.lastName || ''
            }`}
          </div>
          <div>{customerAccountSelected?.fsrUser?.email || emdash}</div>
          <div>
            {customerAccountSelected?.fsrUser?.phoneNumber
              ? formatPhoneNumber(customerAccountSelected?.fsrUser?.phoneNumber)
              : ''}
          </div>
        </div>,
      ],
      [
        'Receive PM reminders',
        `${customerAccountSelected?.maintenanceReminder === 1 ? 'Yes' : 'No'}`,
      ],
      [
        'Customer agency indicator',
        customerAccountSelected?.agencySubGroup || '',
      ],
      [
        'Mileage capture type',
        customerTypeMap[customerAccountSelected?.customerType] || '',
      ],
    ];

    if (isGsaEmployee()) {
      items.push([
        'Transportation service fee applied',
        customerAccountSelected?.hasTransportationFee ? 'Yes' : 'No',
      ]);
    }
    return items;
  };

  return (
    <div className="customer-account-view">
      <Breadcrumbs trail={trail} current={title} />
      {customerAccountSelected && (
        <>
          <div className="margin-bottom-3">
            <CustomerAccountHeader title={title} headingText="" />
          </div>
          <div className="grid-row grid-gap">
            <div className="grid-col-6">
              <SectionBlock title="Account details" basic>
                <DetailsTable bordered wrapText data={getAccountDetails()} />
              </SectionBlock>
            </div>
            <div className="grid-col-6">
              <SectionBlock title="GSA Fleet Management Information" basic>
                <DetailsTable bordered wrapText data={getMgmtCenterInfo()} />
              </SectionBlock>
            </div>
          </div>
          <div className="grid-row grid-gap">
            <div className="grid-col-12">
              <SectionBlock title="Address and Points of Contact" basic>
                <div className="grid-row">
                  <div className="grid-col-6">
                    <DetailsTable
                      wrapText
                      data={getOfficeAddress()}
                      className="text-top"
                    />
                  </div>
                  <div className="grid-col-6">
                    <DetailsTable
                      bordered
                      wrapText
                      data={getPrimaryContactInfo()}
                    />
                    {additionalPointsOfContact?.map((contact) => (
                      <DetailsTable
                        key={`contact_detail_${contact?.pointOfContact?.contactId}`}
                        bordered
                        wrapText
                        data={getAdditionalContactInfo(contact?.pointOfContact)}
                      />
                    ))}
                  </div>
                </div>
              </SectionBlock>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerAccountView;
