import { z } from 'zod';
import { validEmail } from '../../../../utilities/validation';

export const reassignVehicleSchema = z
  .object({
    fsrEmailSelection: z.string(),
    zone: z.string().optional(),
    fmc: z.string().optional(),
    fsrEmailByZone: z.string().optional(),
    fsrEmail: z.string().optional(),
  })
  .superRefine(
    ({ fsrEmail, fsrEmailSelection, fsrEmailByZone, zone, fmc }, ctx) => {
      if (fsrEmailSelection === '1') {
        if (!fsrEmail) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email is required.',
            path: ['fsrEmail'],
            fatal: true,
          });
        }
        if (fsrEmail && !validEmail(fsrEmail)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Enter valid Email.',
            path: ['fsrEmail'],
            fatal: true,
          });
        }
      } else {
        if (!zone) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Zone is required.',
            path: ['zone'],
            fatal: true,
          });
        }
        if (!fmc) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'FMC is required.',
            path: ['fmc'],
            fatal: true,
          });
        }
        if (!fsrEmailByZone) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'FSR Email is required.',
            path: ['fsrEmailByZone'],
            fatal: true,
          });
        }
      }
    },
  );
