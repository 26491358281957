/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { TextInput } from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';

// Create an editable cell renderer
const EditableCell = ({
  prefix,
  suffix,
  inputType,
  numberFormat,
  characterLimit,
  defaultValue,
  placeholder,
  row: { index, original },
  column: { id },
  updateCellData, // This is a custom function to supply to our table instance
  ...restProps
}) => {
  // need to keep and update the state of the cell normally
  const [cellValue, setCellValue] = useState({
    value: defaultValue,
    isDirty: false,
  });
  const [cellValidationMsg, setCellValidationMsg] = useState('');
  const [inputClass, setInputClass] = useState('');

  const handleChange = (e) => {
    setCellValue({
      value: e.target.value,
      isDirty: !!e.target.value,
    });
  };

  const onNumberChange = (e) => {
    let value = e.formattedValue;
    // Check if the input starts with the given prefix
    if (inputType === 'number' && prefix && value.startsWith(prefix)) {
      value = parseFloat(value.slice(prefix.length));
    }

    // Check if the input ends with the given suffix
    if (inputType === 'number' && suffix && value.endsWith(suffix)) {
      value = parseFloat(value.slice(0, -suffix.length));
    }

    setCellValue({
      value,
      isDirty: cellValue.value !== e.formattedValue,
    });
  };

  // only update the external data when the input is blurred
  const onBlur = () => {
    const validatedRlt = updateCellData(index, id, cellValue, original);
    setCellValidationMsg(validatedRlt?.errorMessage);
    setInputClass(validatedRlt?.inputClass);
  };

  const getComponent = (type) => {
    switch (type) {
      case 'number':
        return (
          <div className={inputClass}>
            <NumberFormat
              className="usa-input"
              onFocus={() => setInputClass()}
              prefix={prefix ? `${prefix} ` : ''}
              suffix={suffix ? ` ${suffix}` : ''}
              format={numberFormat}
              placeholder={placeholder}
              name={`${id}-${index}`}
              type="text"
              value={cellValue.value}
              onValueChange={(val) => {
                onNumberChange(val);
              }}
              onBlur={(val) => {
                onBlur(val);
              }}
              data-testid={`editable-cell-${id}-${index}`}
              id={`editable-cell-${id}-${index}`}
              containerClassName="margin-0"
              aria-invalid={
                original?.errors && original?.errors[id] ? 'true' : 'false'
              }
              {...restProps}
            />
          </div>
        );
      default:
        return (
          <TextInput
            suffix={suffix ? ` ${suffix}` : ''}
            placeholder={placeholder}
            characterLimit={characterLimit}
            name={`${id}-${index}`}
            type={inputType}
            value={cellValue.value}
            onChange={handleChange}
            onBlur={onBlur}
            data-testid={`editable-cell-${id}-${index}`}
            id={`editable-cell-${id}-${index}`}
            containerClassName="margin-0"
            aria-invalid={
              original?.errors && original?.errors[id] ? 'true' : 'false'
            }
            {...restProps}
          />
        );
    }
  };

  return (
    <div className="editable-cell">
      {original?.errors && original?.errors[id] && !cellValidationMsg ? (
        <span
          data-testid={`editable-cell-${id}-${index}-error`}
          className="usa-error-message"
        >
          {original?.errors[id].message}
        </span>
      ) : null}
      {!original?.errors && cellValidationMsg ? (
        <span
          data-testid={`editable-cell-${id}-${index}-error`}
          className="usa-error-message"
        >
          {cellValidationMsg}
        </span>
      ) : null}

      {getComponent(inputType)}
    </div>
  );
};

export default EditableCell;
