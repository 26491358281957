import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useMileageExpress } from './mileage-express-provider';

const MileageExpressActions = () => {
  const { saveAllMileageExpresss, addingMileageForGFVehicle } =
    useMileageExpress();
  return (
    <div className="display-flex flex-justify-end">
      <Button
        data-testid="save-all-mileageExpress-submit-btn"
        onClick={() => {
          saveAllMileageExpresss();
        }}
        label="Save all"
        disabled={addingMileageForGFVehicle}
      />
    </div>
  );
};

export default MileageExpressActions;
