import React, { useMemo } from 'react';
import { FilterTableFrame, Alert } from '@gsa/afp-component-library';
import MileageExpressActions from './mileage-express-actions';
import MileageExpressSidebar from './mileage-express-sidebar';
import MileageExpressListingTable from './mileage-express-listing-table';
import { useMileageExpress } from './mileage-express-provider';

const MileageExpressListing = () => {
  const { alertMessage } = useMileageExpress();
  const { header, message, type, context } = alertMessage;

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        MileageExpressActions,
        MileageExpressSidebar,
        MileageExpressListingTable,
      ),
    [],
  );

  return (
    <>
      {context === 'endMileage' && (
        <Alert type={type} heading={header || null} className="margin-bottom-4">
          {message}
        </Alert>
      )}
      <FTF filterToggle />
    </>
  );
};

export default MileageExpressListing;
