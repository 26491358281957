import { isFeatureEnabled } from 'utilities/feature-toggle';
import { cddMapping, cddMappingGF } from './cdd/mapping';
import useCDDDataProcessor from './cdd/use-cdd-data-processor';
import {
  mileageLeaseMapping,
  mileageMapping,
  mileageLeaseTxtMapping,
  mileageLeaseTxtValuePositions,
} from './mileage/mapping';
import useMileageDataProcessor from './mileage/use-mileage-data-processor';
import { expenseMapping } from './expense/mapping';
import useExpenseDataProcessor from './expense/use-expense-data-processor';
import { OWNERSHIP_TYPES } from '../../export-vehicle-reports/config/helpers';
import useGfMileageDataProcessor from './mileage/use-gf-mileage-data-processor';
import useGfMileageTxtDataProcessor from './mileage/use-gf-mileage-txt-data-processor';
import useDLAExpenseDataProcessor from './dla-expense/use-dla-expense-data-processor';
import { dlaExpenseMapping } from './dla-expense/dla-mapping';

const isGFMileageEnabled = isFeatureEnabled('gf-mileage');

// eslint-disable-next-line import/prefer-default-export
export const reportsConfig = {
  DEFAULT: {
    value: 'DEFAULT',
    label: '-Select-',
    maxFileSize: 30,
    fileTypes: ['xlsx'],
  },
  CDD: {
    value: 'CDD',
    label: 'Customer driven data (CDD)',
    fileTypes: ['xlsx'],
    sheets: ['Instructions', 'Vehicles', 'Reference'],
    dataSheet: 'Vehicles',
    ownershipType: [OWNERSHIP_TYPES.GF.value, OWNERSHIP_TYPES.AO.value],
    maxFileSize: 30,
    batchSize: 500,
    mapping: cddMapping,
    gfMapping: cddMappingGF,
    processorHook: useCDDDataProcessor,
    uniqueIdenfier: 'VIN',
    errorFileName: 'CDD_Errors',
    errorSheetName: 'CDD Upload Errors',
    errorSheetHeaders: ['VIN', 'Status', 'Errors'],
    errorSheetColumnWidths: [{ wch: 25 }, { wch: 9 }, { wch: 125 }],
  },
  MILEAGE_LEASE: {
    value: 'MILEAGE_LEASE',
    label: 'Vehicle mileage data',
    fileTypes: ['xlsx', 'txt'],
    sheets: [
      'Instructions',
      'Mileage Upload Template',
    ],
    dataSheet: 'Mileage Upload Template',
    ownershipType: isGFMileageEnabled ? [OWNERSHIP_TYPES.GF.value] : [],
    maxFileSize: 30,
    batchSize: 500,
    mapping: mileageLeaseMapping,
    processorHook: useGfMileageDataProcessor,
    uniqueIdenfier: 'VIN',
    errorFileName: 'mileage-import-errors',
    errorSheetName: 'Mileage Upload Errors',
    errorSheetHeaders: ['Tag', 'VIN', 'Status', 'Errors'],
    errorSheetColumnWidths: [{ wch: 25 },  {wch: 25 }, { wch: 9 }, { wch: 125 }],
  },
  MILEAGE_LEASE_TXT: {
    value: 'MILEAGE_LEASE',
    label: 'Vehicle mileage data',
    fileTypes: ['xlsx', 'txt'],
    hidden: true, // to hide it from the dropdown
    ownershipType: isGFMileageEnabled ? [OWNERSHIP_TYPES.GF.value] : [],
    maxFileSize: 30,
    batchSize: 500,
    mapping: mileageLeaseTxtMapping,
    valuesPosition: mileageLeaseTxtValuePositions,
    processorHook: useGfMileageTxtDataProcessor,
    uniqueIdenfier: 'TAG',
    errorFileName: 'mileage-import-errors',
    errorSheetName: 'Mileage Upload Errors',
    errorSheetHeaders: ['Tag', 'VIN', 'Status', 'Errors'],
    errorSheetColumnWidths: [{ wch: 25 },  {wch: 25 }, { wch: 9 }, { wch: 125 }],
  },
  MILEAGE: {
    value: 'MILEAGE',
    label: 'Vehicle mileage data',
    fileTypes: ['xlsx'],
    sheets: ['Mileage Upload Template', 'Instructions'],
    dataSheet: 'Mileage Upload Template',
    ownershipType: [OWNERSHIP_TYPES.AO.value],
    maxFileSize: 30,
    batchSize: 500,
    mapping: mileageMapping,
    processorHook: useMileageDataProcessor,
    uniqueIdenfier: 'VIN',
    errorFileName: 'mileage-import-errors',
    errorSheetName: 'Mileage Upload Errors',
    errorSheetHeaders: ['VIN', 'Status', 'Errors'],
    errorSheetColumnWidths: [{ wch: 25 }, { wch: 9 }, { wch: 125 }],
  },
  EXPENSE: {
    value: 'EXPENSE',
    label: 'Vehicle expense data',
    fileTypes: ['xlsx'],
    sheets: ['Expense Upload Template', 'Instructions'],
    dataSheet: 'Expense Upload Template',
    ownershipType: [OWNERSHIP_TYPES.AO.value],
    maxFileSize: 30,
    batchSize: 500,
    mapping: expenseMapping,
    processorHook: useExpenseDataProcessor,
    uniqueIdenfier: 'VIN',
    errorFileName: 'expense-import-errors',
    errorSheetName: 'Expense Upload Errors',
    errorSheetHeaders: ['VIN', 'Status', 'Errors'],
    errorSheetColumnWidths: [{ wch: 25 }, { wch: 9 }, { wch: 125 }],
  },
  DLA: {
    value: 'DLA',
    label: 'DLA - Fuel data',
    fileTypes: ['xlsx'],
    sheets: ['DLA-EBS to GSA'],
    dataSheet: 'DLA Expense Upload Template',
    ownershipType: [OWNERSHIP_TYPES.GF.value],
    maxFileSize: 30,
    batchSize: 500,
    mapping: dlaExpenseMapping,
    processorHook: useDLAExpenseDataProcessor,
    uniqueIdenfier: 'tagNumber',
    errorFileName: 'dla-expense-import-errors',
    errorSheetName: 'DLA Expense Upload Errors',
    errorSheetHeaders: ['Vehicle ID', 'Status', 'Errors'],
    errorSheetColumnWidths: [{ wch: 25 }, { wch: 9 }, { wch: 125 }],
  },
};
