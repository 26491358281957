/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import {
  Alert,
  Button,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import useUser from 'utilities/use-user';
import { usCountryCode } from 'utilities/consts';
import { formSectionAccountDetails } from './form-section-account-details';
import { officeLocationSection } from './form-section-office-location';
import { fleetMgmtInfoSection } from './form-section-fmc-info';
import { useCustomerAccounts } from '../providers/customer-account-provider';
import PocModal from './poc-modal';
import ContactInfoCard from './contact-info-card';
import '../customer-account.css';
import { customerAccountFormSchema } from './customer-account-form-schema';
import { contactFormZObject } from './poc-form';

const CustomerAccountForm = ({
  countries,
  usStates,
  fmcsWithZoneAndRegion,
  intCallCodes,
  customerAccount,
}) => {
  const {
    createCustomerAccount,
    updateCustomerAccount,
    dispatchAction,
    alertMessage,
    customerAccountForm,
  } = useCustomerAccounts();
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  const [showPocModal, setShowPocModal] = useState(false);
  const [contactAssociations, setContactAssociations] = useState([]);
  const [formContext, setFormContext] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [pocFormMode, setPocFormMode] = useState('');

  useEffect(() => {
    if (
      (pocFormMode === 'edit' || pocFormMode === 'delete') &&
      selectedContact
    ) {
      setShowPocModal(true);
    }
  }, [pocFormMode, selectedContact]);

  useEffect(() => {
    if (alertMessage?.message && alertMessage?.location === 'poc_section') {
      formContext.setValue('pocMessages', alertMessage);
    } else {
      formContext?.setValue('pocMessages', {});
    }
  }, [alertMessage]);

  useEffect(() => {
    if (customerAccount?.allContactAssociations) {
      const cas = customerAccount.allContactAssociations?.map((ca) => ({
        entityType: 'CUSTOMER',
        entityId: ca.entityId,
        contactId: ca.contactId,
        priority: ca.priority,
        ...ca.pointOfContact,
      }));
      setContactAssociations(cas);
    }
  }, [customerAccount]);

  const getState = () => {
    return customerAccount?.countryId === 233 ||
      customerAccount?.countryId === usCountryCode
      ? customerAccount?.usStateId?.toString()
      : customerAccount?.internationalStateName;
  };

  const getFmcData = () => {
    const fmc = fmcsWithZoneAndRegion.find(
      (fm) =>
        fm.centerId === currentUser.internalAttrs.managementCenter.id &&
        fm.zoneId === currentUser.internalAttrs.zone.id,
    );
    return fmc
      ? {
          fmcId: `${fmc.zoneId}:${fmc.centerId}:${fmc.regionId}`,
          zoneId: fmc.zoneId.toString(),
          regionId: fmc.regionId.toString(),
        }
      : {};
  };

  if (
    currentUser?.internalAttrs?.managementCenter?.id &&
    currentUser?.internalAttrs?.zone?.id
  ) {
    dispatchAction('SET_CUSTOMER_ACCOUNT_FORM', {
      ...customerAccountForm,
      ...getFmcData(),
    });
  }

  const mode = customerAccount ? 'update' : 'create';

  const { isRole } = useUser();

  const internalGsaWithUpdateRight =
    isRole('FSR') ||
    isRole('FMCManager') ||
    isRole('SiteAdmin') ||
    isRole('MCCSpecialist') ||
    isRole('FleetSvcRep') ||
    isRole('AMCSpecialist') ||
    isRole('OBAnalyst');

  const extneralCustomerWithupdateRight =
    isRole('CustomerAdmin') || isRole('CustFltMan');

  const internalExternalWithUpdateRight =
    internalGsaWithUpdateRight || extneralCustomerWithupdateRight;

  const intenalGsaReadOnlyMode =
    mode === 'update' && !internalGsaWithUpdateRight;

  const fmcReadOnlyMode = mode === 'update' && !isRole('FMCManager');

  const bothReadOnlyMode =
    mode === 'update' && !internalExternalWithUpdateRight;

  const accountDetailsSection = formSectionAccountDetails(
    mode,
    intenalGsaReadOnlyMode,
  );
  const locationSection = officeLocationSection(
    countries,
    usStates,
    bothReadOnlyMode,
  );
  const fmcSection = fleetMgmtInfoSection(
    mode,
    fmcsWithZoneAndRegion,
    intenalGsaReadOnlyMode,
    fmcReadOnlyMode,
  );

  const getButtonControls = () => {
    return {
      submit: {
        label: customerAccount?.customerId
          ? 'Update customer account'
          : 'Create customer account',
        leftIcon: { name: 'check' },
      },
      cancel: {
        label: 'Cancel',
        onClick: () => {
          history.push('/customer-accounts');
        },
      },
    };
  };

  const formContent = {
    buttonControls: getButtonControls(),
    sections: [
      {
        className: 'ca-form-section',
        header: (
          <span className="title-s-caps text-primary">ACCOUNT DETAILS</span>
        ),
        sections: [{ ...accountDetailsSection }],
      },
      {
        header: (
          <span className="title-s-caps text-primary">
            OFFICE LOCATION ADDRESS
          </span>
        ),
        sections: [{ ...locationSection }],
      },
      {
        header: (
          <span className="title-s-caps text-primary">POINTS OF CONTACT</span>
        ),
        sections: [
          {
            fields: [
              {
                component: (_, getFormContext) => {
                  const {
                    watch,
                    setValue,
                    formState: { errors },
                    clearErrors,
                  } = getFormContext();
                  const ca = watch('contactAssociations');
                  const pocMessages = watch('pocMessages');

                  const primaryContact = ca?.find(
                    (contact) => contact.priority === 1,
                  );
                  const additionalContacts = ca?.filter(
                    (contact) => contact.priority !== 1,
                  );

                  useEffect(() => {
                    clearErrors('contactAssociations');
                  }, [ca]);

                  const handleContactCardAction = (action, contact) => {
                    switch (action) {
                      case 'REMOVE_CONTACT':
                        setPocFormMode('delete');
                        setSelectedContact(contact);
                        break;
                      case 'CONFIRM_REMOVE_CONTACT':
                        // Remove the contact from the state
                        // eslint-disable-next-line no-case-declarations
                        const modContactAssociations = ca.filter(
                          (c) => c !== contact,
                        );
                        setContactAssociations(modContactAssociations);
                        setValue('contactAssociations', modContactAssociations);
                        dispatchAction('SET_ALERT_MESSAGE', {
                          type: 'success',
                          message: `You have removed ${contact.pocFirstName} ${contact.pocLastName} as a point of contact. Changes will be persisted on submitting customer account form.`,
                          location: 'poc_section',
                        });
                        break;
                      case 'EDIT_CONTACT':
                        // Set the contact to be edited in the state and open the modal
                        setPocFormMode('edit');
                        setSelectedContact(contact);
                        break;
                      case 'MARK_CONTACT_AS_PRIMARY':
                        // Mark the contact as primary in the state
                        // eslint-disable-next-line no-case-declarations
                        const modified = ca.map((c) =>
                          c.contactId === contact.contactId
                            ? { ...c, priority: 1 }
                            : { ...c, priority: 0 },
                        );
                        setContactAssociations(modified);
                        setValue('contactAssociations', modified);
                        dispatchAction('SET_ALERT_MESSAGE', {
                          type: 'success',
                          message: `You have made ${contact.pocFirstName} ${contact.pocLastName} the primary point of contact. Changes will be persisted on submitting customer account form.`,
                          location: 'poc_section',
                        });
                        break;
                      default:
                        break;
                    }
                  };

                  return (
                    <div className="">
                      {pocMessages?.message ? (
                        <div
                          className="margin-top-2"
                          data-testid="customer-account-alert"
                        >
                          <Alert
                            type={pocMessages?.type}
                            heading={null}
                            onClose={() => {
                              dispatchAction('SET_ALERT_MESSAGE', {});
                            }}
                          >
                            {pocMessages?.message}
                          </Alert>
                        </div>
                      ) : null}
                      <span className="usa-hint">
                        Enter customer points of contact (POCs) here.{' '}
                        <b>
                          (At least one is required <RequiredFieldIndicator />)
                        </b>{' '}
                        If the user is not already registered in GSAFleet.gov,
                        use invitation manager to get them started.
                      </span>
                      {errors.contactAssociations && (
                        <span className="usa-error-message">
                          {errors?.contactAssociations?.message?.toString()}
                        </span>
                      )}
                      {primaryContact && (
                        <div className="grid-row margin-top-2">
                          <span className="title-m">
                            Primary point of contact
                          </span>
                          <div className="grid-col-12">
                            <ContactInfoCard
                              contact={primaryContact}
                              displayContext="CustomerForm"
                              handleContactCardAction={handleContactCardAction}
                            />
                          </div>
                        </div>
                      )}
                      {additionalContacts && additionalContacts.length > 0 && (
                        <div className="grid-row margin-top-2">
                          <span className="title-m">
                            Additional points of contact
                          </span>
                          {additionalContacts.map((contact) => {
                            return (
                              <div className="grid-col-12">
                                <ContactInfoCard
                                  contact={contact}
                                  displayContext="CustomerForm"
                                  handleContactCardAction={
                                    handleContactCardAction
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {ca?.length <= 2 && (
                        <div className="margin-top-2">
                          <Button
                            leftIcon={{ name: 'add' }}
                            variant="outline"
                            className="margin-right-2"
                            data-testid="poc-button"
                            label="Add point of contact"
                            onClick={() => {
                              setPocFormMode('add');
                              setShowPocModal(true);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
      {
        header: (
          <span className="title-s-caps text-primary ">
            GSA FLEET MANAGEMENT INFORMATION
          </span>
        ),
        sections: [{ ...fmcSection }],
      },
    ],
  };

  const onSubmit = (data) => {
    const cas = contactAssociations.map((ca) => ({
      entityType: ca.entityType,
      entityId: ca.entityId,
      contactId: ca.contactId,
      priority: ca.priority,
    }));
    const fmcIdParts = data.fmcId.split(':');
    const { state, ...rest } = data;
    const completeData = {
      ...rest,
      maintenanceReminder: data.maintenanceReminder === 'Y' ? 1 : 0,
      zoneId: Number.parseInt(fmcIdParts[0], 10),
      fmcId: Number.parseInt(fmcIdParts[1], 10),
      regionId: Number.parseInt(fmcIdParts[2], 10),
      usStateId:
        data.countryId === usCountryCode ? Number.parseInt(state, 10) : null,
      internationalStateName: data.countryId !== usCountryCode ? state : null,
      countryId: Number.parseInt(data.countryId, 10),
      contactAssociations: cas,
    };
    if (customerAccount?.customerId) {
      updateCustomerAccount({
        variables: {
          customerAccount: {
            ...completeData,
            customerId: customerAccount.customerId,
          },
        },
      });
    } else {
      createCustomerAccount({ variables: { customerAccount: completeData } });
    }
  };

  const closePocModal = () => {
    setShowPocModal(false);
    setPocFormMode('');
  };

  const saveAndClose = (contact) => {
    const contactAssociation = [...contactAssociations];
    const existingContactIndex = contactAssociation.findIndex(
      (c) => c.contactId === contact.contactId,
    );

    if (existingContactIndex !== -1) {
      // If contact exists, update it
      contactAssociation[existingContactIndex] = {
        ...contact,
        entityType: 'CUSTOMER',
        entityId: customerAccount?.customerId
          ? customerAccount.customerId.toString()
          : '',
        priority: contactAssociation[existingContactIndex].priority,
      };
    } else {
      // If contact does not exist, add it
      const priority = contactAssociation?.length > 0 ? 0 : 1;
      contactAssociation.push({
        ...contact,
        entityType: 'CUSTOMER',
        entityId: customerAccount?.customerId
          ? customerAccount.customerId.toString()
          : '',
        priority,
      });
    }

    formContext.setValue('contactAssociations', contactAssociation);
    setContactAssociations(contactAssociation);
    setShowPocModal(false);
  };

  const deleteAndClose = (contact) => {
    const ca = [...contactAssociations];
    const modContactAssociations = ca.filter(
      (c) => c.contactId !== contact.contactId,
    );
    setContactAssociations(modContactAssociations);
    formContext.setValue('contactAssociations', modContactAssociations);
    setShowPocModal(false);
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'success',
      message: `You have removed ${contact.pocFirstName} ${contact.pocLastName} as a point of contact. Changes will be persisted on submitting customer account form.`,
      location: 'poc_section',
    });
  };

  // eslint-disable-next-line no-unused-vars
  const recursivelyGetErrors = ([key, value]) => {
    if (typeof value === 'object' && !value.type) {
      return Object.entries(value).map(recursivelyGetErrors);
    }

    return (
      <li key={key}>
        {key}: {value?.message}
      </li>
    );
  };

  const onBeforeSubmit = (values) => {
    let contactAssociationError = '';

    const ca = values?.contactAssociations;
    const primaryContact = ca.find((contact) => contact.priority === 1);
    const primaryContactValidation =
      contactFormZObject.safeParse(primaryContact);
    const additionalContacts = ca?.filter((contact) => contact.priority !== 1);

    if (ca.length < 1) {
      contactAssociationError =
        'At least one point of contact is required and one must be marked as primary';
    } else if (ca.length > 3) {
      contactAssociationError = 'No more than 3 points of contact are allowed';
    } else if (!primaryContact) {
      contactAssociationError =
        'At least one point of contact must be marked as primary';
    } else if (!primaryContactValidation.success) {
      contactAssociationError = `Missing required field(s) in primary point of contact.`;
    } else {
      additionalContacts.forEach((contact) => {
        const validationStatus = contactFormZObject.safeParse(contact);
        if (!validationStatus.success) {
          contactAssociationError = `Missing required field(s) in additional point of contact.`;
        }
      });
    }

    if (contactAssociationError) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: contactAssociationError,
        location: 'poc_section',
        context: 'SET_POC_ERROR',
      });
      return { contactAssociationError, ...values };
    }

    return values;
  };

  return (
    <>
      <FormGenerator
        id="customer-account-form"
        data-testid="customer-account-form"
        schema={customerAccountFormSchema}
        defaultValues={customerAccountForm}
        content={formContent}
        onSubmit={onSubmit}
        onSetup={setFormContext}
        onBeforeSubmit={onBeforeSubmit}
        // eslint-disable-next-line no-unused-vars
        onError={(error) => {
          /* const errorValue = Object.entries(error).map(recursivelyGetErrors);
          setMessage({
            type: 'error',
            message: (
              <>
                <p>
                  <strong>Validation Error</strong>
                </p>
                <ul>{errorValue}</ul>
              </>
            ),
          }); */
        }}
        // controls={{ 'boac-field': BoacField, 'fsr-field': FsrField }}
      />
      <PocModal
        closeModal={closePocModal}
        mode={pocFormMode}
        saveAndClose={saveAndClose}
        show={showPocModal}
        intCallCodes={intCallCodes}
        contact={selectedContact}
        deleteAndClose={deleteAndClose}
      />
    </>
  );
};

export default CustomerAccountForm;
