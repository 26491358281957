/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { MultiSelectDropdown } from '@gsa/afp-component-library';
import { Controller, useWatch } from 'react-hook-form';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { useExport } from '../export-provider';

import { GET_CUSTOMER_ACCOUNT_OFFICES_BY_PERMISSION } from '../../../../../services/data-layer';

const getCustomers = (items = []) => {
  if (items?.length === 0) return [];
  return items?.map((item) => ({
    value: item,
    label: item,
  }));
};

// eslint-disable-next-line react/prop-types
export const LegacyCustomerSelection = ({ control, setValue }) => {
  const [legacyCustomers, setLegacyCustomers] = useState([]);
  const [internalError, setInternalError] = useState();

  const [agency, bureau, selectedVehicleOption] = useWatch({
    name: ['agency', 'bureau', 'vehicle_options'],
    control,
  });

  const { selectedReport } = useExport();

  const [legacyCustomersByAgencyQuery] = useLazyQuery(
    GET_CUSTOMER_ACCOUNT_OFFICES_BY_PERMISSION,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        console.log(err.message);
        setInternalError(err);
      },
      onCompleted: (data) => {
        if (data?.getCustomerAccountOfficesByPermission?.length > 0) {
          const legacyCustomerNumberList =
            data?.getCustomerAccountOfficesByPermission
              .map((office) =>
                office?.officeName ? office.officeName.split('-').pop() : null,
              )
              .filter((office) => office)
              .sort();
          setLegacyCustomers(legacyCustomerNumberList);
          setInternalError(undefined);
        }
      },
    },
  );

  useEffect(() => {
    setLegacyCustomers([]);
    setValue('legacyCustomers', []);
    if (selectedVehicleOption === 'GF') {
      if (agency !== 'DEFAULT' && bureau && bureau !== 'DEFAULT') {
        legacyCustomersByAgencyQuery({
          variables: {
            agencyCode: agency !== 'DEFAULT' ? agency : null,
            bureauCode: bureau !== 'DEFAULT' ? bureau : null,
            operation: VMSOperations.VIEW_VEHICLE_GF,
            subject: VMSSubjects.VEHICLE,
          },
        });
      }
    }
  }, [agency, bureau, selectedVehicleOption]);

  useEffect(() => {
    if (
      legacyCustomers?.length > 0 &&
      selectedReport?.reportCriteria?.legacyCustomers
    ) {
      setValue(
        'legacyCustomers',
        selectedReport?.reportCriteria?.legacyCustomers,
      );
    }
  }, [legacyCustomers, selectedReport?.reportCriteria?.legacyCustomers]);

  if (internalError && internalError[0]?.message.includes('Not Authorized!')) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <div className="margin-bottom-3">
      <label className="usa-label margin-bottom-1">
        <span className="text-bold">Legacy customer number</span>
      </label>

      <Controller
        control={control}
        name="legacyCustomers"
        render={({ field: { ref, onChange, value, ...rest } }) => (
          <>
            <MultiSelectDropdown
              label={<b>Legacy customer</b>}
              name="legacyCustomers"
              id="legacyCustomers"
              selectedValues={value}
              disabled={agency === 'DEFAULT' || !bureau || bureau === 'DEFAULT'}
              placeholder={
                agency === 'DEFAULT' || bureau === 'DEFAULT'
                  ? 'Select an Agency and Bureau first'
                  : 'Select Legacy Customer'
              }
              onChange={(items) => {
                onChange(items);
              }}
              options={[...getCustomers(legacyCustomers)]}
              {...rest}
            />
            <span className="usa-sr-only">
              This field is disabled, please select an Agency and Bureau first
            </span>
          </>
        )}
      />
    </div>
  );
};
