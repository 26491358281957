import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { SEARCH_POINTS_OF_CONTACT } from 'services/data-layer/point-of-contact';
import { GET_CUSTOMER_ACCOUNTS } from '../customer-account-gql';
import { useCustomerAccountFilter } from '../providers/filter-provider';
import useUser from '../../../utilities/use-user';
import { useCustomerAccounts } from '../providers/customer-account-provider';

const CustomerAccountSidebar = () => {
  const { currentUser } = useCurrentUser();
  const { optionsData } = useCustomerAccounts();
  const [typeaheadData, setTypeaheadData] = useState([]);

  const { isRole } = useUser();
  const {
    filterStructure,
    setFilters,
    setStructure,
    getCustomerAccountsFilterStruct,
  } = useCustomerAccountFilter();

  useEffect(() => {
    if (optionsData && currentUser) {
      let initialFilters = {
        zone: null,
        fmc: null,
        fsrEmail: null,
        agencyCode: null,
        bureauCode: null,
        pocEmail: null,
      };

      switch (true) {
        case isRole('FMCManager'):
          initialFilters = {
            zone: currentUser?.internalAttrs?.zone?.id,
            fmc: currentUser?.internalAttrs?.managementCenter?.id,
            fsrEmail: null,
            agencyCode: null,
            bureauCode: null,
            pocEmail: null,
          };
          break;
        case isRole('FleetSvcRep'):
          initialFilters = {
            zone: null,
            fmc: null,
            fsrEmail: currentUser.email,
            agencyCode: null,
            bureauCode: null,
            pocEmail: null,
          };
          break;
        default:
          // Default case if no roles match
          break;
      }

      setStructure(
        getCustomerAccountsFilterStruct({
          fmcsWithZoneAndRegion: optionsData.fmcsWithZoneAndRegion,
          zones: optionsData.zones,
          initialFilters,
        }),
      );
    }
  }, [currentUser, optionsData]);

  const clearTypeaheadData = () => {
    setTypeaheadData({
      field: '',
      values: [],
    });
  };

  const [searchPointsOfContact, { data: pocData }] = useLazyQuery(
    SEARCH_POINTS_OF_CONTACT,
    {
      onCompleted: (data) => {
        const pocEmails = Array.from(
          new Set(data.searchPointsOfContact.map((poc) => poc.pocEmailAddress)),
        );
        setTypeaheadData({
          field: '$primaryContactAssociation->pointOfContact.email_address$',
          values: pocEmails,
        });
      },
    },
  );

  const [getCustomerAccountsByEmail, { data: customerAccountsDataByEmail }] =
    useLazyQuery(GET_CUSTOMER_ACCOUNTS, {
      onCompleted: (data) => {
        const fsrEmails = Array.from(
          new Set(
            data.getCustomerAccounts.rows.map(
              (account) => account.fsrUserEmail,
            ),
          ),
        );
        setTypeaheadData({
          field: '$CustomerAccount.fsr_user_email_address$',
          values: fsrEmails,
        });
      },
    });

  const [getCustomerAccountsByNumber, { data: customerAccountsDataByNumber }] =
    useLazyQuery(GET_CUSTOMER_ACCOUNTS, {
      onCompleted: (data) => {
        const legacyCustomerNumbers = Array.from(
          new Set(
            data.getCustomerAccounts.rows.map(
              (account) => account.legacyCustomerNumber,
            ),
          ),
        );
        setTypeaheadData({
          field: '$CustomerAccount.legacy_customer_number$',
          values: legacyCustomerNumbers,
        });
      },
    });

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (
      variables.field ===
      '$primaryContactAssociation->pointOfContact.email_address$'
    ) {
      searchPointsOfContact({
        variables: {
          limit: 10,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$like',
                  key: 'email_address',
                  value: `%${query}%`,
                },
              ],
            },
          ],
        },
      });
    } else if (
      variables.field === '$CustomerAccount.fsr_user_email_address$'
    ) {
      getCustomerAccountsByEmail({
        variables: {
          limit: 25,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$like',
                  key: 'fsrUserEmail',
                  value: `%${query}%`,
                },
              ],
            },
          ],
        },
      });
    } else if (
      variables.field === '$CustomerAccount.legacy_customer_number$'
    ) {
      getCustomerAccountsByNumber({
        variables: {
          limit: 25,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$like',
                  key: 'legacyCustomerNumber',
                  value: `%${query}%`,
                },
              ],
            },
          ],
        },
      });
    }
  };

  const setCustomerAccountFilters = (filters) => {
    setFilters(filters);
  };

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => setCustomerAccountFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
        fetchTypeaheads={handleTypeaheadSearch}
        typeaheadData={typeaheadData}
        handleClearAll={clearTypeaheadData}
      />
    );
  }
  return null;
};
export default CustomerAccountSidebar;
