import { gql } from '@apollo/client';

export const ADD_ASSET_RECALL_COMMENT = gql`
  mutation addAssetRecallComment($assetRecallId: Float!, $comment: String!) {
    addAssetRecallComment(assetRecallId: $assetRecallId, comment: $comment) {
      id
      assetRecallId
      comment
    }
  }
`;

export const UPDATE_REMEDY_DATE_FOR_RECALL = gql`
  mutation updateRemedyDateForRecall($assetRecallId: Float!, $date: String) {
    updateRemedyDateForRecall(assetRecallId: $assetRecallId, date: $date)
  }
`;

export const ASSIGN_VEHICLE = gql`
  mutation assignVehicle($assignmentInput: VehicleAssignmentInput!) {
    assignVehicle(assignmentInput: $assignmentInput) {
      id
      vin
      tagNumber
      ownershipTypeCode
      itemInventoryStatusCode
      agencyCode
      bureauCode
    }
  }
`;

export const TERMINATE_VEHICLE = gql`
  mutation terminateVehicle($terminationInput: VehicleTerminationInput!) {
    terminateVehicle(terminationInput: $terminationInput) {
      vin
    }
  }
`;

export const REASSIGN_FSR = gql`
  mutation reassignFsr($reassignFSRInput: ReassignFSRInput!) {
    reassignFsr(reassignFSRInput: $reassignFSRInput) {
      vin
      id
    }
  }
`;

export const CREATE_OR_UPDATE_STOLEN_VEHICLE = gql`
  mutation CreateOrUpdateVehicleLostStolen(
    $assetLostStolenInput: AssetLostStolenInput!
  ) {
    createOrUpdateVehicleLostStolen(
      assetLostStolenInput: $assetLostStolenInput
    ) {
      assetId
    }
  }
`;

export const GET_STOLEN_VEHICLE_DATA = gql`
  query GetVehicleLostStolen($assetId: String!) {
    getVehicleLostStolen(assetId: $assetId) {
      assetId
      stolenDate
      gsaReportedDate
      dhsReportedDate
      recoveredDate
      dhsCaseNumber
      ncicNumber
      comment
      isLicensePlateStolen
    }
  }
`;

export const SET_LIFYCYCLE_INDICATOR = gql`
  mutation SetLifecycleIndicators(
    $lifecycleIndicatorIDs: [Float!]!
    $assetId: String!
  ) {
    setLifecycleIndicators(
      lifecycleIndicatorIDs: $lifecycleIndicatorIDs
      assetId: $assetId
    ) {
      assetId
      assetLifecycleId
    }
  }
`;

export const DEACTIVE_LIFYCYCLE_INDICATOR = gql`
  mutation DeactivateLifecycleIndicators(
    $lifecycleIndicatorIDs: [Float!]!
    $assetId: String!
  ) {
    deactivateLifecycleIndicators(
      lifecycleIndicatorIDs: $lifecycleIndicatorIDs
      assetId: $assetId
    )
  }
`;
