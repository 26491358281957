/* eslint-disable react/prop-types */
import {
  Button,
  RequiredFieldIndicator,
  Modal,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import FormattedNumberControl from 'utilities/form-generator-controls/formatted-number-control';
import { GET_VEHICLE_EDIT_OPTIONS } from 'services/data-layer';
import { FG_DEFAULT_OPTION_VAL, assetLookupOptions } from 'utilities/consts';
import {
  getVINVerificationStatus,
  displayModel,
} from '../helpers/vehicle-details-helper';

import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
} from 'utilities/authorization';

import { useAppAbility } from '@gsa/afp-shared-ui-utils';

import {
  getOptionsWithDefault,
  boolFieldToYesOrNo,
  yesOrNoOptions,
} from 'utilities/form-generator-controls/helper';
import moment from 'moment';

const schema = z.object({
  makeColorName: z
    .string()
    .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
  fuelCode: z
    .string()
    .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
  tankSize: z.string().nullish(),
  fastReportable: z
    .string()
    .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
  gvwr: z.string().nullish(),
  vamExempt: z.string().nullish(),
  executiveFleet: z.string().nullish(),
  licensePlate: z.string().nullish(),
  stateTag: z.string().nullish(),
  AFINumber: z.string().nullish(),
});
const vemExemptOptions = Object.keys(assetLookupOptions).map((key) => ({
  label: assetLookupOptions[key],
  value: key,
}));

export default function GFVehicleEdit({
  onClose,
  vehicle,
  onSave,
  isGFModalOpen,
}) {
  const ability = useAppAbility();
  useEffect(() => {
    if (vehicle?.vehicleMake?.makeName) {
      getVehicleEditOptions({
        variables: { makeName: vehicle?.vehicleMake?.makeName },
      });
    }
  }, []);

  const [colorOptionsState, setColorOptions] = useState([]);
  const [fuelOptionsState, setFuelOptionsState] = useState([]);

  const [getVehicleEditOptions] = useLazyQuery(GET_VEHICLE_EDIT_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setColorOptions(
        data?.getVehicleMakeModels?.colors.map((option) => ({
          label: option.name,
          value: option.makeColorCode,
        })),
      );
      setFuelOptionsState(
        data?.getFuelNames?.map((option) => ({
          label: option.description,
          value: option.id,
        })),
      );
    },
  });

  const whoCanEdit = (ability, editType) => {
    if (editType === 'admin') {
      return !canUpdateGFVehicleAdmin(ability);
    }
    if (editType === 'fsr') {
      return !canUpdateGFVehicleFSR(ability);
    }
    return true;
  };

  const data = {
    assignmentDate: vehicle?.itemInventoryStatusDateTime
      ? moment.utc(vehicle?.itemInventoryStatusDateTime).format('MM/DD/YYYY')
      : '',
    vinModify: vehicle.id,
    vinVerifiedBy: getVINVerificationStatus(
      vehicle.pre1981,
      vehicle.internationalVin,
      vehicle.unverifiedVin,
      vehicle.nhtsaVerified,
    ),
    vehicleOdometer: vehicle?.newOdometerReading?.odometer
      ? vehicle.newOdometerReading?.odometer
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '',
    modelYear: vehicle?.modelYear ? vehicle?.modelYear.toString() : '',
    makeCode: vehicle?.vehicleMake?.makeName,
    modelCode: displayModel(vehicle),

    vehicleSeries: vehicle?.vehicleSeries,
    vehicleTrim: vehicle?.vehicleTrim,
    vehicleGroup: vehicle.itemType,
    makeColorName: vehicle?.makeColorName ?? FG_DEFAULT_OPTION_VAL,
    fuelCode: vehicle?.fuelCode
      ? vehicle?.fuelCode.toString()
      : FG_DEFAULT_OPTION_VAL,
    tankSize: vehicle?.assetTechnical?.assetTechnicalIce?.tankSize
      ? vehicle?.assetTechnical?.assetTechnicalIce?.tankSize.toString()
      : '',
    fastReportable: boolFieldToYesOrNo({
      data: vehicle,
      field: 'fastReportable',
    }),
    gvwr: vehicle?.assetTechnical?.gvwr
      ? vehicle?.assetTechnical?.gvwr.toString()
      : '',
    vamExempt: vehicle?.assetUsage?.vamExempt
      ? vehicle?.assetUsage?.vamExempt.toString()
      : FG_DEFAULT_OPTION_VAL,
    executiveFleet: boolFieldToYesOrNo({
      data: vehicle,
      field: 'assetUsage.executiveFleet',
    }),
    licensePlate: vehicle?.tagNumber,
    stateTag: vehicle?.ald?.stateTag,
    AFINumber: vehicle?.ald?.AFINumber,
  };

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'assignmentDate',
            label: 'Assignment date',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vinModify',
            label: 'Vin',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'vinVerifiedBy',
            label: 'VIN verified by',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vehicleOdometer',
            label: 'Vehicle odometer',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'modelYear',
            label: 'Year',
            type: 'text',
            disabled: true,
          },
          {
            id: 'makeCode',
            label: 'Make',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'modelCode',
            label: 'Model',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vehicleSeries',
            label: 'Vehicle series',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'vehicleTrim',
            label: 'Trim',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vehicleGroup',
            label: 'Vehicle group',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'makeColorName',
            label: 'Color',
            type: 'select',
            required: true,
            options: getOptionsWithDefault(colorOptionsState),
            disabled: whoCanEdit(ability, 'admin'),
          },
          {
            id: 'fuelCode',
            label: 'Fuel type',
            type: 'select',
            required: true,
            options: getOptionsWithDefault(fuelOptionsState),
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'tankSize',
            label: 'Fuel tank capacity',
            type: 'format-number',
            element: {
              control: { decimalScale: 4, suffix: ' gal' },
            },
          },
          {
            id: 'fastReportable',
            label: 'FAST reportable',
            type: 'select',
            required: true,
            options: yesOrNoOptions,
            disabled: whoCanEdit(ability, 'admin'),
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'gvwr',
            label: 'GVWR',
            type: 'format-number',
            element: {
              control: { decimalScale: 0, suffix: ' lb' },
            },
            disabled: whoCanEdit(ability, 'admin'),
          },
          {
            id: 'vamExempt',
            label: 'VAM exempt',
            type: 'select',
            options: getOptionsWithDefault(vemExemptOptions),
            disabled: whoCanEdit(ability, 'admin'),
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'executiveFleet',
            label: 'Executive fleet',
            type: 'select',
            options: yesOrNoOptions,
            disabled: whoCanEdit(ability, 'admin'),
          },
          {
            id: 'licensePlate',
            label: 'License plate',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'stateTag',
            label: 'State plate',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
          },
          {
            id: 'AFINumber',
            label: 'AFI number',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
          },
        ],
      },
    ],
  };

  return (
    isGFModalOpen && (
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            variant="large"
            title={<h2>Edit vehicle details information</h2>}
            onClose={onClose}
            actions={
              <>
                <Button variant="unstyled" onClick={onClose} label="Close" />
                <Button
                  className="margin-left-2"
                  type="submit"
                  form="g-vehicle-edit-form"
                  label="Save and close"
                />
              </>
            }
          >
            <p>
              Edit vehicle details information for VIN{' '}
              <span className="text-bold">{vehicle.id}</span> in the form below.
            </p>
            <p>
              Required fields are marked with an asterisk (
              <RequiredFieldIndicator />
              ).
            </p>
            <FormGenerator
              id="g-vehicle-edit-form"
              schema={schema}
              content={content}
              defaultValues={data}
              controls={{ 'format-number': FormattedNumberControl }}
              onSubmit={onSave}
            />
          </Modal>
        </div>
      </div>
    )
  );
}
