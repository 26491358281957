import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  canRegisterVehicle,
  canUpdateAOVehicle,
  canViewAOVehicles,
  canViewGFVehicles,
} from '../utilities/authorization';
import { determineRegistrationStatus } from '../components/vehicle-registration-status-tag';
import { isTest } from '../utilities/consts';
import useUser from '../utilities/use-user';

const useCanPerformActions = () => {
  const ability = useAppAbility();
  const { isRole } = useUser();

  const isActive = (vehicle) =>
    vehicle?.itemInventoryStatusCode === 'AC' ||
    vehicle?.itemInventoryStatusCode === 'DD';

  const isOrdered = (vehicle) =>
    vehicle?.itemInventoryStatusCode === 'AN' ||
    vehicle?.itemInventoryStatusCode === 'CC' ||
    vehicle?.itemInventoryStatusCode === 'IN' ||
    vehicle?.itemInventoryStatusCode === 'SP';

  const isNA = (vehicle) =>
    vehicle?.itemInventoryStatusCode === 'VC' ||
    vehicle?.itemInventoryStatusCode === 'TR' ||
    vehicle?.itemInventoryStatusCode === 'IV';

  const isMissing = (vehicle) => vehicle?.itemInventoryStatusCode === 'MS';

  const isSold = (vehicle) => vehicle?.itemInventoryStatusCode === 'SD';

  const isInvalid = (vehicle) =>
    vehicle?.itemInventoryStatusCode === '85' ||
    vehicle?.itemInventoryStatusCode === 'IV';

  const isAoAndAbleToRegister = (vehicle) => {
    if (isTest) return true;
    return vehicle?.ownershipTypeCode === 'AO' && canRegisterVehicle(ability);
  };

  const isAbleToViewAoAndGfVehicle = (vehicle) => {
    if (isTest) return true;
    return (
      (vehicle?.ownershipTypeCode === 'AO' && canViewAOVehicles(ability)) ||
      (vehicle?.ownershipTypeCode === 'GF' && canViewGFVehicles(ability))
    );
  };

  const isAoAndAbleToUpdate = (vehicle) => {
    if (isTest) return true;
    return vehicle?.ownershipTypeCode === 'AO' && canUpdateAOVehicle(ability);
  };

  const canRegister = (vehicle) => {
    const registrationStatus = determineRegistrationStatus(vehicle);
    return (
      isAoAndAbleToRegister(vehicle) &&
      registrationStatus !== 'Incomplete' &&
      isActive(vehicle)
    );
  };

  const canEditLpInfo = () => {
    if (isTest) return true;
    return isRole('FMVRSAdminRole');
  };

  const canEditVehicle = (vehicle) => {
    return (
      isAoAndAbleToRegister(vehicle) &&
      (isActive(vehicle) ||
        isMissing(vehicle) ||
        isRole('FMVRSAdminRole') ||
        ((isOrdered(vehicle) || isNA(vehicle)) &&
          (isRole('FMVRSAdminRole') ||
            isRole('SiteAdmin') ||
            isRole('CustomerAdmin') ||
            isRole('CustFltMan')))) &&
      !isInvalid(vehicle)
    );
  };

  const canEditMotorPoolAgency = () => {
    if (isTest) return true;
    return (
      isRole('FMVRSAdminRole') || isRole('SiteAdmin') || isRole('CustomerAdmin')
    );
  };

  const canEditLicensePlate = (vehicle, hasFMVRSrole) =>
    isAoAndAbleToRegister(vehicle) &&
    !isMissing(vehicle) &&
    (!isSold(vehicle) || hasFMVRSrole);

  const canEditPoc = (vehicle, hasFMVRSrole) => {
    return (
      isAoAndAbleToRegister(vehicle) &&
      !isMissing(vehicle) &&
      (!isSold(vehicle) || hasFMVRSrole) &&
      !isInvalid(vehicle)
    );
  };

  const canEditEquipment = (vehicle, hasFSRrole, hasCFMrole) => {
    return (
      isAoAndAbleToUpdate(vehicle) &&
      !isMissing(vehicle) &&
      (!isSold(vehicle) || hasFSRrole || hasCFMrole) &&
      !isInvalid(vehicle)
    );
  };

  const canViewPm = () => {
    return (
      isRole('FMVRSAdminRole') ||
      isRole('SiteAdmin') ||
      isRole('FleetSvcRep') ||
      isRole('MCCSpecialist') ||
      isRole('CustFltMan') ||
      isRole('FMCManager') ||
      isRole('CustomerAdmin')
    );
  };

  const canAddNewPm = (pmStatus) => {
    return (
      isRole('FMVRSAdminRole') ||
      isRole('SiteAdmin') ||
      isRole('FleetSvcRep') ||
      (isRole('CustFltMan') && pmStatus !== 'UpToDate') ||
      isRole('FMCManager') ||
      (isRole('CustomerAdmin') && pmStatus !== 'UpToDate')
    );
  };

  const canEditPm = () => {
    return (
      isRole('FMVRSAdminRole') ||
      isRole('SiteAdmin') ||
      isRole('FleetSvcRep') ||
      isRole('FMCManager')
    );
  };

  const canManageRecallCampaigns = () => {
    return isRole('FMVRSAdminRole') || isRole('SiteAdmin');
  };

  return {
    canEditLpInfo,
    canRegister,
    isAoAndAbleToRegister,
    canEditVehicle,
    canEditLicensePlate,
    canEditPoc,
    canEditEquipment,
    isAbleToViewAoAndGfVehicle,
    canEditMotorPoolAgency,
    canViewPm,
    canEditPm,
    canAddNewPm,
    canManageRecallCampaigns,
  };
};

export default useCanPerformActions;
