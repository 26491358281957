import React, { useEffect, useState, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typeahead, Checkbox, TextInput } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import licensePlateQueryFilters from '../helpers/license-plate-query-filters';
import {
  FETCH_TYPEAHEAD_FOR_VEHICLE_TYPE,
  FETCH_TAGS_LIST,
} from '../../../services/data-layer';
import { nullForForm } from '../../../utilities/common';
import LicensePlateDetails from './license-plate-details';
import alertManager from '../../../utilities/alert-manager';
import { useRegistration } from '../reg-context-provider';
import ExemptAlert from './exempt-alert';
import ExemptPopover from './exempt-popover';
import _ from 'lodash';
import useValidationModal from './validate-license-plate-modal';

export default function LicensePlate({
  vin,
  tagNumber,
  tagExpirationDate,
  exemptPlate,
  statePlate,
  itemType,
  tagAgencyCode,
  tagBureauCode,
  setFormState,
  getFieldValidation,
}) {
  const { setRegistrationError } = useRegistration();

  const [getTypeaheadsForVehicleType, { data }] = useLazyQuery(
    FETCH_TYPEAHEAD_FOR_VEHICLE_TYPE,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        setRegistrationError(err);
      },
    },
  );

  const [getTagList, { data: tagsdata }] = useLazyQuery(FETCH_TAGS_LIST, {
    fetchPolicy: 'no-cache',
    onError(err) {
      setRegistrationError(err);
    },
  });

  const [ValidateModal, openValidateModal] = useValidationModal();
  const [validateLicenseInfo, setValidateLicenseInfo] = useState(null);

  const [typeaheadValues, setTypeaheadValues] = useState([]);
  const [filterValue, setFilterValue] = useState(nullForForm(tagNumber));
  const [ExemptPlateAlert, { showWarningAlert, closeAlert }] =
    alertManager(false);
  const prevTagNumber = usePrevious(tagNumber);

  useEffect(() => {
    if (!tagNumber && prevTagNumber === undefined) {
      setFilterValue(nullForForm(tagNumber));
    }
    if (!tagNumber) {
      setFilterValue(nullForForm(tagNumber));
      setFormState({ tagExpirationDate: null });
    }

    if (tagNumber) {
      setFilterValue(tagNumber);
      setFormState({
        tagNumber,
        tagExpirationDate,
      });
    }
  }, [tagNumber]);

  const itemTypeRef = useRef(itemType);
  useEffect(() => {
    if (!tagNumber) {
      setFormState({
        tagNumber: '',
        tagExpirationDate: '',
      });
    }
    itemTypeRef.current = itemType;
  }, [itemType]);

  useEffect(() => {
    if (data?.getTypeaheadsForVehicleType?.typeaheads?.values) {
      setTypeaheadValues(data?.getTypeaheadsForVehicleType?.typeaheads?.values);
      setRegistrationError(null);
    }
  }, [data]);

  useEffect(() => {
    if (exemptPlate) {
      showWarningAlert(<ExemptAlert />);
    } else {
      closeAlert();
    }
  }, [exemptPlate]);

  useEffect(() => {
    if (tagsdata) {
      const tagList = tagsdata?.getTagList || [];
      const tagListATTD = tagList.filter(
        (d) => d.tagActivityTypeCode === 'ATTD',
      );
      const tagListRECV = tagList.filter(
        (d) => d.tagActivityTypeCode === 'RECV',
      );
      const tagListOther = tagList.filter(
        (d) =>
          d.tagActivityTypeCode !== 'RECV' && d.tagActivityTypeCode !== 'ATTD',
      );

      if (
        tagListATTD?.length &&
        tagListATTD[0]?.tagActivityTypeCode === 'ATTD'
      ) {
        setValidateLicenseInfo({
          type: 1,
          licensePlate: tagNumber,
          expirationDate: tagListATTD[0]?.expirationDate,
        });
        openValidateModal();
      }

      if (
        tagListRECV?.length > 1 &&
        tagListRECV[0]?.tagActivityTypeCode === 'RECV'
      ) {
        setValidateLicenseInfo({
          type: 2,
          licensePlate: tagNumber,
          expirationDate: tagListRECV[0]?.expirationDate,
          altExpirationDate: tagListRECV[1]?.expirationDate,
        });
        openValidateModal();
      }

      if (tagListOther?.length > 0) {
        setValidateLicenseInfo({
          type: 3,
          licensePlate: tagNumber,
          tagStatus: tagListOther[0]?.tagActivityTypeCode,
          expirationDate: tagListOther[0]?.expirationDate,
        });
        openValidateModal();
      }
    }
  }, [tagsdata]);

  const handleClearLicense = () => {
    setFormState({
      tagNumber: null,
      tagExpirationDate: null,
      tagAgencyCode: null,
      tagBureauCode: null,
    });
  };

  const fetchTagTypeaheads = (_, value) => {
    const filters = licensePlateQueryFilters(value);
    getTypeaheadsForVehicleType({
      variables: {
        field: 'id',
        order: 'id ASC',
        filters,
      },
    });
  };

  return (
    <>
      <ValidateModal
        licensePlate={tagNumber}
        clearLicense={handleClearLicense}
        licenseInfo={validateLicenseInfo}
      />
      <div className="tablet:grid-col-5">
        <div className="display-flex flex-align-center exempt-check">
          <Checkbox
            label={
              <>
                The Agency has an exemption from requirement to display official
                U.S. Government license plates
              </>
            }
            data-testid="checkbox-exempt"
            aria-label="Agency exemption from the requirement to display official U.S. Government license plates"
            checked={!!exemptPlate}
            value={!!exemptPlate}
            onChange={(e) => {
              const { checked } = e.currentTarget;
              setFormState({
                exemptPlate: checked,
                tagNumber: null,
                tagExpirationDate: null,
                tagAgencyCode: null,
                tagBureauCode: null,
              });
            }}
          />
          <div className="margin-top-2 margin-left-05">
            <ExemptPopover />
          </div>
        </div>
      </div>

      <ExemptPlateAlert />
      <div className="grid-row grid-gap margin-bottom-8">
        <div className="tablet:grid-col-4">
          {!exemptPlate ? (
            <Typeahead
              id="licensePlate"
              data-testid="licensePlate"
              accessor="tagNumber"
              filterValue={filterValue}
              placeholder="Search License Plate..."
              onOptionEnter={(value) => {
                setFormState({ tagNumber: value });
                getTagList({
                  variables: {
                    id: value,
                  },
                });
              }}
              typeaheadValues={typeaheadValues}
              fetchTypeaheadValues={fetchTagTypeaheads}
              onFilterChange={(e) => {
                e.target.value === ''
                  ? setFormState({ tagNumber: null })
                  : setFilterValue(e.target.value);
              }}
              inputCharNum={3}
              debounceDelay={500}
              label="Federal License Plate"
              labelClass="text-bold"
              disabled={_.isEmpty(itemType) || _.isEmpty(vin)}
              required
              errorMessage={getFieldValidation('tagNumber')}
              onClear={() => {
                setFormState({
                  tagNumber: null,
                  tagExpirationDate: null,
                  tagAgencyCode: null,
                  tagBureauCode: null,
                });
              }}
            />
          ) : null}
          <TextInput
            value={nullForForm(statePlate)}
            label="State license plate"
            id="statePlate"
            name="statePlate"
            disabled={_.isEmpty(itemType) || _.isEmpty(vin)}
            type="text"
            onChange={(e) => {
              const sp = e.target.value;
              setFormState({ statePlate: sp });
            }}
          />
        </div>
        {!exemptPlate ? (
          <LicensePlateDetails
            tagNumber={tagNumber}
            tagExpirationDate={tagExpirationDate}
            tagAgencyCode={tagAgencyCode}
            tagBureauCode={tagBureauCode}
            onChange={(details) => {
              if (details) {
                const {
                  expirationDate,
                  customerAgencyCode,
                  customerBureauCode,
                  customerSubSectionCode,
                } = details;
                setFormState({
                  tagExpirationDate: expirationDate,
                  tagAgencyCode: customerAgencyCode,
                  tagBureauCode: customerBureauCode,
                  tagOfficeCode: customerSubSectionCode,
                });
              }
            }}
          />
        ) : null}
      </div>
    </>
  );
}
