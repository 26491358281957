import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import {
  AFPTable,
  EmptyState,
  Spinner,
  Menu,
  Pagination,
} from '@gsa/afp-component-library';
import { VMSSubjects, VMSOperations } from 'utilities/consts';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import useCanPerformActions from 'hooks/use-can-perform-actions';
// import PmStatusBadge from 'components/pm-express/pm-status-badge';
import moment from 'moment';
// import PmHistoryFilters from './pm-history-filters';
import { PmRowDetails } from './pm-row-details';
import { usePm } from './pm-provider';

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
};

const tableRef = React.createRef();
export const emdash = '\u2014';

const PmHistory = () => {
  const { vehicle } = useVehicle();
  const {
    pmHistoryList,
    pmHistoryListLoading,
    getPmByVehicle,
    openManagePmModal,
    openDeletePmModal,
    dispatch,
  } = usePm();

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const canPerformActions = useCanPerformActions();

  const actionList = [
    {
      icon: 'edit',
      label: 'Edit',
      action: 'editPm',
      operation: VMSOperations.UPDATE_VEHICLE_GF,
      canShowIndicator: () => canPerformActions.canEditPm(),
    },
    {
      icon: 'delete',
      label: 'Delete',
      action: 'deletePm',
      operation: VMSOperations.UPDATE_VEHICLE_GF,
      canShowIndicator: () => canPerformActions.canEditPm(),
    },
  ];

  const ability = useAppAbility();
  const [order, setOrder] = useState('updatedAt DESC');

  useEffect(() => {
    if (vehicle?.uuid) {
      getPmByVehicle({
        variables: {
          limit: paginationState.limit,
          offset: paginationState.offset,
          assetId: vehicle?.uuid,
          order,
        },
      });
    }
  }, [vehicle, order, paginationState]);

  const handleEditPm = (data) => {
    dispatch({
      type: 'SET_SELECTED_PM',
      payload: data,
    });
    openManagePmModal();
  };

  const handleDeletePm = (data) => {
    dispatch({
      type: 'SET_SELECTED_PM',
      payload: data,
    });
    openDeletePmModal();
  };

  const handleSelectedAction = (action, row) => {
    switch (action) {
      case 'editPm':
        handleEditPm(row);
        break;
      case 'deletePm':
        handleDeletePm(row);
        break;
      default:
        break;
    }
  };

  const allowedActions = actionList.filter((actionItem) => {
    const canShow = actionItem?.canShowIndicator;
    return ability.can(actionItem.operation, VMSSubjects.VEHICLE) && canShow;
  });

  // eslint-disable-next-line react/prop-types
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'PM date',
        accessor: 'currentPmDate',
        sortable: true,
        Cell: ({ value }) =>
          value ? moment.utc(value).format('MM/DD/YYYY') : emdash,
      },

      {
        Header: 'PM mileage',
        accessor: 'currentPmMileage',
        sortable: true,
        Cell: ({ value }) => value?.toLocaleString() || emdash,
      },

      {
        Header: 'PM due date',
        accessor: 'nextPmDate',
        sortable: true,
        Cell: ({ value }) =>
          value ? moment.utc(value).format('MM/DD/YYYY') : emdash,
      },

      {
        Header: 'PM due mileage',
        accessor: 'nextPmMileage',
        sortable: true,
        Cell: ({ value }) => value?.toLocaleString() || emdash,
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return canPerformActions.canEditPm() ? (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={allowedActions}
                // eslint-disable-next-line react/prop-types
                onActionClick={(action) => {
                  handleSelectedAction(action, original);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          ) : (
            emdash
          );
        },
      },
    ];

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const {
      pmSchedule,
      customerId,
      methodOfEntry,
      currentPmDate,
      createdByUser,
      createdAt,
      updatedAt,
      invoiceData,
      pmAssetTechnical,
      customer,
    } = original;
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <PmRowDetails
            detail={{
              customer,
              pmSchedule,
              customerId,
              methodOfEntry,
              currentPmDate,
              createdByUser,
              createdAt,
              updatedAt,
              invoiceData,
              pmAssetTechnical,
            }}
          />
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <div className="margin-top-3">
      <h2>Preventative maintenance history</h2>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="pm-listing-table"
        columns={columns}
        data={pmHistoryListLoading ? [] : pmHistoryList?.rows || []}
        defaultSort={order}
        onSort={setOrder}
        expandable
        renderRowSubComponent={renderRowSubComponent}
      />
      {pmHistoryListLoading && <Spinner className="padding-y-9" />}
      {pmHistoryList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={pmHistoryList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}

      {(!pmHistoryList || pmHistoryList?.rows?.length === 0) &&
        !pmHistoryListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">No data available</div>
          </div>
        )}
    </div>
  );
};

export default PmHistory;
