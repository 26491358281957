import { Button, DetailsTable, EmptyState } from '@gsa/afp-component-library';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { constructPayload } from '../../../registration-details/helpers/payload-constructors';
import useCanPerformActions from '../../../../hooks/use-can-perform-actions';
import useUser from '../../../../utilities/use-user';
import { ExemptLabeledMessage } from '../../../exempt-message';
import TagStatusBadge from '../../../license-plate/table-widgets/tag-status-badge';
import LicensePlateEdit from './license-plate-edit';
import { fieldGetter } from '../../helpers/field-getter';

const LicensePlate = ({
  vehicle,
  updateRegistration,
  setTagForDisplay,
  updateStatePlate,
}) => {
  const getStatePlate = vehicle?.ald?.stateTag || '';
  const getStatePlateExp = vehicle?.ald?.stateTagExpirationDate || '';
  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const canPerformActions = useCanPerformActions();
  const canRegisterVehicle = canPerformActions.canRegister(vehicle);

  const vehicleRegistered = !!vehicle?.tag || vehicle?.exemptPlate;

  const [editingLP, setEditingLP] = useState(false);
  const toggleLPEdit = () => {
    setEditingLP((currentEdit) => !currentEdit);
  };

  const canEditLicensePlate = canPerformActions.canEditLicensePlate(
    vehicle,
    hasFMVRSrole,
  );

  const updateRegDetails = (details) => {
    const payloadStructure = constructPayload(vehicle);
    const payload = { ...payloadStructure, ...details, mode: 'edit' };
    updateRegistration(payload);
  };

  const handleSettingTagForDisplay = () => {
    if (vehicle?.tagNumber) {
      setTagForDisplay({
        tagNumber: vehicle?.tagNumber,
        tagExpirationDate: vehicle?.tag?.expirationDate,
      });
    } else {
      setTagForDisplay(null);
    }
  };

  const saveLPChanges = (lp) => {
    toggleLPEdit();
    handleSettingTagForDisplay();
    if (
      lp.tagNumber !== vehicle.tagNumber ||
      lp.exemptPlate !== vehicle.exemptPlate
    ) {
      updateRegDetails(lp);
    } else if (
      lp.statePlate !== getStatePlate ||
      lp.statePlateExp !== getStatePlateExp
    ) {
      updateStatePlate(lp.statePlate, parseInt(lp.stateLicPlateExpDate, 10));
    }
  };

  return (
    <>
      {vehicleRegistered ? (
        <>
          <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
            {!vehicle?.exemptPlate && (
              <DetailsTable
                data-testid="afp-registered_vehicle_License_plate"
                className="afp-registration__section_container"
                data={[
                  [
                    'License plate',
                    <Link
                      to={`/license-plate/${encodeURIComponent(
                        vehicle?.tagNumber,
                      )}?tagExpirationDate=${vehicle?.tag?.expirationDate}`}
                    >
                      {vehicle?.tagNumber}
                    </Link>,
                  ],
                  [
                    'License plate status',
                    <TagStatusBadge
                      tagStatus={vehicle?.tag?.tagActivityTypeCode}
                    />,
                  ],
                  [
                    'License plate expiration date',
                    fieldGetter([
                      {
                        field: vehicle?.tag?.expirationDate,
                        formatter: 'exp',
                      },
                    ]),
                  ],
                  [
                    'State license plate',
                    fieldGetter([
                      {
                        field: getStatePlate,
                      },
                    ]),
                  ],
                  [
                    'State license plate expiration date',
                    fieldGetter([
                      {
                        field: getStatePlateExp,
                        formatter: 'exp',
                      },
                    ]),
                  ],
                ]}
              />
            )}
            {vehicle?.exemptPlate && (
              <DetailsTable
                data-testid="afp-registered_vehicle_License_plate"
                className="afp-registration__section_container"
                data={[
                  ['License plate', <ExemptLabeledMessage />],
                  [
                    'State license plate',
                    fieldGetter([
                      {
                        field: getStatePlate,
                      },
                    ]),
                  ],
                  [
                    'State license plate expiration date',
                    fieldGetter([
                      {
                        field: getStatePlateExp,
                        formatter: 'exp',
                      },
                    ]),
                  ],
                ]}
              />
            )}
            {canEditLicensePlate && (
              <Button
                data-testid="lp-edit-button"
                onClick={toggleLPEdit}
                variant="outline"
                className="lp-edit bg-white margin-top-2"
                aria-label="edit license plate"
                label="Edit"
              />
            )}
          </div>
          <LicensePlateEdit
            licensePlate={vehicle?.tagNumber || ''}
            lpExpDate={vehicle?.tag?.expirationDate}
            stateLicPlate={getStatePlate}
            stateLicPlateExpDate={getStatePlateExp}
            editing={editingLP}
            onClose={toggleLPEdit}
            onSave={saveLPChanges}
            exemptPlate={vehicle?.exemptPlate}
          />
        </>
      ) : (
        <div
          className="bg-gray-3 radius-md padding-y-2 padding-x-4 afp-registration__section_lp_register"
          data-testid="afp-unregistered_vehicle_default_info"
        >
          <div className="afp-vehicle-details__no_image padding-y-4">
            <EmptyState
              alt="Image not available"
              bottomText={
                !canRegisterVehicle ? 'No attached license plate' : ''
              }
              hasBackground
            />
          </div>
          {canRegisterVehicle && (
            <>
              <div data-testid="register-vehicle-placeholder">
                Add a federal license plate
                <br />
                and two points of contact to register
                <br />
                this vehicle{' '}
              </div>
              <div className="margin-top-2 margin-bottom-2">
                <Link
                  to={`/vehicle-registration/${encodeURIComponent(
                    _.get(vehicle, 'id'),
                  )}`}
                >
                  <Button
                    aria-label="register vehicle"
                    label="Register Vehicle"
                    data-testid="register-vehicle-button"
                  />
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

LicensePlate.defaultProps = {
  updateRegistration: () => {
    // noop
  },
  setTagForDisplay: () => {
    // noop
  },
  updateStatePlate: () => {
    // noop
  },
  vehicle: {},
};

LicensePlate.propTypes = {
  updateRegistration: PropTypes.func,
  setTagForDisplay: PropTypes.func,
  updateStatePlate: PropTypes.func,
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    tagNumber: PropTypes.string,
    exemptPlate: PropTypes.bool,
    ald: PropTypes.shape({
      stateTag: PropTypes.string,
      stateTagExpirationDate: PropTypes.number,
    }),
    tag: PropTypes.shape({
      tagActivityTypeCode: PropTypes.string,
      expirationDate: PropTypes.string,
    }),
  }),
};

export default LicensePlate;
