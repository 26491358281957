/* eslint-disable react/prop-types */
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import React, { useEffect, useMemo, useRef } from 'react';
import { Alert } from '@gsa/afp-component-library';
import {
  licensePlateSchema,
  recoveredSchema,
  stolenSchema,
} from './report-stolen-form-schema';

export const ReportStolenForm = ({
  content,
  mode,
  reportData,
  setReportData,
  onSubmit,
}) => {
  const reportStolenVehicleRef = useRef(null);

  useEffect(() => {
    if (!reportStolenVehicleRef.current) return () => {};
    const subscription = reportStolenVehicleRef.current.watch((data) => {
      setReportData({
        ...reportData,
        ...data,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [reportStolenVehicleRef.current]);

  const today = new Date().toISOString();

  const defaultFormData = useMemo(() => {
    const {
      stolenDate = null,
      gsaReportedDate = null,
      dhsReportedDate = null,
      recoveredDate = null,
      dhsCaseNumber = '',
      ncicNumber = '',
      comment = '',
    } = reportData;
    const defaultData = {
      stolenDate,
      gsaReportedDate,
      dhsReportedDate,
      recoveredDate,
      dhsCaseNumber,
      ncicNumber,
      comment,
    };
    if (mode === 'plate') {
      const { tagA = true, tagB = true } = reportData;

      defaultData.tagA = tagA;
      defaultData.tagB = tagB;
    }
    return defaultData;
  }, []);

  const dateRecoveredField = {
    columnWidth: 'col-4-12',
    id: 'recoveredDate',
    type: 'datepicker',
    label: 'Date recovered',
    required: true,
    element: {
      control: {
        maxDate: today,
      },
    },
  };

  const dateStolenOrRecovered = {
    gap: '124px',
    fieldLayout: 'horizontal',
    fields: [
      {
        columnWidth: 'col-4-12',
        id: 'stolenDate',
        type: 'datepicker',
        label: 'Date stolen',
        required: true,
        disabled: mode === 'recover',
        element: {
          control: {
            maxDate: today,
          },
        },
      },
    ],
  };

  const recoverdPlatesCheckbox = () => {
    return mode === 'recover'
      ? {
          gap: '8px',
          fields: [
            {
              id: 'plateMissing',
              label: 'Federal license plates recovered.',
              component: () => {
                return (
                  <>
                    <span className="usa-hint">Select all that apply</span>
                  </>
                );
              },
            },
            {
              id: 'recoveredTagA',
              type: 'checkbox',
              label: 'Plate A',
            },
            {
              id: 'recoveredTagB',
              type: 'checkbox',
              label: 'Plate B',
            },
          ],
        }
      : null;
  };

  let formSchema = stolenSchema;
  if (mode === 'recover') {
    formSchema = recoveredSchema;
    dateStolenOrRecovered.fields.push(dateRecoveredField);
  }
  if (mode === 'plate') {
    formSchema = licensePlateSchema;
  }
  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      dateStolenOrRecovered,
      {
        gap: '124px',
        fieldLayout: "horizontal",
        fields: [
          {
            columnWidth: 'col-4-12',
            id: 'gsaReportedDate',
            type: 'datepicker',
            label: 'Date reported to GSA',
            required: true,
            disabled: mode === 'recover',
            element: {
              control: {
                maxDate: today,
              },
            },
          },
          {
            columnWidth: 'col-4-12',
            id: 'dhsReportedDate',
            type: 'datepicker',
            label: 'Date reported to DHS',
            disabled: mode === 'recover',
            element: {
              control: {
                maxDate: today,
              },
            },
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            id: 'dhsCaseNumber',
            type: 'text',
            label: 'DHS Case number',
            disabled: mode === 'recover',
            element: {
              control: {
                maxLength: 20,
                countdown: true,
              },
            },
          },
          {
            id: 'ncicNumber',
            type: 'text',
            label: 'NCIC Case number',
            disabled: mode === 'recover',
            element: {
              control: {
                maxLength: 20,
                countdown: true,
              },
            },
          },
        ],
      },
      {
        fields: [
          {
            columnWidth: 'col-12-12',
            id: 'comment',
            type: 'textarea',
            label: 'Comments',
            required: true,
            element: {
              control: {
                maxLength: 250,
                countdown: true,
              },
            },
          },
          {
            component: () => {
              return (
                <>
                  { mode !== 'recover' &&
                    <Alert
                      id="licensePlateAlert"
                      data-testId="licensePlateAlert"
                      type="warning"
                    >
                      Note: If the stolen vehicle has federal license plates
                      attached, they will be reported as missing with the data
                      provided above.
                    </Alert>
                  }
                </>
              );
            },
          },
        ],
      },
      recoverdPlatesCheckbox(),
    ],
  };

  return (
    <FormGenerator
      ref={reportStolenVehicleRef}
      id="report-stolen-vehicle-form"
      data-testid="report-stolen-vehicle-form"
      schema={formSchema}
      defaultValues={defaultFormData}
      content={content || formContent}
      onSubmit={() => onSubmit(reportData)}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};
