import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Controller, useWatch } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { SelectDropdown } from '@gsa/afp-component-library';
import { DEFAULT_OPTION } from '../config/helpers';
import { GET_AGENCY_INDICATORS_BY_AGENCY_BUREAU } from '../../../helpers/vehicle-reports.gql';

const getAgencyIndicatorsOption = (items = []) => {
  if (items?.length === 0) return [];
  return items?.map((item) => ({
    value: item.agencySubGroup,
    label: item.agencySubGroup,
  }));
};

// eslint-disable-next-line react/prop-types
export const AgencyIndicatorSelection = ({ control }) => {
  const [agency, bureau, selectedVehicleOption] = useWatch({
    name: ['agency', 'bureau', 'vehicle_options'],
    control,
  });
  const [agencyIndicators, setAgencyIndicators] = useState([]);
  const [internalError, setInternalError] = useState();

  const [agencyIndicatorByAgencyAndBureauQuery] = useLazyQuery(
    GET_AGENCY_INDICATORS_BY_AGENCY_BUREAU,
    {
      onError: (err) => {
        console.log(err.message);
        setInternalError(err);
      },
      onCompleted: (data) => {
        setAgencyIndicators(data?.getAgencyIndicatorsByAgencyBureau || []);
        setInternalError(undefined);
      },
    },
  );

  useEffect(() => {
    if (selectedVehicleOption === 'GF') {
      if (agency !== 'DEFAULT') {
        agencyIndicatorByAgencyAndBureauQuery({
          variables: {
            agencyCode: agency !== 'DEFAULT' ? agency : null,
            bureauCode: bureau !== 'DEFAULT' ? bureau : null,
          },
        });
      }
    }
  }, [agency, bureau, selectedVehicleOption]);

  if (internalError && internalError[0]?.message.includes('Not Authorized!')) {
    return <Redirect to="/unauthorized" />;
  }
  return (
    <section className="margin-top-3">
      <div>
        <Controller
          control={control}
          name="agencyIndicator"
          defaultValue={DEFAULT_OPTION.value}
          render={({ field: { ref, ...rest } }) => (
            <SelectDropdown
              label={<b>Agency indicator</b>}
              name="agencyIndicator"
              id="agencyIndicator"
              placeholder="Select Agency indicator"
              options={[
                DEFAULT_OPTION,
                ...getAgencyIndicatorsOption(agencyIndicators),
              ]}
              {...rest}
            />
          )}
        />
      </div>
    </section>
  );
};
