import { gql } from '@apollo/client';

export const GET_SALES_CODES = gql`
  query GetSalesCodes {
    getSalesCodes {
      code
    }
  }
`;

export const GET_BILLING_HISTORY = gql`
  query GetBillingHistory(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getBillingHistory(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      rows {
        createdAt
        salesCode
        totalAmount
        createdAt
        milesDriven
        endMileage
        daysUsed
        actionCode
        customerBoac
        accessoryCharge
        salesCodeModel {
          description
        }
      }
      count
      hasMore
    }
  }
`;
