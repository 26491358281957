import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from 'react';
import QueryString from 'query-string';
import * as PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import {
  CREATE_OR_UPDATE_MISSING_TAG,
  GET_TAGS_LOST_STOLEN,
  GET_TAG,
  GET_TAG_ACTIVITY_LIST,
  UPDATE_LP_INFO,
} from '../../services/data-layer';
import useLpDisassociationManager from './helpers/lp-disassociation-manager';
import useLpReconciliationManager from './helpers/lp-reconciliation-manager';
import useLicensePlateDestructionManager from '../license-plate/widgets/helpers/license-plate-destruction-manager';
import LicensePlateDestructionContextProvider from '../license-plate-destruction/context';

export const LicensePlateDetailsContext = createContext();

const initFilters = [
  {
    conditions: [],
    operator: '$and',
  },
];

const initVariables = {
  order: [['recordedAt', 'DESC']],
  filters: initFilters,
};

const initialState = {
  loading: false,
  error: false,
  tagDetails: {},
  expirationDates: [],
  tagActivityList: [],
  expirationDatesFlag: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: action.payload };
    case 'error':
      return { ...state, error: action.payload };
    case 'UPDATING_LPINFO':
      return { ...state, updatingLpInfo: action.payload };
    case 'ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_TAG_ATTACHMENTS':
      return {
        ...state,
        tagDetails: {
          ...state.tagDetails,
          attachments: action.payload,
        },
      };
    case 'SET_TAG_DETAILS':
      return { ...state, tagDetails: action.payload };
    case 'SET_TAG_ACTIVITY_LIST':
      return { ...state, tagActivityList: action.payload };
    case 'SET_TAG_ACTIVITY_EXP_DATES':
      return {
        ...state,
        expirationDates: action.payload,
        expirationDatesFlag: true,
      };
    default:
      return state;
  }
};

const LicensePlateDetailsProvider = ({ children, ...props }) => {
  const {
    disassociateTag,
    disassociationAlert,
    disassociationPayload,
    setDisassociationPayload,
  } = useLpDisassociationManager();

  const {
    reconcileTag,
    reconciliationAlert,
    reconciliationPayload,
    setReconciliationPayload,
  } = useLpReconciliationManager();

  const {
    forDestructionList,
    setForDestructionList,
    setEligibleForDestruction,
    getIneligiblePlateDistribution,
    ineligibleDestructionList,
    destructionInitData,
    setDestructionInitData,
    toggleDestructionEditing,
    destructionEditing,
    saveDestruction,
  } = useLicensePlateDestructionManager();

  const [state, dispatch] = useReducer(reducer, initialState);

  const history = useHistory();
  const { tagExpirationDate } = QueryString.parse(history.location.search);

  let { id } = useParams();
  id = decodeURIComponent(id);

  const [tagActivityFilters, setTagActivityFilters] = useState(
    initVariables.filters,
  );
  const [tagActivityOrder, setTagActivityOrder] = useState(initVariables.order);

  const [reportingStolenLPGF, setReportingStolenLPGF] = useState(false);

  const [missingPlateData, setMissingPlateData] = useState([]);

  const [createOrUpdateMissingTags, setCreateOrUpdateMissingTags] = useState({
    aOrB: 'both',
    createOrUpdate: 'create',
  });

  const setFilters = (newFilters, latestTagExpirationDate) => {
    const baseTagActivityFilter = [
      {
        conditions: [
          {
            key: 'tagNumber',
            operator: '$exact',
            value: id,
          },
          {
            key: 'expirationDate',
            operator: '$exact',
            value: tagExpirationDate || _.toString(latestTagExpirationDate),
          },
        ],
        operator: '$and',
      },
    ];
    let filters = tagActivityFilters;
    let order = tagActivityOrder;

    if (newFilters?.filters) {
      setTagActivityFilters([...baseTagActivityFilter, ...newFilters?.filters]);
      filters = [...baseTagActivityFilter, ...newFilters?.filters];
    } else if (!newFilters?.expirationDate) {
      setTagActivityFilters([...baseTagActivityFilter]);
      filters = [...baseTagActivityFilter];
    }

    if (newFilters?.order) {
      setTagActivityOrder(newFilters?.order);
      order = newFilters?.order;
    }

    getTagActivityList({
      variables: {
        filters,
        order,
        tagNumber: id,
        tagExpirationDate:
          _.toNumber(tagExpirationDate) || latestTagExpirationDate,
      },
    });
  };

  const setServiceTypeError = (payload) => {
    dispatch({
      type: 'error',
      payload,
    });
  };

  const setUpdatingLpInfo = (payload) => {
    dispatch({
      type: 'UPDATING_LPINFO',
      payload,
    });
  };

  const setUpdateAlertMessage = (payload) => {
    dispatch({
      type: 'ALERT_MESSAGE',
      payload,
    });
  };

  const setTagDetailsState = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [getTag, { data, refetch }] = useLazyQuery(GET_TAG, {
    fetchPolicy: 'no-cache',
    onError: (requestError) => {
      setServiceTypeError(requestError.message ?? 'Unknown Error.');
    },
  });

  const [
    reportMissingPlate,
    { error: reportMissingPlateError, loading: reportMissingPlateLoading },
  ] = useMutation(CREATE_OR_UPDATE_MISSING_TAG, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.createOrUpdateTagLostStolen && id) {
        getTag({
          variables: {
            id,
            tagExpirationDate: tagExpirationDate
              ? _.toNumber(tagExpirationDate)
              : null,
          },
        });

        setUpdateAlertMessage({
          type: 'success',
          message: 'Missing license plate report successfully submitted',
        });
        setReportingStolenLPGF(false);
        setMissingPlateData([]);
      }
    },
    onError: (error) => {
      setUpdateAlertMessage({
        type: 'error',
        message: error?.message || 'Unknown Error.',
      });
    },
  });
  const [getMissingPlateData, { loading: getMissingPlateDataLoading }] =
    useLazyQuery(GET_TAGS_LOST_STOLEN, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (response) => {
        if (response.getTagsLostStolen) {
          setMissingPlateData(response.getTagsLostStolen);
        }
      },
      onError: (error) => {
        setUpdateAlertMessage({
          type: 'error',
          message: error?.message || 'Unknown Error.',
        });
      },
    });

  const [updateLp, { data: updatedLpData, loading: updatingLpInfoLoading }] =
    useMutation(UPDATE_LP_INFO, {
      fetchPolicy: 'no-cache',
      onCompleted: (value) => {
        if (data) {
          getTag({
            variables: {
              id: value.updateLpInfo.id,
              expirationDate: value.updateLpInfo.expirationDate,
            },
          });

          setUpdateAlertMessage({
            type: 'success',
            message: 'License plate successfully updated.',
          });
        }
      },
      onError: (error) => {
        setUpdateAlertMessage({
          type: 'error',
          message: error?.message || 'Unknown Error.',
        });
      },
    });

  const [getTagActivityList] = useLazyQuery(GET_TAG_ACTIVITY_LIST, {
    fetchPolicy: 'no-cache',
    onError: (requestError) => {
      setServiceTypeError(requestError.message ?? 'Unknown Error.');
    },
    onCompleted: (responseData) => {
      if (responseData?.getTagActivity) {
        const tagActivityData = _.get(responseData, 'getTagActivity');
        const mergedActivities = [];
        let expirationDates = [];
        tagActivityData &&
          tagActivityData.map((tad) => {
            const expDate = moment(tad.expirationDate, 'YYYYMM').format(
              'MM/YYYY',
            );
            const index = mergedActivities.findIndex(
              (tg) =>
                tg.tagNumber === tad.tagNumber &&
                tg.expirationDate === tad.expirationDate &&
                tg.typeCode === tad.typeCode &&
                tg.serialNumberVin === tad.serialNumberVin &&
                tg.recordedAt === tad.recordedAt,
            );
            const expDateIndex = expirationDates.findIndex(
              (tg) => tg.value === tad.expirationDate,
            );
            if (index !== -1) {
              mergedActivities[index] = {
                ...mergedActivities[index],
                aOrB:
                  mergedActivities[index].aOrB.indexOf(tad.aOrB) !== -1
                    ? mergedActivities[index].aOrB
                    : `${mergedActivities[index].aOrB} ${tad.aOrB}`,
              };
            } else {
              mergedActivities.push(tad);
            }
            if (expDateIndex === -1) {
              expirationDates.push({
                label: expDate,
                value: tad.expirationDate,
              });
            }
          });
        expirationDates = expirationDates.sort((a, b) => a.value - b.value);
        setTagDetailsState('SET_TAG_ACTIVITY_LIST', mergedActivities);
        if (!state.expirationDatesFlag) {
          setTagDetailsState('SET_TAG_ACTIVITY_EXP_DATES', expirationDates);
        }
        setServiceTypeError('');
      }
    },
  });

  useEffect(() => {
    if (id) {
      getTag({
        variables: {
          id,
          tagExpirationDate: tagExpirationDate
            ? _.toNumber(tagExpirationDate)
            : null,
        },
      });
    }
  }, [id, tagExpirationDate]);

  useEffect(() => {
    if (data?.getTag) {
      setTagDetailsState('SET_TAG_DETAILS', {
        ..._.get(data, 'getTag'),
        ...(data?.getSupportingAttachments && {
          attachments: data?.getSupportingAttachments,
        }),
      });
      setServiceTypeError('');
      // get tag activity
      setFilters({}, _.get(data, 'getTag.expirationDate'));
    }
  }, [data]);

  useEffect(() => {
    if (
      disassociationPayload?.disassociatedTag ||
      reconciliationPayload?.reconciledTags
    ) {
      refetch();
      setFilters(); // trigger refetch TagActivityList
    }
  }, [
    disassociationPayload?.disassociatedTag,
    reconciliationPayload?.reconciledTags,
  ]);

  const updateLpInfo = (expirationDate, orderNumber) => {
    const { tagDetails } = state;
    const tagUuids = [tagDetails.uuid];
    if (tagDetails?.counterPartTag?.uuid)
      tagUuids.push(tagDetails.counterPartTag.uuid);

    const unicorUuids = [];
    if (tagDetails?.unicorTag?.uuid)
      unicorUuids.push(tagDetails?.unicorTag?.uuid);
    if (tagDetails?.counterPartTag?.unicorTag?.uuid)
      unicorUuids.push(tagDetails.counterPartTag.unicorTag.uuid);

    updateLp({
      variables: {
        expirationDate,
        orderNumber,
        tagUuids,
        unicorUuids: unicorUuids.length > 0 ? unicorUuids : null,
      },
    });
  };

  const reportStolenLPGF = (payload) => {
    reportMissingPlate({
      variables: {
        tagLostStolenInput: {
          ...payload,
        },
      },
    });
  };

  const getMissingPlates = (payload) => {
    getMissingPlateData({
      variables: {
        ...payload,
      },
    });
  };

  return (
    <LicensePlateDetailsContext.Provider
      value={{
        ...state,
        setTagDetailsState,
        getTag,
        refetch,
        getTagActivityList,
        setFilters,
        disassociateTag,
        disassociationPayload,
        setDisassociationPayload,
        disassociationAlert,
        reconcileTag,
        reconciliationAlert,
        reconciliationPayload,
        setReconciliationPayload,
        setUpdatingLpInfo,
        updateLpInfo,
        updatingLpInfoLoading,
        updatedLpData,
        reportingStolenLPGF,
        setReportingStolenLPGF,
        reportStolenLPGF,
        reportMissingPlateError,
        reportMissingPlateLoading,
        getMissingPlates,
        getMissingPlateDataLoading,
        missingPlateData,
        createOrUpdateMissingTags,
        setCreateOrUpdateMissingTags,
        ...props,
      }}
    >
      <LicensePlateDestructionContextProvider
        forDestructionList={forDestructionList}
        setForDestructionList={setForDestructionList}
        setEligibleForDestruction={setEligibleForDestruction}
        getIneligiblePlateDistribution={getIneligiblePlateDistribution}
        ineligibleDestructionList={ineligibleDestructionList}
        setDestructionInitData={setDestructionInitData}
        destructionInitData={destructionInitData}
        destructionEditing={destructionEditing}
        toggleDestructionEditing={toggleDestructionEditing}
        saveDestruction={saveDestruction}
      >
        {children}
      </LicensePlateDestructionContextProvider>
    </LicensePlateDetailsContext.Provider>
  );
};

export default LicensePlateDetailsProvider;

LicensePlateDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLicensePlateDetails = () =>
  useContext(LicensePlateDetailsContext);
