import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  DetailsTable,
  FilterPanel,
  AFPTable,
  Icon,
  Spinner,
  Pagination,
  EmptyState,
} from '@gsa/afp-component-library';
import { emdash } from 'components/common';

import { useVehicle } from '../../../vehicle-context-provider';
import { GET_SALES_CODES, GET_BILLING_HISTORY } from '../billing-history-gql';
import BillingHistoryTableDetail from './billing-history-table-detail';

const BillingHistoryTable = () => {
  const { vehicle } = useVehicle();

  const DEFAULT_OPTION = {
    value: '',
    label: '- Select -',
    defaultValue: true,
  };

  const tableData = [
    ['Current customer account name', vehicle?.customer?.accountName || emdash],
    [
      'Current customer account number',
      vehicle?.customer?.customerId || emdash,
    ],
    ['Fund code', vehicle?.ald?.fundCode || emdash],
    ['Description 1', vehicle?.ald?.account1 || emdash],
    ['Description 2', vehicle?.ald?.account2 || emdash],
  ];

  const TABLE_HEADER = [
    {
      Header: 'Transaction Date',
      accessor: 'createdAt',
      sortable: true,
    },
    {
      Header: 'Sales code',
      accessor: 'salesCode',
      sortable: false,
    },
    {
      Header: 'Description',
      accessor: 'salesCodeDescription',
      sortable: false,
    },
    {
      Header: 'Total amount',
      accessor: 'totalAmount',
      sortable: false,
    },
  ];

  const initialPaginationState = {
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  };

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const [order, setOrder] = useState('`createdAt` DESC');

  const TABLE_ICON_COLLAPSED = (
    <Icon className="usa-icon--size-4" iconName="navigate_next" />
  );
  const TABLE_ICON_EXPANDED = (
    <Icon className="usa-icon--size-4" iconName="expand_more" />
  );

  const [salesCodes, setSalesCodes] = useState(DEFAULT_OPTION);
  const [billingHistoryList, setBillingHistoryList] = useState();

  const initaialFilterStructure = [
    {
      expanded: true,
      filters: [
        {
          columnWidth: '5',
          key: 'salesCode',
          id: 'sales-code',
          label: 'Sales code',
          operator: '$exact',
          options: [DEFAULT_OPTION],
          permanent: false,
          title: 'Sales code',
          type: 'select',
          value: '',
        },
        {
          columnWidth: '6',
          hideClear: true,
          id: 'date-range-time',
          key: 'date-range-time',
          type: 'daterange',
          operator: '$between',
          options: {
            className: 'display-flex',
            startDate: {
              key: 'beginning',
              label: <strong>Start date</strong>,
              // defaultValue: startDate,
            },
            endDate: {
              key: 'end',
              label: <strong>End date</strong>,
              //  defaultValue: endDate,
            },
            wrapperClass: 'margin-top-0',
          },

          value: {
            beginning: '',
            end: '',
          },
        },
      ],
      key: 1,
      requiredAsterisk: true,
      title: '',
    },
  ];

  const [filterStructure, setfilterStructure] = useState(
    initaialFilterStructure,
  );

  const [getBillingHistoryList, { loading: billingHistoryLoading }] =
    useLazyQuery(GET_BILLING_HISTORY, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      // onError: (error) => setRequestError(error),
      onCompleted: (responseData) => {
        if (responseData?.getBillingHistory) {
          const billingRecords = cloneDeep(responseData?.getBillingHistory);
          const records = billingRecords?.rows?.map((item) => {
            const billingRecord = item;
            billingRecord.createdAt = moment(billingRecord.createdAt).format(
              'MM/DD/YYYY',
            );
            return billingRecord;
          });
          billingRecords.rows = records;
          setBillingHistoryList(billingRecords);
          // dispatchAction('SET_BILLING_HISTORY_LIST', billingRecords);
        }
      },
    });
  const [getSalesCodes, { loading: salesCodeLoading }] = useLazyQuery(
    GET_SALES_CODES,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      // onError: (error) => setRequestError(error),
      onCompleted: (responseData) => {
        if (responseData?.getSalesCodes) {
          const saleCodes = responseData?.getSalesCodes.map((salesCode) => ({
            value: salesCode.code,
            label: salesCode.code,
            key: salesCode.code,
          }));
          setSalesCodes([DEFAULT_OPTION, ...saleCodes]);
        }
      },
    },
  );

  useEffect(() => {
    getSalesCodes();
    getBillingHistoryList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
      },
    });
  }, []);

  useEffect(() => {
    if (salesCodes?.length > 0) {
      const filterStruct = cloneDeep(filterStructure);
      filterStruct[0].filters[0].options = [DEFAULT_OPTION, ...salesCodes];
      setfilterStructure(filterStruct);
    }
  }, [salesCodes]);

  const clearData = (filters) => {
    console.log(filters);
  };

  const setBillingHistoryFilters = (filters) => {
    Object.keys(filters).forEach((key) => {
      const valueObj = filters[key];
      const selectedKey = valueObj?.filter?.key;
    });
  };

  useEffect(() => {
    getBillingHistoryList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
      },
    });
  }, [paginationState]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const sort = () => {};

  return (
    <>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-7">
          <h4 className="title-s-caps text-primary">CUSTOMER INFORMATION</h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-2"
            data-testid="afp-billing-details"
          >
            <DetailsTable
              className="afp-registration__section_container text-tabular"
              data={tableData}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="grid-row">
          <div className="grid-col-12 margin-top-5 bg-primary-lightest padding-3">
            {salesCodeLoading && <Spinner className="padding-y-9" />}
            {salesCodes?.length > 0 && (
              <FilterPanel.FilterPanel
                clearButtonLabel="Reset all"
                filterStructure={filterStructure}
                mode="horizontal"
                model="Billing"
                setQueryFiltersState={(filters) =>
                  setBillingHistoryFilters(filters)
                }
                updateAppliedFilters={(filters) => {
                  setBillingHistoryFilters(filters);
                }}
                handleClearAll={(filters) => {
                  clearData(filters);
                }}
                showClearIcon
              />
            )}
          </div>
        </div>
      </div>

      <div>
        <AFPTable
          columns={TABLE_HEADER}
          iconCollapsed={TABLE_ICON_COLLAPSED}
          iconExpanded={TABLE_ICON_EXPANDED}
          data={
            !billingHistoryLoading?.rows ? billingHistoryList?.rows || [] : []
          }
          testId="afp-billing-history"
          fullWidth
          bordered={false}
          defaultSort="trasactionDate asc"
          expandable
          scrollable={false}
          stacked
          selectAllHeader=""
          renderRowSubComponent={BillingHistoryTableDetail}
          onSort={() => sort()}
        />
        {billingHistoryLoading && <Spinner className="padding-y-9" />}
        {billingHistoryList?.rows?.length > 0 && (
          <Pagination
            fullWidth
            variant="advanced"
            itemsPerPageOptions={[10, 25, 50]}
            itemsCount={billingHistoryList?.count}
            itemsPerPage={paginationState.limit}
            currentPage={paginationState.currentPage}
            onPageChange={handlePaginationChange}
            isReset={paginationState.isReset}
          />
        )}
        {/* )} */}
        {(billingHistoryList == null || billingHistoryList.length === 0) && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No billing history available
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BillingHistoryTable;
BillingHistoryTable.propTypes = {
  vehicle: PropTypes.shape({
    customer: PropTypes.shape({
      accountName: PropTypes.string,
      customerId: PropTypes.string,
    }),
    ald: PropTypes.shape({
      fundCode: PropTypes.string,
      account1: PropTypes.string,
      account2: PropTypes.string,
    }),
  }).isRequired,
};
