import React from 'react';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import LicensePlateReview from './license-plate-review';

export default function VehicleInfoReview({ formState, setStep = () => {} }) {
  const getFastReportableLabel = (fastReportable) => {
    if (fastReportable === true) {
      return 'Yes';
    } else if (fastReportable === false) {
      return 'No';
    }
    return fastReportable;
  };
  const handleNavToStep1 = () => {
    setStep(1);
  };
  return (
    <>
      <div className="tablet:grid-col-6">
        <h4 className="title-s-caps text-primary margin-bottom-1">VEHICLE</h4>
        <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
          <DetailsTable
            className="review-vehicle-information text-right"
            data={[
              ['VIN', formState.vin],
              ['Year', formState.modelYear],
              ['Make', formState.makeName],
              ['Model', formState.modelName],
              ['Color', formState.makeColorLabel],
              ['Fuel Type', formState.fuelDescription],
              [
                'FAST reportable',
                getFastReportableLabel(formState.fastReportable),
              ],
            ]}
          />
          <Button
            data-testid="btn-edit-step-1-vehicle"
            variant="outline"
            className="margin-top-2 margin-left-0 bg-white"
            aria-label="edit vehicle"
            onClick={handleNavToStep1}
            label="Edit"
          />
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <LicensePlateReview
          tagNumber={formState.tagNumber}
          tagExpirationDate={formState.tagExpirationDate}
          statePlate={formState.statePlate}
          exemptPlate={formState.exemptPlate}
          handleNavToStep1={handleNavToStep1}
        />
      </div>
    </>
  );
}
