import { UserContext } from '@gsa/afp-shared-ui-utils';
import { useContext } from 'react';
import { UserType } from './consts';

export const ROLES = {
  FMVRS: 'FMVRS',
  FSR: 'FSR',
  CFM: 'CFM',
  FleetSvcRep: 'Fleet Service Representative',
  CustFltMan: 'Customer Fleet Manager',
  CustomerAdmin: 'Customer Administrator',
  FMVRSAdminRole: 'FMVRS Admin Role',
  SiteAdmin: 'Site Administrator',
  FMCManager: 'FMC Manager',
  Dispatcher: 'Motor Pool Dispatcher',
  LpVendor: 'License Plate Vendor',
  MCCSpecialist: 'MCC Specialist',
  AMCSpecialist: 'AMC Specialist',
  OBAnalyst: 'Oversight Business Analyst',
};

// eslint-disable-next-line import/no-mutable-exports
export let ROLES_TO_ABBR;
(() => {
  ROLES_TO_ABBR = Object.keys(ROLES).reduce((rolesToAbbr, role) => {
    // eslint-disable-next-line no-param-reassign
    rolesToAbbr[ROLES[role]] = role;
    return rolesToAbbr;
  }, {});
})();

export const checkRole = (roles, role) => {
  if (roles && role && ROLES[role]) {
    return roles.findIndex((r) => r.name === ROLES[role]) !== -1;
  }
  if (!ROLES[role]) {
    // eslint-disable-next-line no-console
    console.warn(`"${role}" role does not exist.`);
  }
  return null;
};

export default function useUser() {
  const userContext = useContext(UserContext);

  const isRole = (role) => {
    return checkRole(userContext?.currentUser?.roles, role);
  };

  const getRole = () => {
    return userContext?.currentUser?.roles.map((role) => role.name);
  };

  const isCustomer = () => {
    return Number(userContext?.currentUser?.userType?.id) === UserType.CUSTOMER;
  };

  const isGsaEmployee = () => {
    return (
      Number(userContext?.currentUser?.userType?.id) === UserType.GSA_EMPLOYEE
    );
  };

  const isScopeLevel = (tagetScopeLevel, scopeValue, operation, subject) => {
    const abilities = userContext?.currentUser.abilities;
    for (let i = 0; i < abilities.length; i++) {
      const [verb, obj, payload] = abilities[i];
      if (verb === operation && obj === subject) {
        const { serviceObjectScopes } = payload;
        if (
          serviceObjectScopes &&
          serviceObjectScopes.$elemMatch &&
          serviceObjectScopes?.$elemMatch[tagetScopeLevel] &&
          serviceObjectScopes?.$elemMatch[tagetScopeLevel] === scopeValue
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return {
    userContext,
    isRole,
    getRole,
    isCustomer,
    isGsaEmployee,
    isScopeLevel,
  };
}
