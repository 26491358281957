import { gql } from '@apollo/client';

export const CustomerAccountFields = gql`
  fragment CustomerAccountFragment on CustomerAccount {
    customerId
    customerType
    accountName
    legacyCustomerId
    legacyCustomerNumber
    boac
    zoneId
    regionId
    fmcId
    customerAgencyCode
    customerBureauCode
    agencySubGroup
    address1
    address2
    city
    usStateId
    internationalStateName
    countryId
    postalCode
    maintenanceReminder
    fsrUserEmail
    accountNotes
    vehicleCount
    hasTransportationFee
    fsrUser {
      firstName
      lastName
      phoneNumber
      email
    }
    primaryContactAssociation {
      contactId
      priority
      entityType
      entityId
      pointOfContact {
        pocEmailAddress
        pocFirstName
        pocLastName
        pocFullName
        pocPhone1
        pocPhone1Ext
        pocPhone1CountryCode
        pocPhone2
        pocPhone2Ext
        pocPhone2CountryCode
        pocAddress1
        pocAddress2
        pocCity
        pocPostalCode
        pocStateCode
        pocIsoCountryCode2
      }
    }
    customerAgency {
      name
      id
      prefixedName
    }
    customerBureau {
      name
      id
      prefixedName
    }
    customerPhysicalOffice {
      officeName
      prefixedName
    }
    managementCenter {
      id
      name
      stateCode
      city
    }
  }
`;

export const CustomerAccountModalFields = gql`
  fragment CustomerAccountFragment on CustomerAccount {
    customerId
    accountName
    boac
    fsrUserEmail
    fsrUser {
      firstName
      lastName
      phoneNumber
      email
    }
    customerPhysicalOfficeCode
    primaryContactAssociation {
      contactId
      priority
      entityType
      entityId
      pointOfContact {
        pocEmailAddress
        pocFirstName
        pocLastName
        pocFullName
        pocPhone1
        pocPhone1Ext
        pocPhone1CountryCode
        pocPhone2
        pocPhone2Ext
        pocPhone2CountryCode
        pocAddress1
        pocAddress2
        pocCity
        pocPostalCode
        pocStateCode
        pocIsoCountryCode2
      }
    }
  }
`;

export const GET_CUSTOMER_ACCOUNTS = gql`
  query GetCustomerAccounts(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getCustomerAccounts(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      count
      hasMore
      rows {
        ...CustomerAccountFragment
      }
    }
  }
  ${CustomerAccountFields}
`;

export const GET_CUSTOMER_ACCOUNTS_FOR_MODAL = gql`
  query GetCustomerAccounts(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getCustomerAccounts(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      count
      hasMore
      rows {
        ...CustomerAccountFragment
      }
    }
  }
  ${CustomerAccountModalFields}
`;

export const GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL = gql`
  query GetCustomerFSREmailsByPartialEmail(
    $fsrUserEmail: String!
    $limit: Float
  ) {
    getCustomerFSREmailsByPartialEmail(
      fsrUserEmail: $fsrUserEmail
      limit: $limit
    ) {
      fsrUserEmail
    }
  }
`;

export const GET_CUSTOMER_ACCOUNT = gql`
  query GetCustomerAccount($customerId: Float!) {
    getCustomerAccount(customerId: $customerId) {
      ...CustomerAccountFragment
      mileageCaptureType
      allContactAssociations {
        contactId
        priority
        entityType
        entityId
        pointOfContact {
          pocEmailAddress
          pocFullName
          pocFirstName
          pocLastName
          pocPhone1
          pocPhone1Ext
          pocPhone1CountryCode
          pocPhone2
          pocPhone2Ext
          pocPhone2CountryCode
          pocAddress1
          pocAddress2
          pocCity
          pocPostalCode
          pocStateCode
          pocIsoCountryCode2
        }
      }
    }
  }
  ${CustomerAccountFields}
`;

export const CREATE_CUSTOMER_ACCOUNT = gql`
  mutation CreateCustomerAccount($customerAccount: CustomerAccountInput!) {
    createCustomerAccount(customerAccount: $customerAccount) {
      customerId
      accountName
    }
  }
`;

export const UPDATE_CUSTOMER_ACCOUNT = gql`
  mutation UpdateCustomerAccount($customerAccount: CustomerAccountInput!) {
    updateCustomerAccount(customerAccount: $customerAccount) {
      customerId
      accountName
    }
  }
`;

export const DELETE_CUSTOMER_ACCOUNT = gql`
  mutation DeleteCustomerAccount($id: Float!) {
    deleteCustomerAccount(id: $id) {
      status
      accountName
    }
  }
`;

export const ASSIGN_FSR_EMAIL = gql`
  mutation AssignFsrEmail($customerAccountIds: [String!]!, $fsrEmail: String!) {
    assignFsrEmail(customerAccountIds: $customerAccountIds, fsrEmail: $fsrEmail)
  }
`;

export const UPDATE_CUSTOMER_TRANSPORTATION_FEES = gql`
  mutation UpdateCustomerTransportationFees(
    $customerAccountIds: [Int!]!
    $actionType: String!
  ) {
    updateCustomerTransportationFees(
      customerAccountIds: $customerAccountIds
      actionType: $actionType
    )
  }
`;
