import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';
import { GET_ZONES_CENTERS } from 'services/data-layer/fed-hierarchy.gql';

const placeholderOption = {
  value: '',
  label: '- Select FMC -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const VehiclesFmcFilterItem = ({ filter }) => {
  const [zoneValue, setZoneValue] = useState();
  const [fmcWithZoneAndRegion, setFmcWithZoneAndRegion] = useState({});
  const [options, setOptions] = useState([]);
  const [fmcValue, setFmcValue] = useState('');

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const fmcOptions = {};
        responseData?.getZones.forEach((zone) =>
          zone.centers.forEach((center) => {
            const option = {
              value: center.id,
              label: center.name,
            };
            if (!fmcOptions[zone.id]) fmcOptions[zone.id] = [];
            fmcOptions[zone.id].push(option);
          }),
        );
        setFmcWithZoneAndRegion(fmcOptions);
        if (zoneValue) {
          setOptions([placeholderOption, ...fmcOptions[zoneValue]]);
        }
      }
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  useEffect(() => {
    const zoneFilter = getFilterFromState('$customer.zone_id$') || {};
    setZoneValue(zoneFilter.value);
    const fmcFilter = getFilterFromState('$customer.fmc_id$') || {};
    setFmcValue(fmcFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);
    if (zoneValue && !isEmpty(fmcWithZoneAndRegion)) {
      setOptions([placeholderOption, ...fmcWithZoneAndRegion[zoneValue]]);
    } else {
      setOptions([placeholderOption]);
      getZones();
    }
  }, [zoneValue]);

  return (
    <div className="margin-top-3 margin-bottom-2">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: fmcValue || [],
        }}
      />
    </div>
  );
};

VehiclesFmcFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesFmcFilterItem;
